import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { List, Modal } from 'antd';
import {
  errorSelector,
  singleActionTypeSelector,
  singleActionStepSelector,
  isSingleActionWorkflowActiveSelector,
  shouldShowVerificationMessageSelector,
} from '../../../../redux/selectors/singleActionsSelector.js';
import SINGLE_ACTIONS_MAP, {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../../../../redux/constants/single_actions.js';
import {
  cancelSingleActionWorkflow,
  finishSingleActionWorkflow,
} from '../../../../redux/actions/index.js';
import SingleActionCommentForm from '../../ModalCommentForm/SingleActionCommentForm.js';
import ModalContext from '../ModalContext.js';
import SingleActionConfirmForm from '../../ModalConfirmForm/SingleActionConfirmForm.js';
import Loader from '../../Loader/index.jsx';
import './index.css';

const noop = () => {};

const mapStateToProps = state => ({
  singleActionType: singleActionTypeSelector(state),
  singleActionStep: singleActionStepSelector(state),
  isActive: isSingleActionWorkflowActiveSelector(state),
});

const mapDispatchToProps = {
  cancelWorkflow: cancelSingleActionWorkflow,
  finishWorkflow: finishSingleActionWorkflow,
};

const mapStateToMainCopyBodyProps = state => ({
  shouldShowVerificationMessage: shouldShowVerificationMessageSelector(state),
});

const MainCopyBody = ({
  body,
  shouldShowVerificationMessage,
  verificationNote,
}) => (
  <Fragment>
    <p>{body}</p>
    { (verificationNote && shouldShowVerificationMessage) && (
    <Fragment>
      <p>{verificationNote.body}</p>
      <List
        dataSource={verificationNote.list}
        prefixCls="modal_verification_list ant-list"
        renderItem={li => (
          <li>{li}</li>
              )}
      />
    </Fragment>
      )
    }
  </Fragment>
);

const ConnectedMainCopyBody = connect(mapStateToMainCopyBodyProps)(MainCopyBody);

const mapStateToFailureCopyBodyProps = state => ({
  serverError: errorSelector(state),
});

const FailureCopyBody = ({
  fallbackError,
  serverError,
}) => (
  <Fragment>
    {
      serverError || fallbackError
    }
  </Fragment>
);

const ConnectedFailureCopyBody = connect(mapStateToFailureCopyBodyProps)(FailureCopyBody);

const getModalBody = ({
  singleActionStep,
  copyConfig,
}) => {
  switch (singleActionStep) {
    case COMMENT_STEP:
      return (
        <Fragment>
          <ConnectedMainCopyBody
            body={copyConfig.body}
            verificationNote={copyConfig.verificationNote}
          />
          <SingleActionCommentForm />
        </Fragment>
      );
    case CONFIRM_STEP:
      return (
        <Fragment>
          <ConnectedMainCopyBody
            body={copyConfig.body}
            verificationNote={copyConfig.verificationNote}
          />
          <p>
            {`Type "${copyConfig.confirmationPhrase}" to confirm`}
          </p>
          <SingleActionConfirmForm
            confirmationPhrase={copyConfig.confirmationPhrase}
          />
        </Fragment>
      );
    case FAILURE:
      return (
        <ConnectedFailureCopyBody
          fallbackError={copyConfig.errorBody}
        />
      );
    case SUCCESS:
      return (
        <Fragment>
          {copyConfig.successBody}
        </Fragment>
      );
    case SUBMITTING:
    default:
      return (
        <Loader isLoading />
      );
  }
};

const getOnCancel = ({
  singleActionStep,
  cancelWorkflow,
  finishWorkflow,
}) => {
  switch (singleActionStep) {
    case SUCCESS:
    case FAILURE:
      return finishWorkflow;
    default:
      return cancelWorkflow;
  }
};

const getOnOk = ({
  singleActionStep,
  onCancel,
  submitFn,
}) => {
  switch (singleActionStep) {
    case COMMENT_STEP:
    case CONFIRM_STEP:
      return submitFn;
    default:
      return onCancel;
  }
};

const SingleActionsWorkflowModal = ({
  singleActionType,
  singleActionStep,
  isActive,
  cancelWorkflow,
  finishWorkflow,
}) => {
  // used by nth-level children to set outer submit control
  const [submitFn, setSubmitFn] = useState(noop);
  // used by nth-level children to set outer submit control disabled state
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const [modalContext] = useState({
    setSubmitFn,
    setSubmitDisabled,
  });

  const copyConfig = SINGLE_ACTIONS_MAP[singleActionType];
  const isClosable = singleActionStep !== SUBMITTING;
  const isCancelButtonHidden = [SUBMITTING, SUCCESS, FAILURE]
    .includes(singleActionStep);
  const onCancel = getOnCancel({
    singleActionStep,
    cancelWorkflow,
    finishWorkflow,
  });

  // disable the modal submit by the form's status if it's a form,
  // otherwise never disable it
  const isOkButtonDisabled = [COMMENT_STEP, CONFIRM_STEP]
    .includes(singleActionStep) && isSubmitDisabled;
  const isOkButtonHidden = singleActionStep === SUBMITTING;
  const onOk = getOnOk({
    singleActionStep,
    onCancel,
    submitFn,
  });

  const footerOverride = {};
  // No buttons means no need for footer
  if (isOkButtonHidden && isCancelButtonHidden) {
    footerOverride.footer = null;
  }
  return (
    <Modal
      {...footerOverride}
      destroyOnClose
      closable={isClosable}
      keyboard={isClosable}
      maskClosable={isClosable}
      cancelButtonProps={{
        hidden: isCancelButtonHidden,
      }}
      okButtonProps={{
        disabled: isOkButtonDisabled,
        hidden: isOkButtonHidden,
      }}
      onCancel={onCancel}
      onOk={onOk}
      title={copyConfig.title}
      visible={isActive}
    >
      <ModalContext.Provider value={modalContext}>
        <div>
          {
            getModalBody({
              singleActionStep,
              copyConfig,
            })
          }
        </div>
      </ModalContext.Provider>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleActionsWorkflowModal);

