import API from '../../services/api.js';
import createCsvPure from './createCsvPure';

async function fetchAPI({
  limit, markedForDeletion, offset, searchParams, fetchType,
}) {
  const api = API.create();
  const response = await api[fetchType]({
    limit,
    markedForDeletion,
    offset,
    searchParams,
  });
  return response.data.data.users || '';
}

export default async function createCSV({
  searchParams,
  totalCount,
  dataIndex,
  fetchType,
  marked,
}) {
  const result = await createCsvPure({
    searchParams,
    totalCount,
    dataIndex,
    fetchType,
    marked,
    fetchAPI,
    createBlob: (...args) => new Blob(...args),
    URL,
    document,
  });
  return result;
}
