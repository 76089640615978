import { connect } from 'react-redux';
import ModalParametersForm from './ModalParametersForm.jsx';
import { addParameters } from '../../../redux/actions/index.js';
import {
  ticketscanLimitSelector,
  isTicketscanUnlimitedSelector,
  newTicketscanSelector,
} from '../../../redux/selectors/featureActionsSelector.js';

const mapStateToProps = state => ({
  ticketscanLimit: ticketscanLimitSelector(state),
  isTicketscanUnlimited: isTicketscanUnlimitedSelector(state),
  newTicketscanOptions: newTicketscanSelector(state),
});

const mapDispatchToProps = {
  addParameters,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  initialValues: {
    ...stateProps.newTicketscanOptions,
    scanCountLimit: stateProps.newTicketscanOptions.scanCountLimit > -1
      ? stateProps.newTicketscanOptions.scanCountLimit
      : null,
  },
  onSubmit: (values) => {
    dispatchProps.addParameters({
      newTicketscanOptions: {
        ...stateProps.newTicketscanOptions,
        ...values,
      },
    });
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ModalParametersForm);
