import React, { Fragment } from 'react';
import getProp from 'lodash/get.js';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  startBulkActionWorkflow,
  startSingleActionWorkflow,
} from '../../redux/actions/index.js';
import BULK_ACTIONS_MAP, {
  VERIFY,
  UNVERIFY,
  LOCK_ACCOUNT,
  UNLOCK_ACCOUNT,
  CANCEL_DELETION,
  MARK_FOR_DELETION,
  PURGE,
} from '../../redux/constants/bulk_actions.js';
import SINGLE_ACTIONS_MAP, { RESET_PASSWORD } from '../../redux/constants/single_actions.js';

const mapStateToProps = state => ({
  isAdmin: state.loginReducer.isAdmin,
});

const mapDispatchToProps = {
  startBulkActionWorkflow,
  startSingleActionWorkflow,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  resetPassword: () => dispatchProps.startSingleActionWorkflow({
    singleActionType: RESET_PASSWORD,
    userId: getProp(ownProps.user, 'sub'),
  }),
});

const Status = ({
  user,
  isAdmin,
  // eslint-disable-next-line no-shadow
  startBulkActionWorkflow,
  resetPassword,
  originPath,
}) => {
  const isVerified = getProp(user, 'sso_verified', false) === 'Y';
  const isLocked = getProp(user, 'locked', false);
  const isMarkedForDeletion = getProp(user, 'marked_for_deletion', false);
  const isEmailVerified = getProp(user, 'cognito_email_verified', false);
  const isPhoneVerified = getProp(user, 'sso_phone_verification', false) === 'Y';
  const isSSNVerified = getProp(user, 'sso_ssn_verification', false) === 'Y';
  const isGovVerified = getProp(user, 'sso_gov_id_verification', false) === 'Y';
  const isNewsletter = getProp(user, 'newsletter_opt_in', false);

  const verifyButtonType = isVerified ? UNVERIFY : VERIFY;
  const verifyButtonConfig = BULK_ACTIONS_MAP[verifyButtonType];
  const lockButtonType = isLocked ? UNLOCK_ACCOUNT : LOCK_ACCOUNT;
  const lockButtonConfig = BULK_ACTIONS_MAP[lockButtonType];
  const markButtonType = isMarkedForDeletion ? CANCEL_DELETION : MARK_FOR_DELETION;
  const markButtonConfig = BULK_ACTIONS_MAP[markButtonType];
  const canPurge = isMarkedForDeletion && isAdmin;

  // eslint-disable-next-line import/no-named-as-default-member
  const resetCta = SINGLE_ACTIONS_MAP[RESET_PASSWORD].ctaText;
  return (
    <Fragment>
      <div className="col-lg-12 mb-5 mt-5">
        <div className="d-flex align-items-center mb-5">
          <div className="font-weight-bold col-lg-3 col-md-4">
            Verification Status
          </div>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isVerified ? (
              <span className="font-weight-bold text-success">Verified</span>
            ) : (
              <span className="font-weight-bold text-danger">Unverified</span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">
            Lock Status
          </span>
          <div className="col-lg-6 col-md-8">
            {isLocked ? (
              <span className="font-weight-bold text-danger">Locked</span>
            ) : (
              <span className="font-weight-bold text-success">Unlocked</span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">Email</span>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isEmailVerified ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">
            Phone (OTP)
          </span>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isPhoneVerified ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">
            PII (with SSN4)
          </span>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isSSNVerified ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">ID</span>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isGovVerified ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <span className="font-weight-bold col-lg-3 col-md-4">
            Newsletter Opt-In
          </span>
          <div className={classnames('col-lg-6 col-md-8')}>
            {isNewsletter ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="d-flex pt-3 col-12 pb-6">
          <button
            type="button"
            className="btn btn-secondary btn-square"
            onClick={() => {
              startBulkActionWorkflow({
                bulkActionType: verifyButtonType,
              });
            }}
          >
            {verifyButtonConfig.ctaText}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-square ml-2"
            disabled={isLocked || isMarkedForDeletion}
            onClick={resetPassword}
          >
            {resetCta}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-square ml-2"
            onClick={() => {
              startBulkActionWorkflow({
                bulkActionType: lockButtonType,
              });
            }}
          >
            {lockButtonConfig.ctaText}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-square ml-2"
            onClick={() => {
              startBulkActionWorkflow({
                bulkActionType: markButtonType,
              });
            }}
          >
            {markButtonConfig.ctaText}
          </button>
          {canPurge && (
            <button
              type="button"
              className="btn btn-danger btn-square ml-2"
              onClick={() => {
                startBulkActionWorkflow({
                  bulkActionType: PURGE,
                });
              }}
            >
              Permanently Delete
            </button>
          )}
        </div>
        <div className="bg-white card-footer d-flex justify-content-between">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to={originPath}>
            <button type="button" className="btn btn-primary" onClick={() => {}}>
              BACK
            </button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Status);
