/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({
  isAdmin: state.loginReducer.isAdmin,
});

class AsideMenu extends React.Component {
  render() {
    const { isAdmin } = this.props;
    return (
      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu "
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1"
          data-ktmenu-dropdown-timeout="500"
        >
          <ul className="kt-menu__nav ">
            <li
              className="kt-menu__item  kt-menu__item--active"
              aria-haspopup="true"
            >
              <Link to="/users" className="kt-menu__link">
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                  <span />
                </i>
                <span className="kt-menu__link-text">Users</span>
              </Link>
            </li>
            <li
              className="kt-menu__item  kt-menu__item--active"
              aria-haspopup="true"
            >
              <Link to="/pending-deletion" className="kt-menu__link">
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                  <span />
                </i>
                <span className="kt-menu__link-text">Pending Deletion</span>
              </Link>
            </li>
            {isAdmin && (
              <li
                className="kt-menu__item  kt-menu__item--active"
                aria-haspopup="true"
              >
                <Link to="/purged-users" className="kt-menu__link">
                  <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                    <span />
                  </i>
                  <span className="kt-menu__link-text">
                    Permanently Deleted
                  </span>
                </Link>
              </li>
            )}
            {isAdmin && (
              <li
                className="kt-menu__item  kt-menu__item--active"
                aria-haspopup="true"
              >
                <Link to="/admins" className="kt-menu__link">
                  <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                    <span />
                  </i>
                  <span className="kt-menu__link-text">
                    Admins
                  </span>
                </Link>
              </li>
            )}
            <li
              className="kt-menu__item  kt-menu__item--active"
              aria-haspopup="true"
            >
              <Link to="/features" className="kt-menu__link">
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                  <span />
                </i>
                <span className="kt-menu__link-text">Features</span>
              </Link>
              <Link to="/features-audit-trail" className="kt-menu__link">
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot invisible">
                  <span />
                </i>
                <span className="kt-menu__link-text">Features Audit Trail</span>
              </Link>
            </li>
            {isAdmin && (
            <li
              className="kt-menu__item  kt-menu__item--active"
              aria-haspopup="true"
            >
              <Link to="/create-user" className="kt-menu__link">
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                  <span />
                </i>
                <span className="kt-menu__link-text">Create User</span>
              </Link>
            </li>)}
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AsideMenu);
