export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

// messy, but I gotta be quick. this is for transforming ITU
// phone to domestic format for the user details form
// ex: `+12125558000` -> `(212) 555-8000`
export function formatPhoneForDetailsForm(phoneNumberString) {
  const withIntlCode = formatPhoneNumber(phoneNumberString) || '';
  return withIntlCode.replace('+1 ', '');
}

export function formatPhoneNumberOnlyNumbers(phoneNumberString) {
  return phoneNumberString.replace(/\D+/g, '');
}

export function formatRoleString(roleString) {
  switch (roleString) {
    case 'superadmin':
      return 'Superadmin';
    case 'csr':
    default:
      return 'CSR';
  }
}
