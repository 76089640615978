/* eslint-disable arrow-parens */
/* eslint-disable import/first */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Loader from '../../common/components/Loader/index.jsx';
import { Switch } from 'antd';
import Portlet from '../../common/components/Portlet/index.jsx';
import FeaturesWorkflowModal from '../../common/components/Modal/FeaturesWorkflow/index.jsx';
import {
  LOCK_TICKET_SCAN,
  UNLOCK_TICKET_SCAN,
} from '../../redux/constants/features_actions.js';
import {
  startFeaturesWorkflow,
  fetchFeaturesSettings,
  startTicketscanToggleFlow,
  startTicketscanLimitFlow,
} from '../../redux/actions/index.js';
import './index.css';
import {
  isFeaturesLoadingSelector,
  isTicketscanEnabledSelector,
  ticketscanLimitSelector,
  isTicketscanUnlimitedSelector,
  ticketscanSelector,
} from '../../redux/selectors/featureActionsSelector.js';
import DataToggleForm from '../../common/components/DataToggleForm/index.jsx';

// TODO: reorganize file.

const mapStateToProps = state => ({
  isAdmin: state.loginReducer.isAdmin,
  isTicketscanEnabled: isTicketscanEnabledSelector(state),
  ticketscanOptions: ticketscanSelector(state),
  ticketscanLimit: ticketscanLimitSelector(state),
  isTicketscanUnlimited: isTicketscanUnlimitedSelector(state),
  isLoading: isFeaturesLoadingSelector(state),
});
const mapDispatchToProps = {
  fetchFeaturesSettings,
  startFeaturesWorkflow,
  startTicketscanToggleFlow,
  startTicketscanLimitFlow,
};

const featuresPortletConfig = {
  title: 'Features',
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  lockTicketScan: () =>
    dispatchProps.startTicketscanToggleFlow({
      newTicketscanOptions: {
        ...stateProps.ticketscanOptions,
        isEnabled: false,
      },
      toggleType: LOCK_TICKET_SCAN,
    }),
  unlockTicketScan: () =>
    dispatchProps.startTicketscanToggleFlow({
      newTicketscanOptions: {
        ...stateProps.ticketscanOptions,
        isEnabled: true,
      },
      toggleType: UNLOCK_TICKET_SCAN,
    }),
  setTicketScanLimit: () =>
    dispatchProps.startTicketscanLimitFlow({
      newTicketscanOptions: {
        ...stateProps.ticketscanOptions,
      },
    }),
});

const Features = ({
  isAdmin,
  isLoading,
  fetchFeaturesSettings,
  ticketscanOptions,
  isTicketscanEnabled,
  ticketscanLimit,
  isTicketscanUnlimited,
  lockTicketScan,
  unlockTicketScan,
  setTicketScanLimit,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(ticketscanOptions.isEnabled);
  const switchTimeoutRef = useRef(null);
  // Reset switch state if modal was cancelled
  useEffect(() => {
    setIsSwitchOn(ticketscanOptions.isEnabled);
  }, [ticketscanOptions]);

  // Cancel timer upon unmount if exists
  useEffect(() => {
    if (switchTimeoutRef.current) {
      clearTimeout(switchTimeoutRef.current);
      switchTimeoutRef.current = null;
    }
  }, []);

  // Visually show switch flipping and then open modal after
  // a short timeout
  const flipSwitch = () => {
    if (!switchTimeoutRef.current) {
      setIsSwitchOn(prevSwitchState => !prevSwitchState);
      switchTimeoutRef.current = setTimeout(() => {
        if (isTicketscanEnabled) {
          lockTicketScan();
        } else {
          unlockTicketScan();
        }
        switchTimeoutRef.current = null;
      }, 100);
    }
  };

  useEffect(() => {
    fetchFeaturesSettings();
  }, []);

  return (
    <div className="row">
      <div className="order-1 col-12">
        <FeaturesWorkflowModal />
        <Loader isLoading={isLoading}>
          <div className="features-portlet">
            <Portlet config={featuresPortletConfig}>
              <div className="d-flex align-items-center">
                <div className="col-2">
                  <span className="font-weight-bold">Ticket Scan</span>
                </div>
                <div className="col-2">
                  <Switch
                    checked={isSwitchOn}
                    checkedChildren="Unlocked"
                    unCheckedChildren="Locked"
                    disabled={!isAdmin}
                    onClick={flipSwitch}
                  />
                </div>
                <div className="">
                  <button
                    className="bg-transparent btn btn-link text-reset font-weight-bold user-select-none"
                    onClick={setTicketScanLimit}
                    disabled={!isAdmin}
                  >
                    <u className="text-primary">
                      <small>
                        {`Ticket Scan Number Limit: ${
                          isTicketscanUnlimited ? 'Unlimited' : ticketscanLimit
                        }`}
                      </small>
                    </u>
                  </button>
                </div>
              </div>
            </Portlet>
          </div>
          <DataToggleForm isAdmin={isAdmin} />
        </Loader>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Features);
