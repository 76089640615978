import React, { Fragment } from 'react';
import { Spin } from 'antd';

const Loader = ({
  children,
  isLoading,
  size = 'large',
}) => (
  <Fragment>
    { isLoading ? (
      <div className="text-center">
        <Spin size={size} />
      </div>
    ) : children }
  </Fragment>
);

export default Loader;
