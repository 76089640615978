import { connect } from 'react-redux';
import ModalConfirmForm from './ModalConfirmForm.jsx';
import { performFeaturesAction } from '../../../redux/actions/index.js';
import { newTicketscanSelector } from '../../../redux/selectors/featureActionsSelector.js';

const mapStateToProps = state => ({
  newTicketscanOptions: newTicketscanSelector(state),
});

const mapDispatchToProps = {
  onSubmit: performFeaturesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmForm);
