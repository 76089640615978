import { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { saveCredentials as saveUserCredentials } from '../../redux/actions';

const mapDispatchToProps = {
  saveCredentials: saveUserCredentials,
};

@connect(null, mapDispatchToProps)
class LoginResponseView extends Component {
  componentDidMount() {
    const {
      history,
      location,
    } = this.props;
    const queryParams = queryString.parse(location.hash);
    const {
      access_token: accessToken,
      id_token: identityToken,
    } = queryParams;

    const {
      saveCredentials,
    } = this.props;

    saveCredentials({ accessToken, identityToken });
    history.push('/');
  }

  render() {
    return null;
  }
}

export default LoginResponseView;
