/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ContentSubheader from '../ContentSubheader/index.jsx';

class ContentBody extends React.Component {
  render() {
    const {
      children,
    } = this.props;

    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
        <ContentSubheader {...this.props} />
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          {children}
        </div>
      </div>
    );
  }
}

export default ContentBody;
