/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Input } from 'antd';
import {
  formToQueryString,
  queryStringToForm,
} from '../../lib/transformSearchRequest.js';
import './index.css';

/**
   * isAllowDuplicateCategories parameter allows to perform search by unique
   * category at one search attempt.
*/

const StructuredSearchbarForm = ({
  initSearchParams,
  isFetching,
  searchCategories,
  shouldGetMarkedUsers,
  submitSearch,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState('');
  const getDefaultSelected = () => queryStringToForm(location.search);
  const urlParams = new URLSearchParams(window.location.search);
  const initSearchTerm = urlParams.getAll('search_term')[0];
  const initOffset = urlParams.getAll('offset')[0] || 0;
  const initOrder = urlParams.getAll('order')[0] || null;
  const initColumn = urlParams.getAll('column')[0] || null;

  // fetch on init
  useEffect(() => {
    const formParams = initSearchTerm || getDefaultSelected();
    const storeParams = {
      formParams,
      markedForDeletion: shouldGetMarkedUsers,
    };
    initSearchParams(storeParams);
    setSearchParams(formParams);
    submitSearch({
      searchParams: formParams,
      offset: initOffset,
      limit: 10,
      order: initOrder,
      column: initColumn,
      markedForDeletion: shouldGetMarkedUsers,
    });
  }, []);
  const handleSubmit = () => {
    const trimmedSearchParams = searchParams ? searchParams.trim() : '';
    const querystring = formToQueryString(trimmedSearchParams);
    history.push({
      pathname: location.pathname,
      search: querystring,
    });
    submitSearch({
      searchParams: trimmedSearchParams,
      offset: initOffset,
      limit: 10,
      order: initOrder,
      column: initColumn,
      markedForDeletion: shouldGetMarkedUsers,
    });
    setSearchParams(trimmedSearchParams);
  };

  return (
    <div className="form-group">
      <div className="input-group">
        <div>
          <Input
            type="text"
            onChange={e => setSearchParams(e.target.value)}
            value={searchParams}
            defaultValue={initSearchTerm}
          />
        </div>
        <div className="form-group">
          <button
            className="ml-2 btn btn-wide btn-primary btn-upper"
            style={{ backgroundColor: '#084267', borderColor: '#084267', padding: '1rem 1rem' }}
            onClick={handleSubmit}
            disabled={isFetching}
          >
            {isFetching ? 'Fetching users...' : 'Search'}
          </button>
        </div>
      </div>
      {searchCategories.some(element => element.name === 'Phone Number') && <span>When (testing010 deployment with Node 20 on check-deployment-to-dev branch) entering a phone number, please enter digits only: no dashes, spaces, parentheses, etc.</span>}
    </div>
  );
};

export default StructuredSearchbarForm;
