import _ from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import { FETCH_PURGED_USER, FETCH_PURGED_USERS } from '../constants/index.js';

import {
  fetchPurgedUserSuccess,
  fetchPurgedUserFailure,
  fetchPurgedUsersSuccess,
  fetchPurgedUsersFailure,
} from '../actions/index.js';

const createPurgedUserSaga = (api) => {
  function* fetchPurgedUsersSaga(action) {
    const {
      searchParams, limit, offset, column, order,
    } = action.payload;
    let response;
    try {
      response = yield call(api.fetchPurgedUsers, {
        searchParams,
        limit,
        offset,
        column,
        order,
      });
      const { totalCount, users } = _.get(response, 'data.data', {
        count: 0,
        totalCount: 0,
        users: [],
      });
      yield put(fetchPurgedUsersSuccess({
        totalCount,
        users,
      }));
    } catch (error) {
      console.error('Fetch users error', error);
      const statusCode = _.get(error, 'response.status', -1);
      yield put(fetchPurgedUsersFailure({ error, statusCode }));
    }
  }

  function* watchFetchPurgedUsers() {
    yield takeLatest(FETCH_PURGED_USERS, fetchPurgedUsersSaga);
  }

  function* fetchPurgedUserSaga(action) {
    const { userDbId } = action.payload;
    let response;
    try {
      response = yield call(api.fetchPurgedUser, { userDbId });
      const user = _.get(response, 'data.data', {});
      yield put(fetchPurgedUserSuccess({
        user,
      }));
    } catch (error) {
      console.error('Fetch purged users error', error);
      const errorCode = _.get(error, 'response.status', -1);
      yield put(fetchPurgedUserFailure({ errorCode }));
    }
  }

  function* watchFetchPurgedUser() {
    yield takeLatest(FETCH_PURGED_USER, fetchPurgedUserSaga);
  }

  return {
    watchFetchPurgedUsers,
    watchFetchPurgedUser,
  };
};

export default createPurgedUserSaga;
