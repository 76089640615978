import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import './index.css';

const { TextArea } = Input;

const CommentForm = ({
  onSubmit,
  setOuterSubmitFn,
  setOuterSubmitDisabled,
}) => {
  const textAreaRef = useRef();

  // This allows us to provide outer controls the ability to submit this form.
  const [form] = Form.useForm();

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef.current]);

  // Allows outer control to use submit
  useEffect(() => {
    if (setOuterSubmitFn) {
      setOuterSubmitFn(() => form.submit);
    }
  }, [setOuterSubmitFn, form]);

  // Allows outer control to see disabled status of form
  useEffect(() => {
    if (setOuterSubmitDisabled) {
      setOuterSubmitDisabled(false);
    }
  }, [setOuterSubmitDisabled]);

  const onFinish = (allValues) => {
    const payload = {};
    if (allValues.comment && allValues.comment.length) {
      payload.comment = allValues.comment;
    }
    onSubmit(payload);
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      preserve={false}
    >
      <Form.Item name="comment">
        <TextArea
          className="comment-text-area"
          maxLength={280}
          placeholder="add optional comment here"
          rows={4}
          ref={textAreaRef}
          autoComplete="off"
        />
      </Form.Item>
    </Form>
  );
};

export default CommentForm;
