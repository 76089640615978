import React, { useState } from 'react';
import { Button } from 'antd';
import createCSV from '../../lib/createCSV.js';

export default function CSVDownloadButton({
  searchParams, totalCount, dataIndex, fetchType, marked,
}) {
  const [buttonStatus, updateButtonStatus] = useState(false);
  const fetchCSV = () => {
    updateButtonStatus(true);
    createCSV({
      searchParams, totalCount, dataIndex, fetchType, marked,
    }).then(() => updateButtonStatus(false));
  };
  return (
    <Button
      type="primary"
      style={{ float: 'right', margin: '20px' }}
      size="large"
      disabled={buttonStatus}
      onClick={() => fetchCSV()}
    >
      {buttonStatus ? 'Processing CSV' : 'Download CSV'}
    </Button>
  );
}
