import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import usersReducer from './reducers/usersReducer.js';
import purgedUsersReducer from './reducers/purgedUsersReducer.js';
import adminsReducer from './reducers/adminsReducer.js';
import loginReducer from './reducers/loginReducer.js';
import createSsoUserReducer from './reducers/createSsoUserReducer.js';
import bulkActionsReducer from './reducers/bulkActionsReducer.js';
import singleActionsReducer from './reducers/singleActionsReducer.js';
import featuresReducer from './reducers/featuresReducer.js';

export default combineReducers({
  routing,
  usersReducer,
  purgedUsersReducer,
  loginReducer,
  bulkActionsReducer,
  singleActionsReducer,
  featuresReducer,
  adminsReducer,
  createSsoUserReducer,
  form: formReducer,
});
