/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Form, Input } from 'antd';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import Loader from '../../common/components/Loader/index.jsx';
import Details from './Details.jsx';
import AuditTrail from './AuditTrail.jsx';
import Portlet from '../../common/components/Portlet/index.jsx';
import { formToQueryString } from '../../common/lib/transformSearchRequest.js';
import {
  fetchPurgedUser,
  clearError,
  fetchAuditTrail,
  resetAuditTrailPagination,
  saveAuditTrailComment,
} from '../../redux/actions/index.js';
import {
  setCommentFocusIntervalRef,
  clearIntervalRef,
} from '../../common/lib/utils.js';

const mapStateToProps = state => ({
  user: state.purgedUsersReducer.purgedUser,
  errorCode: state.purgedUsersReducer.errorCode,
  isFetching: !state.purgedUsersReducer.purgedUser || state.purgedUsersReducer.isFetching,
  searchParams: state.purgedUsersReducer.searchParams,
  auditTrailLimit: state.usersReducer.auditTrailLimit,
  auditTrailOffset: state.usersReducer.auditTrailOffset,
});

const mapDispatchToProps = {
  clearError,
  fetchPurgedUser,
  // TODO do the purged version
  fetchAuditTrail,
  resetAuditTrailPagination,
  saveAuditTrailComment,
};

const portletConfig = {
  tabs: ['User Details', '', 'Audit Trail'],
  actionsLabel: 'Actions',
  actions: [],
};

const PurgedUser = ({
  clearError,
  user,
  searchParams,
  errorCode,
  fetchPurgedUser,
  isFetching,
  saveAuditTrailComment,
  resetAuditTrailPagination,
  auditTrailLimit,
  auditTrailOffset,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [visible, setVisible] = React.useState(false);

  // This effect and refs focuses the comment form field upon displaying it and
  // cleans up interval upon unmount or close modal
  const commentRef = useRef();
  const intervalRef = useRef();
  useEffect(() => {
    if (visible) {
      setCommentFocusIntervalRef(commentRef, intervalRef);
    } else {
      clearIntervalRef(intervalRef);
    }
    return () => clearIntervalRef(intervalRef);
  }, [visible]);

  const location = useLocation();
  const { userDbId } = useParams();
  // for dynamic 'back' button on details page
  const basePath = `/${location.pathname.split('/')[1]}`;
  let originPath = basePath;
  let querystring;
  if (searchParams.length) {
    querystring = formToQueryString(searchParams);
    originPath = `${originPath}?${querystring}`;
  }

  const [form] = Form.useForm();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchPurgedUser({ userDbId });
  }, [userDbId]);

  useEffect(() => resetAuditTrailPagination, []);

  const showModal = () => {
    setVisible(true);
  };

  const onSubmit = (values) => {
    const comment = _.get(values, 'audit-log-comment');
    saveAuditTrailComment({
      comment,
      userDbId,
      auditTrailLimit,
      auditTrailOffset,
    });
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  if (errorCode) {
    const redirectObj = {
      pathname: basePath,
    };
    if (querystring) {
      redirectObj.search = `?${querystring}`;
    }
    clearError();
    return (
      <Redirect
        to={redirectObj}
      />
    );
  }
  return (
    <div className="row">
      <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
        <Modal
          title="Please add a comment"
          centered
          visible={visible}
          okText="Submit"
          cancelText="Cancel"
          onOk={form.submit}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Form
            form={form}
            onFinish={onSubmit}
            preserve={false}
          >
            <Form.Item name="audit-log-comment">
              <Input.TextArea
                className="comment-text-area"
                placeholder="add optional comment here"
                rows={4}
                ref={commentRef}
                maxLength={280}
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </Modal>
        <Portlet
          config={portletConfig}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        >
          {activeTab === 2 &&
            <div className="action-btn">
              <button
                onClick={() => { showModal(true); }}
                className="btn btn-secondary"
              >
                Add Comment
              </button>
            </div>
          }
          {activeTab === 0 && (
            <Loader isLoading={isFetching}>
              <Details
                originPath={originPath}
                user={user}
              />
            </Loader>
          )}
          {activeTab === 2 && (
            <AuditTrail
              originPath={originPath}
              userDbId={userDbId}
            />
          )}
        </Portlet>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PurgedUser);
