/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';
import ContentWrapper from './ContentWrapper/index.jsx';

class Content extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <ContentWrapper {...this.props}>
          {children}
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default Content;
