import { CREATE_SSO_USER, CREATE_SSO_USER_FAILURE, CREATE_SSO_USER_SUCCESS } from '../constants/index.js';

const defaultState = {
  isCreating: false,
  errorDetail: '',
  errorCode: null,
  sub: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_SSO_USER: {
      const { ssoUserDetails } = action.payload;
      return {
        ...state,
        ssoUserDetails,
        isCreating: true,
        errorCode: defaultState.errorCode,
      };
    }
    case CREATE_SSO_USER_SUCCESS: {
      const { sub } = action.payload;

      return {
        ...state,
        errorCode: defaultState.errorCode,
        errorDetail: '',
        isCreating: false,
        sub,
      };
    }
    case CREATE_SSO_USER_FAILURE: {
      const {
        errorCode,
        errorDetail,
      } = action.payload;
      return {
        ...state,
        isCreating: false,
        errorCode,
        errorDetail,
        sub: '',
      };
    }
    default: {
      return { ...state };
    }
  }
};
