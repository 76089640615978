import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CLEAR_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_AUDIT_TRAIL,
  FETCH_AUDIT_TRAIL_SUCCESS,
  FETCH_AUDIT_TRAIL_FAILURE,
  RESET_AUDIT_TRAIL_PAGINATION,
  PERFORM_BULK_ACTION_SUCCESS,
  INIT_SEARCH_PARAMS,
  UPDATE_USER_DISPLAY,
} from '../constants/index.js';

// eslint-disable-next-line consistent-return
const getUserBodyChangeFromBulkResp = (updateBody) => {
  if (updateBody.verified) {
    return {
      sso_verified: 'Y',
    };
  }
  if (updateBody.unverified) {
    return {
      sso_verified: 'N',
      sso_gov_id_verification: 'N',
      sso_ssn_verification: 'N',
      sso_phone_verification: 'N',
    };
  }
  if (updateBody.locked) {
    return {
      locked: true,
    };
  }
  if (updateBody.unlocked) {
    return {
      locked: false,
    };
  }
  if (updateBody.purged) {
    return {
      purged: true,
    };
  }
  if (updateBody.marked) {
    return {
      marked_for_deletion: true,
    };
  }
  if (updateBody.cancelled) {
    return {
      marked_for_deletion: false,
    };
  }
};

const defaultState = {
  user: null,
  // The total count of users that matched the search terms
  totalCount: 0,
  users: [],
  limit: 10, // limit to fetch users send to the api
  offset: 0,
  column: null,
  order: null,
  markedForDeletion: false,
  searchParams: [],
  isFetching: false,
  statusCode: false,
  errorDetail: '',
  // use this for error codes
  errorCode: null,
  userError: false,
  auditTrail: [],
  auditTrailTotalCount: 0,
  auditTrailLimit: 10,
  auditTrailOffset: 0,
  isFetchingAuditTrail: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_AUDIT_TRAIL: {
      const { auditTrailOffset, auditTrailLimit } = action.payload;
      return {
        ...state,
        auditTrailOffset,
        auditTrailLimit,
        isFetchingAuditTrail: true,
      };
    }
    case FETCH_AUDIT_TRAIL_SUCCESS: {
      const { auditTrail, auditTrailTotalCount } = action.payload;
      return {
        ...state,
        auditTrail,
        auditTrailTotalCount,
        isFetchingAuditTrail: false,
      };
    }
    case FETCH_AUDIT_TRAIL_FAILURE: {
      const { errorDetail } = action.payload;
      return {
        ...state,
        errorDetail,
        isFetchingAuditTrail: false,
      };
    }
    case RESET_AUDIT_TRAIL_PAGINATION:
      return {
        ...state,
        auditTrailOffset: 0,
      };
    case FETCH_USER:
      return {
        ...state,
        user: null,
        errorCode: defaultState.errorCode,
        isFetching: true,
      };
    case FETCH_USER_SUCCESS: {
      const {
        user,
      } = action.payload;
      return {
        ...state,
        errorCode: defaultState.errorCode,
        isFetching: false,
        user,
      };
    }
    case FETCH_USER_FAILURE: {
      const {
        errorCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        errorCode,
      };
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        errorCode: defaultState.errorCode,
      };
    }
    case PERFORM_BULK_ACTION_SUCCESS: {
      if (
        state.user &&
        action.payload.successUsers.length
      ) {
        const updated = action.payload.successUsers;
        const index = updated.findIndex(({
          userId,
        }) => state.user.sub === userId);
        if (index > -1) {
          const userBodyChange = getUserBodyChangeFromBulkResp(updated[index]);
          return {
            ...state,
            user: {
              ...state.user,
              ...userBodyChange,
            },
          };
        }
      }
      return {
        ...state,
      };
    }
    case FETCH_USERS: {
      const {
        searchParams,
        offset,
        limit,
        order,
        column,
      } = action.payload;
      return {
        ...state,
        users: defaultState.users,
        isFetching: true,
        searchParams,
        limit,
        offset,
        order,
        column,
      };
    }
    case FETCH_USERS_SUCCESS: {
      const {
        totalCount,
        users,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        totalCount,
        users,
      };
    }
    case FETCH_USERS_FAILURE: {
      const {
        errorDetail,
        statusCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        statusCode,
        errorDetail,
      };
    }
    case INIT_SEARCH_PARAMS: {
      const {
        markedForDeletion,
        formParams,
      } = action.payload;
      return {
        ...state,
        searchParams: formParams,
        markedForDeletion,
        limit: defaultState.limit,
        offset: defaultState.offset,
      };
    }

    case UPDATE_USER_DISPLAY: {
      const {
        user,
      } = action.payload;
      return {
        ...state,
        user,
      };
    }
    default:
      return { ...state };
  }
};
