import { connect } from 'react-redux';
import {
  fetchPurgedUsers,
  initPurgedSearchParams,
} from '../../../redux/actions/index.js';
import searchConfigs, { PURGED_USERS_SEARCH } from '../../lib/searchBarConfig.js';
import StructuredSearchbarForm from './StructuredSearchbarForm.jsx';

// eslint-disable-next-line import/no-named-as-default-member
const searchCategories = searchConfigs[PURGED_USERS_SEARCH];
const mapStateToProps = state => ({
  isFetching: state.purgedUsersReducer.isFetching,
  searchParams: state.purgedUsersReducer.searchParams,
});

const mapDispatchToProps = {
  submitSearch: fetchPurgedUsers,
  initSearchParams: initPurgedSearchParams,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  searchCategories,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StructuredSearchbarForm);
