import { call, put, select, takeLatest } from 'redux-saga/effects';
import getProp from 'lodash/get.js';
import {
  PERFORM_FEATURES_ACTION,
  PERFORM_FEATURES_ACTION_SUCCESS,
  FETCH_FEATURES_SETTINGS,
  FETCH_FEATURES_AUDIT_TRAIL,
  SAVE_FEATURE_AUDIT_TRAIL_COMMENT,
} from '../constants/index.js';
import {
  LOCK_TICKET_SCAN,
  UNLOCK_TICKET_SCAN,
  SET_TICKET_SCAN_LIMIT,
} from '../constants/features_actions.js';
import {
  performFeaturesActionSuccess,
  performFeaturesActionFailure,
  fetchFeaturesSettingsSuccess,
  fetchFeaturesSettingsFailure,
  fetchFeaturesAuditTrail,
  fetchFeaturesAuditTrailSuccess,
  fetchFeaturesAuditTrailFailure,
  saveFeatureAuditTrailCommentFailure,
} from '../actions/index.js';
import {
  featuresActionTypeSelector,
  featuresCommentSelector,
  newTicketscanSelector,
} from '../selectors/featureActionsSelector.js';

const createFeaturesSagas = (api) => {
  const { fetchFeaturesSettings, putTicketscanSettings } = api;

  const ACTION_TO_API_METHOD = {
    [LOCK_TICKET_SCAN]: putTicketscanSettings,
    [UNLOCK_TICKET_SCAN]: putTicketscanSettings,
    [SET_TICKET_SCAN_LIMIT]: putTicketscanSettings,
  };

  function* fetchFeaturesSettingsSaga() {
    let response;
    try {
      response = yield call(fetchFeaturesSettings, {});
      const settings = getProp(response.data.data.features, 'ticketscan', {});
      yield put(fetchFeaturesSettingsSuccess({ ticketscanOptions: settings }));
    } catch (error) {
      const statusCode = getProp(error, 'response.status', -1);
      const displayError = getProp(
        error,
        'response.data.errors.0.detail',
        'Data not found',
      );
      yield put(fetchFeaturesSettingsFailure({ errorDetail: displayError, statusCode }));
    }
  }

  function* watchFetchFeaturesSettings() {
    yield takeLatest(FETCH_FEATURES_SETTINGS, fetchFeaturesSettingsSaga);
  }

  function* fetchFeaturesAuditTrailSaga(action) {
    const { auditTrailLimit, auditTrailOffset } = action.payload;

    let response;
    try {
      response = yield call(api.fetchFeaturesAuditTrail, {
        limit: auditTrailLimit,
        offset: auditTrailOffset,
      });
      const { auditLog: auditTrail, totalCount } = getProp(
        response,
        'data.data',
        {},
      );
      yield put(fetchFeaturesAuditTrailSuccess({
        auditTrail,
        auditTrailTotalCount: totalCount,
      }));
    } catch (error) {
      console.log('Fetch audit trail error', error);
      const statusCode = getProp(error, 'response.status', -1);
      yield put(fetchFeaturesAuditTrailFailure({ errorDetail: error, statusCode }));
    }
  }

  function* watchFetchFeaturesAuditTrail() {
    yield takeLatest(FETCH_FEATURES_AUDIT_TRAIL, fetchFeaturesAuditTrailSaga);
  }

  function* saveFeaturesAuditTrailCommentSaga(action) {
    const { comment, auditTrailLimit, auditTrailOffset } = action.payload;
    try {
      const auditTrailEntry = {
        comment,
      };

      yield call(api.postFeaturesAuditTrailComment, {
        body: auditTrailEntry,
      });
      yield put(fetchFeaturesAuditTrail({ auditTrailLimit, auditTrailOffset }));
    } catch (error) {
      console.log('Save audit trail comment error', error);
      const statusCode = getProp(error, 'response.status', -1);
      yield put(saveFeatureAuditTrailCommentFailure({ errorDetail: error, statusCode }));
    }
  }

  function* watchFeaturesSaveAuditTrailComment() {
    yield takeLatest(
      SAVE_FEATURE_AUDIT_TRAIL_COMMENT,
      saveFeaturesAuditTrailCommentSaga,
    );
  }

  function* performFeaturesAction() {
    const featureAction = yield select(featuresActionTypeSelector);
    const comment = yield select(featuresCommentSelector);
    const {
      // don't question it
      isUnlimitedScan: isUnlimited,
      ...newTicketscanOptions
    } = yield select(newTicketscanSelector);
    const reqBody = {
      ...newTicketscanOptions,
      comment,
      isUnlimited,
    };

    const apiMethod = ACTION_TO_API_METHOD[featureAction];
    try {
      const response = yield call(apiMethod, reqBody);
      const ticketscanOptions = getProp(response, 'data.data.ticketscan', {});
      yield put(performFeaturesActionSuccess({ ticketscanOptions }));
    } catch (error) {
      console.log('Set ticketscan options error', error);
      const statusCode = getProp(error, 'response.status', -1);
      yield put(performFeaturesActionFailure({ errorDetail: error, statusCode }));
    }
  }

  function* watchFeaturesSubmissions() {
    yield takeLatest(PERFORM_FEATURES_ACTION, performFeaturesAction);
  }

  function* putRefreshFeatureAudits() {
    yield put(fetchFeaturesAuditTrail({
      auditTrailLimit: 10,
      auditTrailOffset: 0,
    }));
  }

  function* watchPerformFeaturesActionSucccess() {
    yield takeLatest(PERFORM_FEATURES_ACTION_SUCCESS, putRefreshFeatureAudits);
  }

  return {
    watchFeaturesSubmissions,
    watchFetchFeaturesSettings,
    watchPerformFeaturesActionSucccess,
    watchFetchFeaturesAuditTrail,
    watchFeaturesSaveAuditTrailComment,
  };
};

export default createFeaturesSagas;
