export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const CREATE_SSO_USER = 'CREATE_SSO_USER';
export const CREATE_SSO_USER_SUCCESS = 'CREATE_SSO_USER_SUCCESS';
export const CREATE_SSO_USER_FAILURE = 'CREATE_SSO_USER_FAILURE';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const SORT_USERS = 'SORT_USERS';

export const INIT_ADMINS_SEARCH_PARAMS = 'INIT_ADMINS_SEARCH_PARAMS';
export const FETCH_ADMINS = 'FETCH_ADMINS';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';

export const INIT_PURGED_SEARCH_PARAMS = 'INIT_PURGED_SEARCH_PARAMS';
export const FETCH_PURGED_USER = 'FETCH_PURGED_USER';
export const FETCH_PURGED_USER_SUCCESS = 'FETCH_PURGED_USER_SUCCESS';
export const FETCH_PURGED_USER_FAILURE = 'FETCH_PURGED_USER_FAILURE';

export const FETCH_PURGED_USERS = 'FETCH_PURGED_USERS';
export const FETCH_PURGED_USERS_SUCCESS = 'FETCH_PURGED_USERS_SUCCESS';
export const FETCH_PURGED_USERS_FAILURE = 'FETCH_PURGED_USERS_FAILURE';

export const FETCH_AUDIT_TRAIL = 'FETCH_AUDIT_TRAIL';
export const FETCH_AUDIT_TRAIL_SUCCESS = 'FETCH_AUDIT_TRAIL_SUCCESS';
export const FETCH_AUDIT_TRAIL_FAILURE = 'FETCH_AUDIT_TRAIL_FAILURE';

export const RESET_AUDIT_TRAIL_PAGINATION = 'RESET_AUDIT_TRAIL_PAGINATION';

export const SAVE_AUDIT_TRAIL_COMMENT = 'SAVE_AUDIT_TRAIL_COMMENT';
export const SAVE_AUDIT_TRAIL_COMMENT_SUCCESS = 'SAVE_AUDIT_TRAIL_COMMENT_SUCCESS';
export const SAVE_AUDIT_TRAIL_COMMENT_FAILURE = 'SAVE_AUDIT_TRAIL_COMMENT_FAILURE';

export const UPDATE_USER_DISPLAY = 'UPDATE_USER_DISPLAY';

export const SAVE_CREDENTIALS = 'SAVE_CREDENTIALS';
export const PURGE_TOKENS = 'PURGE_TOKENS';

export const NYL_ADMIN_ACCESS_TOKEN = 'NYL_ADMIN_ACCESS_TOKEN';
export const NYL_ADMIN_REFRESH_TOKEN = 'NYL_ADMIN_REFRESH_TOKEN';
export const NYL_ADMIN_IDENTITY_TOKEN = 'NYL_ADMIN_IDENTITY_TOKEN';
export const NYL_ADMIN_IDENTITY = 'NYL_ADMIN_IDENTITY';
export const NYL_ADMIN_EXPIRES_IN = 'NYL_ADMIN_EXPIRES_IN';

export const INIT_SEARCH_PARAMS = 'INIT_SEARCH_PARAMS';

export const FETCH_FEATURES_SETTINGS = 'FETCH_FEATURES_SETTINGS';
export const FETCH_FEATURES_SETTINGS_SUCCESS = 'FETCH_FEATURES_SETTINGS_SUCCESS';
export const FETCH_FEATURES_SETTINGS_FAILURE = 'FETCH_FEATURES_SETTINGS_FAILURE';

export const FETCH_FEATURES_AUDIT_TRAIL = 'FETCH_FEATURES_AUDIT_TRAIL';
export const FETCH_FEATURES_AUDIT_TRAIL_SUCCESS = 'FETCH_FEATURES_AUDIT_TRAIL_SUCCESS';
export const FETCH_FEATURES_AUDIT_TRAIL_FAILURE = 'FETCH_FEATURES_AUDIT_TRAIL_FAILURE';
export const RESET_FEATURES_AUDIT_TRAIL_PAGINATION = 'RESET_FEATURES_AUDIT_TRAIL_PAGINATION';

export const SET_TICKETSCAN_LIMIT = 'SET_TICKETSCAN_LIMIT';

export const SET_IS_BULK_USER_WORKFLOW = 'SET_IS_BULK_USER_WORKFLOW';
export const SET_IS_SINGLE_USER_WORKFLOW = 'SET_IS_SINGLE_USER_WORKFLOW';
export const SET_IS_FEATURES_WORKFLOW = 'SET_IS_FEATURES_WORKFLOW';

export const SELECT_USER_FOR_BULK = 'SELECT_USER_FOR_BULK';
export const DESELECT_USER_FOR_BULK = 'DESELECT_USER_FOR_BULK';
export const START_BULK_ACTION_WORKFLOW = 'START_BULK_ACTION_WORKFLOW';
export const ADD_COMMENT = 'ADD_COMMENT';
export const CANCEL_BULK_ACTION_WORKFLOW = 'CANCEL_BULK_ACTION_WORKFLOW';
export const FINISH_BULK_ACTION_WORKFLOW = 'FINISH_BULK_ACTION_WORKFLOW';
export const PERFORM_BULK_ACTION = 'PERFORM_BULK_ACTION';
export const PERFORM_BULK_ACTION_SUCCESS = 'PERFORM_BULK_ACTION_SUCCESS';
export const PERFORM_BULK_ACTION_FAILURE = 'PERFORM_BULK_ACTION_FAILURE';

export const START_UPDATE_USER_MODAL_WORKFLOW = 'START_UPDATE_USER_MODAL_WORKFLOW';
export const START_SINGLE_ACTION_WORKFLOW = 'START_SINGLE_ACTION_WORKFLOW';
export const ADD_SINGLE_COMMENT = 'ADD_SINGLE_COMMENT';
export const CANCEL_SINGLE_ACTION_WORKFLOW = 'CANCEL_SINGLE_ACTION_WORKFLOW';
export const PERFORM_SINGLE_ACTION = 'PERFORM_SINGLE_ACTION';
export const PERFORM_SINGLE_ACTION_SUCCESS = 'PERFORM_SINGLE_ACTION_SUCCESS';
export const PERFORM_SINGLE_ACTION_FAILURE = 'PERFORM_SINGLE_ACTION_FAILURE';
export const FINISH_SINGLE_ACTION_WORKFLOW = 'FINISH_SINGLE_ACTION_WORKFLOW';
export const USERS_TABLE_LIMIT = 10;

export const START_FEATURES_WORKFLOW = 'START_FEATURES_WORKFLOW';
export const ADD_FEATURES_COMMENT = 'ADD_FEATURES_COMMENT';
export const ADD_PARAMETERS = 'ADD_PARAMETERS';
export const CANCEL_FEATURES_WORKFLOW = 'CANCEL_FEATURES_WORKFLOW';
export const PERFORM_FEATURES_ACTION = 'PERFORM_FEATURES_ACTION';
export const PERFORM_FEATURES_ACTION_SUCCESS = 'PERFORM_FEATURES_ACTION_SUCCESS';
export const PERFORM_FEATURES_ACTION_FAILURE = 'PERFORM_FEATURES_ACTION_FAILURE';
export const FINISH_FEATURES_WORKFLOW = 'FINISH_FEATURES_WORKFLOW';

export const SAVE_FEATURE_AUDIT_TRAIL_COMMENT = 'SAVE_FEATURE_AUDIT_TRAIL_COMMENT';
export const SAVE_FEATURE_AUDIT_TRAIL_COMMENT_SUCCESS = 'SAVE_FEATURE_AUDIT_TRAIL_COMMENT_SUCCESS';
export const SAVE_FEATURE_AUDIT_TRAIL_COMMENT_FAILURE = 'SAVE_FEATURE_AUDIT_TRAIL_COMMENT_FAILURE';

export const START_TICKETSCAN_TOGGLE_FLOW = 'START_TICKETSCAN_TOGGLE_FLOW';
export const START_TICKETSCAN_LIMIT_FLOW = 'START_TICKETSCAN_LIMIT_FLOW';

export const START_INVITE_ADMIN_FLOW = 'START_INVITE_ADMIN_FLOW';
export const INVITE_ADMIN = 'INVITE_ADMIN';
export const INVITE_ADMIN_SUCCESS = 'INVITE_ADMIN_SUCCESS';
export const INVITE_ADMIN_FAILURE = 'INVITE_ADMIN_FAILURE';

export const START_EDIT_ADMIN_FLOW = 'START_EDIT_ADMIN_FLOW';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAILURE = 'EDIT_ADMIN_FAILURE';

export const START_TOGGLE_ADMIN_FLOW = 'START_TOGGLE_ADMIN_FLOW';
export const LOCK_ADMIN_SUCCESS = 'LOCK_ADMIN_SUCCESS';
export const LOCK_ADMIN_FAILURE = 'LOCK_ADMIN_FAILURE';
export const UNLOCK_ADMIN_SUCCESS = 'UNLOCK_ADMIN_SUCCESS';
export const UNLOCK_ADMIN_FAILURE = 'UNLOCK_ADMIN_FAILURE';

export const PERFORM_ADMINS_ACTION = 'PERFORM_ADMINS_ACTION';

export const FINISH_ADMINS_WORKFLOW = 'FINISH_ADMINS_WORKFLOW';
export const CANCEL_ADMINS_WORKFLOW = 'CANCEL_ADMINS_WORKFLOW';

export const ADD_ADMINS_COMMENT = 'ADD_ADMINS_COMMENT';
export const ADD_ADMIN_DETAILS = 'ADD_ADMIN_DETAILS';
