/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';

class MobileHeader extends PureComponent {
  render() {
    return (
      <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
        <div className="kt-header-mobile__logo">
          <a href="#">
            <img className="nyl-logo-header" alt="Logo" src="https://cdn.nylservices.net/assets/nylotto-logo.png" />
          </a>
        </div>
        <div className="kt-header-mobile__toolbar" style={{ display: 'none' }}>
          <button className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><span /></button>
          <button className="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span /></button>
          <button className="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more" /></button>
        </div>
      </div>
    );
  }
}

export default MobileHeader;
