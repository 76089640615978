import React, { Fragment, useState } from 'react';
import { Input, Modal } from 'antd';

const GameDataToggleModal = ({
  modalVisible, setToggle, handleCancel, messageModalVisible, changeMessage, name, isEnabled, message,
}) => {
  const [inputValue, setInputValue] = useState(message);

  // resets modal message if the change is cancelled
  const onCancelMessage = () => {
    setInputValue(message);
    handleCancel();
  };

  return (
    <Fragment>
      <Modal
        title={`Are you sure you want to ${isEnabled ? 'disable' : 'enable'} ${name}?`}
        centered
        visible={modalVisible}
        okText="Yes"
        cancelText="Cancel"
        onOk={setToggle}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
      />
      <Modal
        title="Do you want to change the message for this game?"
        centered
        visible={messageModalVisible}
        okText="Yes"
        cancelText="No"
        onOk={() => { changeMessage(inputValue); }}
        maskClosable={false}
        onCancel={onCancelMessage}
        destroyOnClose
      >
        <div>
          <Input
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default GameDataToggleModal;
