import {
  SET_IS_SINGLE_USER_WORKFLOW,
  SET_IS_BULK_USER_WORKFLOW,
  SELECT_USER_FOR_BULK,
  DESELECT_USER_FOR_BULK,
  START_BULK_ACTION_WORKFLOW,
  CANCEL_BULK_ACTION_WORKFLOW,
  ADD_COMMENT,
  PERFORM_BULK_ACTION,
  PERFORM_BULK_ACTION_SUCCESS,
  PERFORM_BULK_ACTION_FAILURE,
  FINISH_BULK_ACTION_WORKFLOW,
  START_SINGLE_ACTION_WORKFLOW,
  START_UPDATE_USER_MODAL_WORKFLOW,
  ADD_SINGLE_COMMENT,
  CANCEL_SINGLE_ACTION_WORKFLOW,
  PERFORM_SINGLE_ACTION,
  PERFORM_SINGLE_ACTION_SUCCESS,
  PERFORM_SINGLE_ACTION_FAILURE,
  FINISH_SINGLE_ACTION_WORKFLOW,
  CREATE_SSO_USER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  UPDATE_USER_DISPLAY,
  FETCH_USER,
  FETCH_USER_FAILURE,
  CLEAR_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_AUDIT_TRAIL,
  FETCH_AUDIT_TRAIL_FAILURE,
  FETCH_AUDIT_TRAIL_SUCCESS,
  RESET_AUDIT_TRAIL_PAGINATION,
  SAVE_AUDIT_TRAIL_COMMENT,
  SAVE_AUDIT_TRAIL_COMMENT_FAILURE,
  SAVE_AUDIT_TRAIL_COMMENT_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  SORT_USERS,
  SAVE_CREDENTIALS,
  PURGE_TOKENS,
  INIT_SEARCH_PARAMS,
  INIT_PURGED_SEARCH_PARAMS,
  FETCH_PURGED_USER,
  FETCH_PURGED_USER_SUCCESS,
  FETCH_PURGED_USER_FAILURE,
  FETCH_PURGED_USERS,
  FETCH_PURGED_USERS_SUCCESS,
  FETCH_PURGED_USERS_FAILURE,
  START_FEATURES_WORKFLOW,
  ADD_FEATURES_COMMENT,
  CANCEL_FEATURES_WORKFLOW,
  PERFORM_FEATURES_ACTION,
  PERFORM_FEATURES_ACTION_SUCCESS,
  PERFORM_FEATURES_ACTION_FAILURE,
  FINISH_FEATURES_WORKFLOW,
  FETCH_FEATURES_SETTINGS,
  FETCH_FEATURES_SETTINGS_SUCCESS,
  FETCH_FEATURES_SETTINGS_FAILURE,
  FETCH_FEATURES_AUDIT_TRAIL,
  FETCH_FEATURES_AUDIT_TRAIL_SUCCESS,
  FETCH_FEATURES_AUDIT_TRAIL_FAILURE,
  RESET_FEATURES_AUDIT_TRAIL_PAGINATION,
  SAVE_FEATURE_AUDIT_TRAIL_COMMENT,
  SAVE_FEATURE_AUDIT_TRAIL_COMMENT_SUCCESS,
  SAVE_FEATURE_AUDIT_TRAIL_COMMENT_FAILURE,
  START_TICKETSCAN_TOGGLE_FLOW,
  START_TICKETSCAN_LIMIT_FLOW,
  ADD_PARAMETERS,
  FETCH_ADMINS,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  INIT_ADMINS_SEARCH_PARAMS,
  INVITE_ADMIN,
  INVITE_ADMIN_SUCCESS,
  INVITE_ADMIN_FAILURE,
  FINISH_ADMINS_WORKFLOW,
  CANCEL_ADMINS_WORKFLOW,
  START_INVITE_ADMIN_FLOW,
  START_EDIT_ADMIN_FLOW,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  START_TOGGLE_ADMIN_FLOW,
  ADD_ADMINS_COMMENT,
  ADD_ADMIN_DETAILS,
  PERFORM_ADMINS_ACTION,
  LOCK_ADMIN_SUCCESS,
  LOCK_ADMIN_FAILURE,
  UNLOCK_ADMIN_SUCCESS,
  UNLOCK_ADMIN_FAILURE,
  CREATE_SSO_USER_SUCCESS,
  CREATE_SSO_USER_FAILURE,
} from '../constants/index.js';

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: { },
});

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
  payload: { },
});

export const logoutUserFailure = () => ({
  type: LOGOUT_USER_FAILURE,
  payload: { },
});
export const loginUser = (loginUserForm, push) => ({
  type: LOGIN_USER,
  payload: { loginUserForm, push },
});

export const loginUserSuccess = ({
  accessToken,
  refreshToken,
  identityToken,
  expiresIn,
}) => ({
  type: LOGIN_USER_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
    identityToken,
    expiresIn,
  },
});

export const loginUserFailure = ({ statusCode, errorDetail }) => ({
  type: LOGIN_USER_FAILURE,
  payload: { statusCode, errorDetail },
});

export const fetchUser = ({ userId }) => ({
  type: FETCH_USER,
  payload: { userId },
});

export const fetchUserSuccess = ({ user }) => ({
  type: FETCH_USER_SUCCESS,
  payload: { user },
});

export const fetchUserFailure = ({ errorCode }) => ({
  type: FETCH_USER_FAILURE,
  payload: { errorCode, statusCode: errorCode },
});

// Don't do this, but i gotta be fast
export const clearError = () => ({
  type: CLEAR_ERROR,
  payload: {},
});

export const updateUserDisplay = ({ user }) => ({
  type: UPDATE_USER_DISPLAY,
  payload: { user },
});

export const fetchUsers = ({
  searchParams,
  limit = 10,
  offset = 0,
  order,
  column,
  markedForDeletion,
}) => ({
  type: FETCH_USERS,
  payload: {
    searchParams,
    limit,
    offset,
    order,
    column,
    markedForDeletion,
  },
});

export const fetchUsersSuccess = ({ totalCount, users }) => ({
  type: FETCH_USERS_SUCCESS,
  payload: {
    totalCount,
    users,
  },
});

export const fetchUsersFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_USERS_FAILURE,
  payload: { statusCode, errorDetail },
});

export const fetchPurgedUser = ({ userDbId }) => ({
  type: FETCH_PURGED_USER,
  payload: { userDbId },
});

export const fetchPurgedUserSuccess = ({ user }) => ({
  type: FETCH_PURGED_USER_SUCCESS,
  payload: { user },
});

export const fetchPurgedUserFailure = ({ errorCode }) => ({
  type: FETCH_PURGED_USER_FAILURE,
  payload: { errorCode, statusCode: errorCode },
});
export const initPurgedSearchParams = ({ formParams }) => ({
  type: INIT_PURGED_SEARCH_PARAMS,
  payload: { formParams },
});

export const fetchPurgedUsers = ({
  searchParams, limit = 10, offset = 0, column, order,
}) => ({
  type: FETCH_PURGED_USERS,
  payload: {
    searchParams, limit, offset, column, order,
  },
});

export const fetchPurgedUsersSuccess = ({ totalCount, users }) => ({
  type: FETCH_PURGED_USERS_SUCCESS,
  payload: {
    totalCount,
    users,
  },
});

export const fetchPurgedUsersFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_PURGED_USERS_FAILURE,
  payload: { statusCode, errorDetail },
});

export const initAdminsSearchParams = ({ formParams }) => ({
  type: INIT_ADMINS_SEARCH_PARAMS,
  payload: { formParams },
});

export const fetchAdmins = ({
  searchParams, limit = 10, offset = 0, order, column,
}) => ({
  type: FETCH_ADMINS,
  payload: {
    searchParams,
    limit,
    offset,
    order,
    column,
  },
});

export const fetchAdminsSuccess = ({ totalCount, users }) => ({
  type: FETCH_ADMINS_SUCCESS,
  payload: {
    totalCount,
    users,
  },
});

export const fetchAdminsFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_ADMINS_FAILURE,
  payload: { statusCode, errorDetail },
});

export const fetchAuditTrail = ({
  userDbId,
  auditTrailLimit,
  auditTrailOffset,
}) => ({
  type: FETCH_AUDIT_TRAIL,
  payload: { userDbId, auditTrailLimit, auditTrailOffset },
});

export const fetchAuditTrailSuccess = ({
  auditTrail,
  auditTrailTotalCount,
}) => ({
  type: FETCH_AUDIT_TRAIL_SUCCESS,
  payload: { auditTrail, auditTrailTotalCount },
});

export const fetchAuditTrailFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_AUDIT_TRAIL_FAILURE,
  payload: { statusCode, errorDetail },
});

export const resetAuditTrailPagination = () => ({
  type: RESET_AUDIT_TRAIL_PAGINATION,
});

export const saveAuditTrailComment = ({
  comment,
  userDbId,
  auditTrailLimit,
  auditTrailOffset,
}) => ({
  type: SAVE_AUDIT_TRAIL_COMMENT,
  payload: {
    comment,
    userDbId,
    auditTrailLimit,
    auditTrailOffset,
  },
});

export const saveAuditTrailCommentSuccess = ({ auditTrail }) => ({
  type: SAVE_AUDIT_TRAIL_COMMENT_SUCCESS,
  payload: { auditTrail },
});

export const saveAuditTrailCommentFailure = ({ statusCode, errorDetail }) => ({
  type: SAVE_AUDIT_TRAIL_COMMENT_FAILURE,
  payload: { statusCode, errorDetail },
});

export const saveCredentials = ({ accessToken, identityToken }) => ({
  type: SAVE_CREDENTIALS,
  payload: { accessToken, identityToken },
});

export const createSsoUser = ({ ssoUserDetails }) => ({
  type: CREATE_SSO_USER,
  payload: { ssoUserDetails, isCreating: true },
});

export const createSsoUserSuccess = ({ sub }) => ({
  type: CREATE_SSO_USER_SUCCESS,
  payload: { sub },
});

export const createSsoUserFailure = ({ errorCode, errorDetail }) => ({
  type: CREATE_SSO_USER_FAILURE,
  payload: { errorCode, errorDetail },
});

export const purgeTokens = () => ({
  type: PURGE_TOKENS,
  payload: {},
});

export const initSearchParams = ({ formParams, markedForDeletion }) => ({
  type: INIT_SEARCH_PARAMS,
  payload: { formParams, markedForDeletion },
});

export const sortUsers = ({ column, order }) => ({
  type: SORT_USERS,
  payload: {
    column,
    order,
  },
});

// BULK WORKFLOW ACTIONS
// For use with users search page bulk workflows
// and pending deletion users list bulk workflows

// fire on entering user page
export const setIsSingleUserWorkflow = () => ({
  type: SET_IS_SINGLE_USER_WORKFLOW,
  payload: {},
});

// fire on entering search page
export const setIsBulkUserWorkflow = () => ({
  type: SET_IS_BULK_USER_WORKFLOW,
  payload: {},
});

export const selectUserForBulk = ({ userId }) => ({
  type: SELECT_USER_FOR_BULK,
  payload: { userId },
});

export const deselectUserForBulk = ({ userId }) => ({
  type: DESELECT_USER_FOR_BULK,
  payload: { userId },
});

/*
 * expects
 * {
 *   bulkAction: constant from ../constants/bulk_actions,
 * }
 */
export const startBulkActionWorkflow = ({ bulkActionType }) => ({
  type: START_BULK_ACTION_WORKFLOW,
  payload: {
    bulkActionType,
  },
});

export const cancelBulkActionWorkflow = () => ({
  type: CANCEL_BULK_ACTION_WORKFLOW,
  payload: {},
});

export const addComment = ({ comment = null }) => ({
  type: ADD_COMMENT,
  payload: {
    comment,
  },
});

export const performBulkAction = () => ({
  type: PERFORM_BULK_ACTION,
  payload: {},
});

export const performBulkActionSuccess = ({ successUsers, errorUsers }) => ({
  type: PERFORM_BULK_ACTION_SUCCESS,
  payload: {
    successUsers,
    errorUsers,
  },
});

export const performBulkActionFailure = ({
  statusCode,
}) => ({
  type: PERFORM_BULK_ACTION_FAILURE,
  payload: {
    statusCode,
  },
});

export const finishBulkActionWorkflow = () => ({
  type: FINISH_BULK_ACTION_WORKFLOW,
  payload: {},
});

/*
 * expects
 * {
 *   singleActionType: constant from ../constants/single_actions,
 * }
 */
export const startSingleActionWorkflow = ({
  singleActionType,
  userId,
}) => ({
  type: START_SINGLE_ACTION_WORKFLOW,
  payload: {
    singleActionType,
    userId,
  },
});

// Used to start the single action workflow for update user
// This helps us
// A) get profile update to the update API
// B) Know if we should show verification message
// C) What the userId of the user to update is
export const startUpdateUserModalWorkflow = ({
  profileUpdate,
  shouldShowVerificationMessage,
  userId,
}) => ({
  type: START_UPDATE_USER_MODAL_WORKFLOW,
  payload: {
    profileUpdate,
    shouldShowVerificationMessage,
    userId,
  },
});

export const cancelSingleActionWorkflow = () => ({
  type: CANCEL_SINGLE_ACTION_WORKFLOW,
  payload: {},
});

export const addSingleComment = ({ comment = null }) => ({
  type: ADD_SINGLE_COMMENT,
  payload: {
    comment,
  },
});

export const performSingleAction = () => ({
  type: PERFORM_SINGLE_ACTION,
  payload: {},
});

export const performSingleActionSuccess = () => ({
  type: PERFORM_SINGLE_ACTION_SUCCESS,
  payload: {},
});

export const performSingleActionFailure = ({
  error,
  statusCode,
}) => ({
  type: PERFORM_SINGLE_ACTION_FAILURE,
  payload: {
    error,
    statusCode,
  },
});

export const finishSingleActionWorkflow = () => ({
  type: FINISH_SINGLE_ACTION_WORKFLOW,
  payload: {},
});

/* GLOBAL FEATURES WORKFLOW */
export const startFeaturesWorkflow = ({ featuresActionType }) => ({
  type: START_FEATURES_WORKFLOW,
  payload: {
    featuresActionType,
  },
});

export const cancelFeaturesWorkflow = () => ({
  type: CANCEL_FEATURES_WORKFLOW,
  payload: {},
});

export const addFeaturesComment = ({ comment = null }) => ({
  type: ADD_FEATURES_COMMENT,
  payload: {
    comment,
  },
});

export const performFeaturesAction = () => ({
  type: PERFORM_FEATURES_ACTION,
  payload: {},
});

export const performFeaturesActionSuccess = ({ ticketscanOptions }) => ({
  type: PERFORM_FEATURES_ACTION_SUCCESS,
  payload: { ticketscanOptions },
});

export const performFeaturesActionFailure = ({
  statusCode,
}) => ({
  type: PERFORM_FEATURES_ACTION_FAILURE,
  payload: {
    statusCode,
  },
});

export const finishFeaturesWorkflow = () => ({
  type: FINISH_FEATURES_WORKFLOW,
  payload: {},
});

export const fetchFeaturesSettings = () => ({
  type: FETCH_FEATURES_SETTINGS,
  payload: {},
});

export const fetchFeaturesSettingsSuccess = ({ ticketscanOptions }) => ({
  type: FETCH_FEATURES_SETTINGS_SUCCESS,
  payload: { ticketscanOptions },
});

export const fetchFeaturesSettingsFailure = ({ errorDetail, statusCode }) => ({
  type: FETCH_FEATURES_SETTINGS_FAILURE,
  payload: { errorDetail, statusCode },
});

export const fetchFeaturesAuditTrail = ({
  auditTrailLimit,
  auditTrailOffset,
}) => ({
  type: FETCH_FEATURES_AUDIT_TRAIL,
  payload: { auditTrailLimit, auditTrailOffset },
});

export const fetchFeaturesAuditTrailSuccess = ({
  auditTrail,
  auditTrailTotalCount,
}) => ({
  type: FETCH_FEATURES_AUDIT_TRAIL_SUCCESS,
  payload: {
    auditTrail,
    auditTrailTotalCount,
  },
});

export const fetchFeaturesAuditTrailFailure = ({
  statusCode,
  errorDetail,
}) => ({
  type: FETCH_FEATURES_AUDIT_TRAIL_FAILURE,
  payload: { statusCode, errorDetail },
});

export const resetFeaturesAuditTrailPagination = () => ({
  type: RESET_FEATURES_AUDIT_TRAIL_PAGINATION,
});

export const saveFeatureAuditTrailComment = ({
  comment,
  auditTrailLimit,
  auditTrailOffset,
}) => ({
  type: SAVE_FEATURE_AUDIT_TRAIL_COMMENT,
  payload: {
    comment,
    auditTrailLimit,
    auditTrailOffset,
  },
});

export const saveFeatureAuditTrailCommentSuccess = ({ auditTrail }) => ({
  type: SAVE_FEATURE_AUDIT_TRAIL_COMMENT_SUCCESS,
  payload: { auditTrail },
});

export const saveFeatureAuditTrailCommentFailure = ({
  statusCode,
  errorDetail,
}) => ({
  type: SAVE_FEATURE_AUDIT_TRAIL_COMMENT_FAILURE,
  payload: { statusCode, errorDetail },
});

export const startTicketscanToggleFlow = ({
  newTicketscanOptions,
  toggleType,
}) => ({
  type: START_TICKETSCAN_TOGGLE_FLOW,
  payload: {
    newTicketscanOptions,
    toggleType,
  },
});

export const startTicketscanLimitFlow = ({ newTicketscanOptions }) => ({
  type: START_TICKETSCAN_LIMIT_FLOW,
  payload: {
    newTicketscanOptions,
  },
});

export const addParameters = ({ newTicketscanOptions }) => ({
  type: ADD_PARAMETERS,
  payload: { newTicketscanOptions },
});

export const inviteAdmin = ({ adminDetails }) => ({
  type: INVITE_ADMIN,
  payload: { adminDetails },
});

export const inviteAdminsSuccess = newAdmin => ({
  type: INVITE_ADMIN_SUCCESS,
  payload: { newAdmin },
});

export const inviteAdminsFailure = ({ errorDetail, statusCode }) => ({
  type: INVITE_ADMIN_FAILURE,
  payload: { errorDetail, statusCode },
});

/* ADMIN ACTIONS WORKFLOW */
export const startInviteAdminFlow = ({ adminDetails }) => ({
  type: START_INVITE_ADMIN_FLOW,
  payload: { adminDetails },
});

export const startEditAdminFlow = ({ adminDetails }) => ({
  type: START_EDIT_ADMIN_FLOW,
  payload: { adminDetails },
});

export const editAdminsSuccess = updatedAdmin => ({
  type: EDIT_ADMIN_SUCCESS,
  payload: { updatedAdmin },
});

export const editAdminsFailure = ({ errorDetail, statusCode }) => ({
  type: EDIT_ADMIN_FAILURE,
  payload: { errorDetail, statusCode },
});

export const startToggleAdminFlow = ({ toggleType }) => ({
  type: START_TOGGLE_ADMIN_FLOW,
  payload: { toggleType },
});

export const lockAdminSuccess = updatedAdmin => ({
  type: LOCK_ADMIN_SUCCESS,
  payload: { updatedAdmin },
});

export const lockAdminFailure = ({ statusCode }) => ({
  type: LOCK_ADMIN_FAILURE,
  payload: {
    statusCode,
  },
});

export const unlockAdminSuccess = updatedAdmin => ({
  type: UNLOCK_ADMIN_SUCCESS,
  payload: { updatedAdmin },
});

export const unlockAdminFailure = ({ statusCode }) => ({
  type: UNLOCK_ADMIN_FAILURE,
  payload: {
    statusCode,
  },
});

export const finishAdminsWorkflow = ({
  adminsActionType,
}) => ({
  type: FINISH_ADMINS_WORKFLOW,
  payload: {
    adminsActionType,
  },
});

export const cancelAdminsWorkflow = () => ({
  type: CANCEL_ADMINS_WORKFLOW,
  payload: {},
});

export const addAdminsComment = ({ comment = null }) => ({
  type: ADD_ADMINS_COMMENT,
  payload: {
    comment,
  },
});

export const addAdminDetails = ({ userId, adminDetails }) => ({
  type: ADD_ADMIN_DETAILS,
  payload: { userId, adminDetails },
});

export const performAdminsAction = data => ({
  type: PERFORM_ADMINS_ACTION,
  payload: { data },
});
