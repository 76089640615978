/* eslint-disable arrow-parens */
/* eslint-disable import/first */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../common/components/Table/PaginatedTable.jsx';
import moment from 'moment';
import { fetchAuditTrail } from '../../redux/actions/index.js';

const mapStateToProps = state => ({
  userDbId: _.get(state.usersReducer.user, 'id', null),
  auditTrail: state.usersReducer.auditTrail,
  auditTrailTotalCount: state.usersReducer.auditTrailTotalCount,
  auditTrailLimit: state.usersReducer.auditTrailLimit,
  auditTrailOffset: state.usersReducer.auditTrailOffset,
  errorDetail: state.usersReducer.errorDetail,
  isFetchingAuditTrail: state.usersReducer.isFetchingAuditTrail,
});

const mapDispatchToProps = {
  fetchAuditTrail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  currentPage: stateProps.auditTrailOffset / stateProps.auditTrailLimit + 1,
  getPage(page) {
    const { auditTrailLimit } = stateProps;
    const { userDbId } = stateProps;
    const zeroBased = page - 1;
    const auditTrailOffset = zeroBased * stateProps.auditTrailLimit;
    dispatchProps.fetchAuditTrail({
      userDbId,
      auditTrailLimit,
      auditTrailOffset,
    });
  },
});

class AuditTrail extends Component {
  componentDidMount() {
    const {
      userDbId,
      fetchAuditTrail,
      auditTrailLimit,
      auditTrailOffset,
    } = this.props;

    fetchAuditTrail({
      userDbId,
      auditTrailLimit,
      auditTrailOffset,
    });
  }
  render() {
    const {
      auditTrail,
      currentPage,
      getPage,
      isFetchingAuditTrail,
      auditTrailTotalCount,
      originPath,
    } = this.props;

    const columns = [
      {
        title: 'Admin',
        dataIndex: 'admin_user_first_name',
        render: (text, record) =>
          `${record.admin_user_first_name} ${record.admin_user_last_name}`,
      },
      {
        title: 'Time',
        dataIndex: 'created_at',
        render: record => moment(record).format('MM/DD/YY H:mm'),
      },
      {
        title: 'Activity',
        dataIndex: 'activity',
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        render: (text) => (
          <div
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              maxWidth: '500px',
            }}
          >
            {text}
          </div>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="mt-5 mb-5 col-lg-12 datatable datatable-default">
          <PaginatedTable
            columns={columns}
            dataSource={auditTrail}
            rowKey={record => record.id}
            currentPage={currentPage}
            getPage={getPage}
            loading={isFetchingAuditTrail}
            limit={10}
            totalCount={auditTrailTotalCount}
          />
        </div>
        <Link to={originPath}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {}}
          >
            BACK
          </button>
        </Link>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AuditTrail);
