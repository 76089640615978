import {
  INIT_PURGED_SEARCH_PARAMS,
  FETCH_PURGED_USER,
  FETCH_PURGED_USER_SUCCESS,
  FETCH_PURGED_USER_FAILURE,
  CLEAR_ERROR,
  FETCH_PURGED_USERS,
  FETCH_PURGED_USERS_SUCCESS,
  FETCH_PURGED_USERS_FAILURE,
} from '../constants/index.js';

const defaultState = {
  purgedUser: null,
  totalCount: 0,
  purgedUsers: [],
  limit: 10, // limit to fetch users send to the api
  offset: 0,
  searchParams: [],
  isFetching: false,
  statusCode: false,
  errorDetail: '',
  errorCode: null,
  order: null,
  column: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case INIT_PURGED_SEARCH_PARAMS: {
      const {
        formParams,
      } = action.payload;
      return {
        ...state,
        searchParams: formParams,
        limit: defaultState.limit,
        offset: defaultState.offset,
      };
    }
    case FETCH_PURGED_USERS: {
      const {
        searchParams,
        offset,
        limit,
        order,
        column,
      } = action.payload;
      return {
        ...state,
        users: defaultState.users,
        isFetching: true,
        searchParams,
        limit,
        offset,
        order,
        column,
      };
    }
    case FETCH_PURGED_USERS_SUCCESS: {
      const {
        totalCount,
        users,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        totalCount,
        purgedUsers: users,
      };
    }
    case FETCH_PURGED_USERS_FAILURE: {
      const {
        errorDetail,
        statusCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        statusCode,
        errorDetail,
      };
    }
    case FETCH_PURGED_USER: {
      return {
        ...state,
        purgedUser: null,
        isFetching: true,
      };
    }
    case FETCH_PURGED_USER_SUCCESS: {
      const {
        user,
      } = action.payload;
      return {
        ...state,
        purgedUser: user,
        isFetching: false,
      };
    }
    case FETCH_PURGED_USER_FAILURE: {
      const {
        errorCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        errorCode,
      };
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        errorCode: defaultState.errorCode,
      };
    }
    default:
      return { ...state };
  }
};
