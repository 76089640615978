import { spawn } from 'redux-saga/effects';
import createBulkActionSagas from './bulkActionSaga.js';
import createSingleActionSagas from './singleActionSaga.js';
import createUsersSagas from './userSaga.js';
import createSsoUserSagas from './ssoUserSaga.js';
import createPurgedUserSaga from './purgedUserSaga.js';
import createSaveCredentialsSaga from './loginSaga.js';
import createFeaturesSagas from './featuresSaga.js';
import createAdminSaga from './adminSaga.js';
import createLogoutSaga from './logoutSaga.js';
import apiFactory from '../../services/api.js';

export default function* sagas(history) {
  const api = apiFactory.create();
  const {
    watchFetchUser,
    watchFetchUsers,
    watchFinishBulkActionWorkflow,
    watchFetchAuditTrail,
    watchSaveAuditTrailComment,
  } = createUsersSagas(api, history);

  const {
    watchCreateSsoUser,
  } = createSsoUserSagas(api, history);

  const {
    watchFetchPurgedUser,
    watchFetchPurgedUsers,
  } = createPurgedUserSaga(api, history);

  const {
    watchFetchAdmins,
    watchPerformAdminAction,
    watchFinishAdminActionWorkflow,
  } = createAdminSaga(api, history);

  const {
    watchBulkActionSubmission,
  } = createBulkActionSagas(api);

  const {
    watchSingleActionSubmission,
  } = createSingleActionSagas(api);

  const {
    watchSaveCredentials,
    watchFailures,
  } = createSaveCredentialsSaga();

  const {
    watchLogout,
  } = createLogoutSaga(api);

  const {
    watchFeaturesSubmissions,
    watchFetchFeaturesSettings,
    watchPerformFeaturesActionSucccess,
    watchFetchFeaturesAuditTrail,
    watchFeaturesSaveAuditTrailComment,
  } = createFeaturesSagas(api, history);

  yield spawn(watchSaveCredentials);
  yield spawn(watchFailures);
  yield spawn(watchFetchUser);
  yield spawn(watchCreateSsoUser);
  yield spawn(watchFetchUsers);
  yield spawn(watchFetchPurgedUser);
  yield spawn(watchFetchPurgedUsers);
  yield spawn(watchFinishBulkActionWorkflow);
  yield spawn(watchSingleActionSubmission);
  yield spawn(watchBulkActionSubmission);
  yield spawn(watchFetchAuditTrail);
  yield spawn(watchSaveAuditTrailComment);
  yield spawn(watchFeaturesSubmissions);
  yield spawn(watchPerformFeaturesActionSucccess);
  yield spawn(watchFetchFeaturesSettings);
  yield spawn(watchFetchFeaturesAuditTrail);
  yield spawn(watchFeaturesSaveAuditTrailComment);
  yield spawn(watchFetchAdmins);
  yield spawn(watchPerformAdminAction);
  yield spawn(watchFinishAdminActionWorkflow);
  yield spawn(watchLogout);
}
