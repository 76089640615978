import { put, takeLatest, call } from 'redux-saga/effects';
import { purgeTokens } from '../actions/index.js';
import { LOGOUT_USER } from '../constants/index.js';

const createLogoutSaga = (api) => {
  function* logoutSaga() {
    yield call(api.logOutAdmin);
    yield put(purgeTokens());
  }

  function* watchLogout() {
    yield takeLatest(LOGOUT_USER, logoutSaga);
  }
  return {
    watchLogout,
  };
};

export default createLogoutSaga;
