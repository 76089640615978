import { GENERIC_ERROR_BODY } from './workflow_actions.js';

// BULK ACTION TYPES
export const VERIFY = 'VERIFY';
export const UNVERIFY = 'UNVERIFY';
export const LOCK_ACCOUNT = 'LOCK_ACCOUNT';
export const UNLOCK_ACCOUNT = 'UNLOCK_ACCOUNT';
export const MARK_FOR_DELETION = 'MARK_FOR_DELETION';
export const CANCEL_DELETION = 'CANCEL_DELETION';
export const PURGE = 'PURGE';

// BULK ACTION WORKFLOW STEPS
export {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from './workflow_actions.js';

// The `partialErrorBody` is to be used if only some of the users threw an error. This is an edge
// case, like if one admin is in the middle of doing a bulk workflow and another admin has deleted
// the user in question before the request comes through. Highly unlikely.
export default {
  NONE: {},
  [VERIFY]: {
    ctaText: 'Verification',
    title: 'Account Verification',
    body: 'This will verify the following user acccounts',
    confirmationPhrase: 'verify account',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts\' verification status were set to verified',
    partialErrorBody: 'Marking the following users as verified failed:',
  },
  [UNVERIFY]: {
    ctaText: 'Unverification',
    title: 'Account Unverification',
    body: 'This will unverify the following user accounts',
    // To be displayed underneath the list of users
    verificationNote: {
      body: 'The user will need to log in to SSO and perform the following verification steps:',
      list: [
        'Resubmit their SSN4 or Government ID for ID DataWeb verification',
        'Request a OTP via TEXT or VOICE and submit the OTP for verification',
      ],
    },
    confirmationPhrase: 'unverify account',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts\' verification status was reset. They will need to reverify their accounts',
    partialErrorBody: 'Marking the following users as unverified failed:',
  },
  [LOCK_ACCOUNT]: {
    ctaText: 'Lock user',
    title: 'Lock Account',
    body: 'This will lock the following user accounts. They will not be able to login',
    confirmationPhrase: 'lock account',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts were locked. They will be unable to login',
    partialErrorBody: 'Locking the following user accounts failed:',
  },
  [UNLOCK_ACCOUNT]: {
    ctaText: 'Unlock',
    title: 'Unlock Account',
    body: 'This will unlock the following user accounts. They will be able to login',
    confirmationPhrase: 'unlock account',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts were unlocked. They will be able to login',
    partialErrorBody: 'Unlocking the following user accounts failed:',
  },
  [MARK_FOR_DELETION]: {
    ctaText: 'Delete',
    title: 'Mark for deletion',
    body: 'This will mark the following user accounts for deletion. They will not be able to login',
    confirmationPhrase: 'mark for deletion',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts were marked for deletion',
    partialErrorBody: 'Marking the following user accounts for deletion failed:',
  },
  [CANCEL_DELETION]: {
    ctaText: 'Cancel Deletion',
    title: 'Cancel deletion',
    body: 'This will cancel the pending deletion of the following user accounts. They will be able to login',
    confirmationPhrase: 'cancel deletion',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts\' pending deletion status were cancelled',
    partialErrorBody: 'Cancelling the following user accounts\' pending deletion failed:',
  },
  [PURGE]: {
    ctaText: 'Permanently Delete',
    title: 'Purge Account',
    body: 'This will permanently delete the following user accounts. This cannot be undone',
    confirmationPhrase: 'purge',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following user accounts were permanently deleted',
    // TODO: again, highly unlikely since it'd be a race condition, but if the user being
    // permanently deleted isn't marked already, the deletion will fail. The only way to get to the
    // permanent deletion flow is from views where the user is known to be marked already.
    partialErrorBody: 'Purging the following user accounts failed:',
  },
};
