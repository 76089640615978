export const USERS_SEARCH = 'USERS_SEARCH';
export const PURGED_USERS_SEARCH = 'PURGED_USERS_SEARCH';
export const ADMIN_USERS_SEARCH = 'ADMIN_USERS_SEARCH';

// There is a bug with the way the tokenizer handles textoptions,
// and it is hardcoded to use `name` as its `fuzzySearchKeyAttribute`.
// The `category` is the key that is actually used by the ReactStructuredQuerySearch
// to render the categories in the dropdown and searchbar tokens
const emailCategoryConfig = {
  name: 'Email',
  category: 'Email',
  type: 'text',
  operator: ['contains'],
  isAllowDuplicateCategories: false,
};

const lastNameCategoryConfig = {
  name: 'Last Name',
  category: 'Last Name',
  type: 'text',
  operator: ['contains'],
  isAllowDuplicateCategories: false,
};
const firstNameCategoryConfig = {
  name: 'First Name',
  category: 'First Name',
  type: 'text',
  operator: ['contains'],
  isAllowDuplicateCategories: false,
};

const addressCategoryConfig = {
  name: 'Address',
  category: 'Address',
  type: 'text',
  operator: ['contains'],
  isAllowDuplicateCategories: false,
};

const phoneCategoryConfig = {
  name: 'Phone Number',
  category: 'Phone Number',
  type: 'text',
  operator: ['contains'],
  isAllowDuplicateCategories: false,
};

const roleCategoryConfig = {
  name: 'Role',
  category: 'Role',
  type: 'textoptions',
  operator: ['is'],
  options: ['CSR', 'superadmin'],
  isAllowDuplicateCategories: false,
};

const purgedSearchCategories = [
  emailCategoryConfig,
];

const usersSearchCategories = [
  firstNameCategoryConfig,
  lastNameCategoryConfig,
  addressCategoryConfig,
  phoneCategoryConfig,
  emailCategoryConfig,
];

const adminSearchCategories = [
  firstNameCategoryConfig,
  lastNameCategoryConfig,
  emailCategoryConfig,
  roleCategoryConfig,
];

export default {
  USERS_SEARCH: usersSearchCategories,
  PURGED_USERS_SEARCH: purgedSearchCategories,
  ADMIN_USERS_SEARCH: adminSearchCategories,
};
