import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput.js';
import {
  INVITE_ADMIN,
  EDIT_ADMIN,
} from '../../../../redux/constants/admins_actions.js';
import { emailRegex } from '../../../lib/validation.jsx';

const AdminModal = ({
  adminDetails,
  actionType,
  onInviteSubmit,
  onEditSubmit,
  setOuterSubmitFn,
  setOuterSubmitDisabled,
  isAdmin,
}) => {
  const [form] = Form.useForm();
  const isInvite = actionType === INVITE_ADMIN;
  const isEdit = actionType === EDIT_ADMIN;

  const initialValues = {};

  if (adminDetails) {
    initialValues.firstName = adminDetails.firstName;
    initialValues.lastName = adminDetails.lastName;
    initialValues.phone = adminDetails.phone;
    initialValues.email = adminDetails.email;
    initialValues.isSuperadmin = adminDetails.isSuperadmin;
  }

  // Allows outer control to use submit
  useEffect(() => {
    if (setOuterSubmitFn) {
      setOuterSubmitFn(() => form.submit);
    }
  }, [setOuterSubmitFn, form]);

  // Allows outer control to see disabled status of form
  useEffect(() => {
    if (setOuterSubmitDisabled) {
      setOuterSubmitDisabled(false);
    }
  }, [setOuterSubmitDisabled]);

  const onFinish = (values) => {
    if (isInvite) {
      onInviteSubmit(values);
      return;
    }
    if (isEdit) {
      onEditSubmit(values);
    }
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      preserve={false}
      layout="vertical"
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: 'First Name is required' }]}
      >
        <Input maxLength={100} onPressEnter={form.submit} autocomplete="off" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: 'Last Name is required' }]}
      >
        <Input maxLength={100} onPressEnter={form.submit} autocomplete="off" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          { required: true, message: 'Phone Number is required' },
          {
            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
            message: 'Invalid phone number',
          },
        ]}
      >
        <MaskedInput mask="(111) 111-1111" onPressEnter={form.submit} autocomplete="off" />
      </Form.Item>
      { (isInvite || (isEdit && isAdmin)) && (
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Email is required' },
            { pattern: emailRegex, message: 'Please enter a valid email' },
          ]}
        >
          <Input onPressEnter={form.submit} autocomplete="off" />
        </Form.Item>
      ) }
      <Form.Item
        name="isSuperadmin"
        label="Role"
        rules={[{ required: true, message: 'Role is required' }]}
      >
        <Select placeholder="Select role">
          <Select.Option value={false}>CSR</Select.Option>
          <Select.Option value>Superadmin</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default AdminModal;
