/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import { formatPhoneNumber } from '../../common/lib/formatting.jsx';
import Portlet from '../../common/components/Portlet/index.jsx';
import PaginatedTable from '../../common/components/Table/PaginatedTable.jsx';
import { USERS_TABLE_LIMIT } from '../../redux/constants/index.js';
import BULK_ACTIONS_MAP, {
  CANCEL_DELETION,
  PURGE,
} from '../../redux/constants/bulk_actions.js';
import {
  setIsBulkUserWorkflow,
  fetchUsers,
  selectUserForBulk,
  deselectUserForBulk,
  startBulkActionWorkflow,
} from '../../redux/actions/index.js';
import { hasSelectedUsersSelector, selectedUserIdsSelector } from '../../redux/selectors/bulkActionsSelector.js';
import BulkActionsWorkflowModal from '../../common/components/Modal/BulkActionsWorkflow/index.jsx';
import UsersSearch from '../../common/components/Searchbar/UsersSearch.js';
import CSVDownloadButton from '../../common/components/CSVDownload/CSVDownloadButton.jsx';

import './index.css';

const mapStateToProps = state => ({
  searchParams: state.usersReducer.searchParams,
  users: state.usersReducer.users,
  isAdmin: state.loginReducer.isAdmin,
  totalCount: state.usersReducer.totalCount,
  limit: state.usersReducer.limit,
  markedForDeletion: state.usersReducer.markedForDeletion,
  errorDetail: state.usersReducer.errorDetail,
  isFetching: state.usersReducer.isFetching,
  offset: state.usersReducer.offset,
  selectedUserIds: selectedUserIdsSelector(state),
  isBulkActionsEnabled: hasSelectedUsersSelector(state),
});

const mapDispatchToProps = {
  fetchUsers,
  setIsBulkUserWorkflow,
  selectUserForBulk,
  deselectUserForBulk,
  startBulkActionWorkflow,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  currentPage: (stateProps.offset / stateProps.limit) + 1,
  getPage(page) {
    const {
      limit,
      markedForDeletion,
      searchParams,
    } = stateProps;
    const zeroBased = page - 1;
    const offset = zeroBased * limit;
    dispatchProps.fetchUsers({
      limit,
      markedForDeletion,
      offset,
      searchParams,
    });
  },

  sortColumns(pagination, filters, sorter) {
    const {
      limit,
      markedForDeletion,
      searchParams,
    } = stateProps;
    const offset = (pagination.current - 1) * limit;
    const column = sorter.field;
    const { order } = sorter;
    dispatchProps.fetchUsers({
      limit,
      markedForDeletion,
      offset,
      searchParams,
      column,
      order,
    });
  },
});
@connect(mapStateToProps, mapDispatchToProps, mergeProps)
class UsersView extends Component {
  constructor(props) {
    super(props);
    this.handleSelectUsers = this.handleSelectUsers.bind(this);
    this.handleDeselectUsers = this.handleDeselectUsers.bind(this);
    this.startBulkActionWorkflow = this.startBulkActionWorkflow.bind(this);
  }

  componentDidMount() {
    // Make it so the selected users array gets cleared upon finishing workflow.
    // Required so that we can reuse bulk for single user page
    this.props.setIsBulkUserWorkflow();
  }

  handleSelectUsers(userId) {
    this.props.selectUserForBulk({ userId });
  }

  handleDeselectUsers(userId) {
    this.props.deselectUserForBulk({ userId });
  }

  startBulkActionWorkflow(bulkActionType) {
    this.props.startBulkActionWorkflow({ bulkActionType });
  }

  render() {
    const {
      totalCount,
      isAdmin,
      users,
      getPage,
      sortColumns,
      currentPage,
      isFetching,
      selectedUserIds,
      isBulkActionsEnabled,
      searchParams,
    } = this.props;

    const bulkActionsConfig = [
      // TODO: reinstate isAdmin check after it's verifiable w/ admin account
      ...(isAdmin ? [PURGE] : []),
      CANCEL_DELETION,
    ].map(key => ({
      key,
      label: BULK_ACTIONS_MAP[key].ctaText,
    })).reverse();

    const portletConfig = {
      title: 'PENDING DELETION USER LIST',
      actionsLabel: 'Actions',
      actions: [],
      shouldShowBulkActions: false,
      renderSearchbar: () => (
        <UsersSearch shouldGetMarkedUsers />
      ),
      bulkActions: bulkActionsConfig,
      bulkSubmit: isBulkActionsEnabled && this.startBulkActionWorkflow,
    };

    const rowSelection = {
      hideSelectAll: false,
      selectedRowKeys: selectedUserIds,
      type: 'checkbox',
      onSelect: (user, selected) => {
        // eslint-disable-next-line no-unused-expressions
        selected
          ? this.handleSelectUsers(user.sub)
          : this.handleDeselectUsers(user.sub);
      },
      onSelectAll: () => {
        const shouldDeselect = selectedUserIds.length === users.length;
        // eslint-disable-next-line max-len
        users.forEach(selectedUser => this[shouldDeselect ? 'handleDeselectUsers' : 'handleSelectUsers'](selectedUser.sub)); // Deselect or add them all
      },
      getCheckboxProps: ({ sub }) => ({
        disabled:
        selectedUserIds.length >= USERS_TABLE_LIMIT && !selectedUserIds.includes(sub),
        name: sub,
      }),
    };

    const columns = [
      {
        title: 'First Name',
        dataIndex: 'given_name',
        sorter: (a, b) => ((a.given_name && b.given_name) ? a.given_name.localeCompare(b.given_name) : -1),
      },
      {
        title: 'Last Name',
        dataIndex: 'family_name',
        sorter: (a, b) => ((a.family_name && b.family_name) ? a.family_name.localeCompare(b.family_name) : -1),
      },
      {
        title: 'Address',
        dataIndex: 'address',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => ((a.email && b.email) ? a.email.localeCompare(b.email) : -1),
      },
      {
        title: 'Date Joined',
        dataIndex: 'created_at',
        render: date => moment(date).format('MMMM, D YYYY'),
        sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      },
      {
        title: 'Phone #',
        dataIndex: 'phone_number',
        render: number => formatPhoneNumber(number),
      },
      {
        title: 'Verification Status',
        dataIndex: 'sso_verified',
        sorter: (a, b) => (a.sso_verified === 'Y') - (b.sso_verified === 'Y'),

        render: (status) => {
          const title = status === 'Y' ? 'Verified' : 'Unverified';
          const color = status === 'Y' ? 'green' : 'volcano';
          return (
            <span>
              <Tag color={color}>{title}</Tag>
            </span>
          );
        },
      },
      {
        title: 'Locked',
        dataIndex: 'locked',
        render: status => (
          <span>{status === true ? <i className="flaticon2-lock" /> : ''}</span>
        ),
      },
      {
        title: 'Action',
        render: (text, record) =>
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          (<Link
            to={{
                pathname: `/pending-deletion/${record.sub}`,
                state: {
                  userObj: record,
                },
            }}
          >
            <button
              type="button"
              className="btn btn-link text-primary bg-transparent text-uppercase"
            >
              view/edit
            </button>
          </Link>),
      },
    ];
    const dataIndex = ['given_name', 'family_name', 'address', 'email', 'phone_number', 'sso_verified'];

    return (
      <div className="row">
        <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <BulkActionsWorkflowModal />
          <Portlet config={portletConfig}>
            <PaginatedTable
              columns={columns}
              dataSource={users}
              rowKey={record => record.sub}
              rowSelection={rowSelection}
              currentPage={currentPage}
              getPage={getPage}
              loading={isFetching}
              limit={10}
              totalCount={totalCount}
              onColumnChange={sortColumns}
            />
            {isFetching && users.length > 0 &&
              <div className="text-center">
                <h4>Loading users...</h4>
              </div>
            }
          </Portlet>
          <CSVDownloadButton
            searchParams={searchParams}
            totalCount={totalCount}
            dataIndex={dataIndex}
            fetchType="fetchUsers"
            marked
          />
        </div>
      </div>
    );
  }
}

export default UsersView;
