import {
  START_FEATURES_WORKFLOW,
  ADD_PARAMETERS,
  ADD_FEATURES_COMMENT,
  CANCEL_FEATURES_WORKFLOW,
  PERFORM_FEATURES_ACTION,
  PERFORM_FEATURES_ACTION_SUCCESS,
  PERFORM_FEATURES_ACTION_FAILURE,
  FINISH_FEATURES_WORKFLOW,
  FETCH_FEATURES_SETTINGS,
  FETCH_FEATURES_SETTINGS_SUCCESS,
  FETCH_FEATURES_SETTINGS_FAILURE,
  FETCH_FEATURES_AUDIT_TRAIL,
  FETCH_FEATURES_AUDIT_TRAIL_SUCCESS,
  FETCH_FEATURES_AUDIT_TRAIL_FAILURE,
  RESET_FEATURES_AUDIT_TRAIL_PAGINATION,
  START_TICKETSCAN_TOGGLE_FLOW,
  START_TICKETSCAN_LIMIT_FLOW,
} from '../constants/index.js';

import {
  SET_TICKET_SCAN_LIMIT,
  COMMENT_STEP,
  PARAMETERS_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
} from '../constants/features_actions.js';

const defaultState = {
  // is the modal open?
  isActive: false,
  // This value is used to pull copy from ../constants/features_actions
  isLoading: false,
  isFetchingSettings: false,
  isFetchingAuditTrail: false,
  auditTrail: [],
  auditTrailTotalCount: 0,
  auditTrailLimit: 10,
  auditTrailOffset: 0,
  // as well as the API method for the saga
  featuresActionType: 'NONE',
  // the step of the form
  featuresActionStep: COMMENT_STEP,
  // probably not necessary
  statusCode: false,
  errorDetail: '',
  comment: null,
  newTicketscanOptions: {},
  ticketscanOptions: {
    isEnabled: true,
    scanCountlimit: 10,
    isUnlimitedScan: false,
  },
};

// start workflow payload:
// {
//   featuresActionType: constant from ../constants/features_actions
// }
export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FEATURES_SETTINGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_FEATURES_SETTINGS_SUCCESS: {
      const {
        payload: { ticketscanOptions },
      } = action;
      return {
        ...state,
        isLoading: false,
        ticketscanOptions,
      };
    }
    case FETCH_FEATURES_SETTINGS_FAILURE: {
      const { errorDetail } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorDetail,
      };
    }
    case FETCH_FEATURES_AUDIT_TRAIL: {
      const { auditTrailLimit, auditTrailOffset } = action.payload;
      return {
        ...state,
        isFetchingAuditTrail: true,
        auditTrailLimit,
        auditTrailOffset,
      };
    }
    case FETCH_FEATURES_AUDIT_TRAIL_SUCCESS: {
      const { auditTrail, auditTrailTotalCount } = action.payload;
      return {
        ...state,
        auditTrail,
        auditTrailTotalCount,
        isFetchingAuditTrail: false,
      };
    }
    case FETCH_FEATURES_AUDIT_TRAIL_FAILURE: {
      const { errorDetail } = action.payload;
      return {
        ...state,
        errorDetail,
        isFetchingAuditTrail: false,
      };
    }
    case RESET_FEATURES_AUDIT_TRAIL_PAGINATION:
      return {
        ...state,
        auditTrailOffset: 0,
      };
    case START_FEATURES_WORKFLOW: {
      const {
        payload: { featuresActionType },
      } = action;
      return {
        ...state,
        isActive: true,
        featuresActionType,
        featuresActionStep: defaultState.featuresActionStep,
      };
    }
    case START_TICKETSCAN_TOGGLE_FLOW: {
      const {
        payload: {
          newTicketscanOptions,
          toggleType,
        },
      } = action;
      return {
        ...state,
        isActive: true,
        featuresActionStep: defaultState.featuresActionStep,
        featuresActionType: toggleType,
        newTicketscanOptions,
      };
    }
    case START_TICKETSCAN_LIMIT_FLOW: {
      const {
        payload: {
          newTicketscanOptions,
        },
      } = action;
      return {
        ...state,
        isActive: true,
        featuresActionType: SET_TICKET_SCAN_LIMIT,
        featuresActionStep: PARAMETERS_STEP,
        newTicketscanOptions,
      };
    }
    case CANCEL_FEATURES_WORKFLOW:
      return {
        ...state,
        // this resets the switch
        ticketscanOptions: {
          ...state.ticketscanOptions,
        },
        featuresActionType: defaultState.featuresActionType,
        isActive: false,
      };
    case ADD_FEATURES_COMMENT: {
      return {
        ...state,
        comment: action.payload.comment,
        featuresActionStep: CONFIRM_STEP,
      };
    }
    case ADD_PARAMETERS: {
      const {
        payload: { newTicketscanOptions },
      } = action;
      return {
        ...state,
        newTicketscanOptions,
        featuresActionStep: COMMENT_STEP,
      };
    }
    case PERFORM_FEATURES_ACTION:
      return {
        ...state,
        isFetchingSettings: true,
        featuresActionStep: SUBMITTING,
      };
    case PERFORM_FEATURES_ACTION_SUCCESS: {
      const {
        payload: { ticketscanOptions },
      } = action;
      return {
        ...state,
        isActive: false,
        isFetchingSettings: false,
        featuresActionType: defaultState.featuresActionType,
        featuresActionStep: defaultState.featuresActionStep,
        comment: defaultState.comment,
        newTicketscanOptions: defaultState.newTicketscanOptions,
        ticketscanOptions,
      };
    }
    case PERFORM_FEATURES_ACTION_FAILURE:
      return {
        ...state,
        isFetchingSettings: false,
        featuresActionStep: FAILURE,
      };
    case FINISH_FEATURES_WORKFLOW: {
      return {
        ...state,
        isActive: false,
        featuresActionStep: defaultState.featuresActionStep,
      };
    }
    default:
      return { ...state };
  }
};
