import qs from 'querystring';
import { startCase } from 'lodash';

/**
   * Transform searchParams data array to params object.
   *
   * @param {Array} searchParams - The search data to transform

   * @returns {Object}
*/

/* Sample of searchParams data
  [
    {"category":"Address","value":"112","operator":"contains"},
    {"category":"Last Name","value":"Bond","operator":"contains"},
    {"category":"First Name","value":"James","operator":"contains"},
    {"category":"Phone Number","value":"718","operator":"contains"},
    {"category":"Email","value":"gmail","operator":"contains"}
  ]
*/

/* Sample of params Object
  {
    "address":"112",
    "last_name":"Bond",
    "first_name":"James",
    "phone_number":"718",
    "email":"gmail"
  }
*/

const DEFAULT_OPERATOR = 'contains';

// intermediary transformer
export function queryStringToObj(queryString) {
  return qs.parse(queryString.substr(1));
}

// intermediary transformer
export function objToQueryString(params) {
  return qs.stringify(params);
}

// for creating the API query params from the form
// also acts as an intermediary transformer

export function formToObj(searchParams) {
  return { search_term: searchParams }; // New Search behavior will have to update this all on the backend to accept this
}

// intermediary transformer
export function objToForm(params) {
  const res = Object.keys(params).map((key) => {
    const category = startCase(key);
    const value = params[key];
    return {
      category,
      operator: DEFAULT_OPERATOR,
      value,
    };
  });
  return res;
}

// for populating the form on init
export function queryStringToForm(queryString) {
  const params = queryStringToObj(queryString);
  return objToForm(params);
}

// for populating the location bar
export function formToQueryString(searchParams) {
  const params = formToObj(searchParams);
  return objToQueryString(params);
}
