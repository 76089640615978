// WORKFLOW
export const isAdminsWorkflowActiveSelector = state =>
  state.adminsReducer.isActive;

export const adminsActionTypeSelector = state =>
  state.adminsReducer.adminsActionType;

export const adminsActionStepSelector = state =>
  state.adminsReducer.adminsActionStep;

export const adminsCommentSelector = state => state.adminsReducer.comment;

// INVITE/EDIT ADMIN
export const adminDetailsSelector = state => state.adminsReducer.adminDetails;
export const adminUserIdSelector = state => state.adminsReducer.userId;
