import _ from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly.js';
import sagas from './sagas/index.js';
import rootReducer from './rootReducers.js';
import { fetchUser, getIsAdmin } from '../services/auth.js';

export default function configureStore(initialState, history) {
  const credentials = fetchUser();
  const hydratedState = {
    ...initialState,
  };
  if (credentials) {
    const loginReducer = _.get(hydratedState, 'loginReducer', {});

    if (
      _.isString(credentials.accessToken) &&
      credentials.accessToken.split('.').length === 3
    ) {
      const isAdmin = getIsAdmin(credentials.accessToken);
      hydratedState.loginReducer = {
        ...loginReducer,
        accessToken: credentials.accessToken,
        identityToken: credentials.identityToken,
        isAdmin,
      };
    }
  }
  const sagaMiddleware = createSagaMiddleware();

  const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));

  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  });

  const store = composeEnhancers(middleware)(createStore)(rootReducer, hydratedState);

  sagaMiddleware.run(sagas, history);

  if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('./rootReducers.js', () => {
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./rootReducers.js'));
    });
  }

  return store;
}
