/* eslint-disable react/forbid-prop-types */
import 'react-app-polyfill/ie11';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import IdleTimerHandler from './common/components/IdleTimerHandler/index.jsx';

export default class Root extends Component {
  get content() {
    return (
      <Router history={this.props.history}>
        {this.props.routes}
      </Router>
    );
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <Fragment>
          <IdleTimerHandler />
          {this.content}
        </Fragment>
      </Provider>
    );
  }
}

Root.propTypes = {
  routes: PropTypes.element.isRequired,
  store: PropTypes.object.isRequired,
};
