/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import AsideBrandHeader from './AsideBrandHeader/index.jsx';
import AsideMenu from './AsideMenu/index.jsx';

class Aside extends React.Component {
  render() {
    return (
      <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
        <AsideBrandHeader />
        <AsideMenu />
      </div>
    );
  }
}

export default Aside;
