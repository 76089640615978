import { PURGE_TOKENS, SAVE_CREDENTIALS } from '../constants/index.js';
import { getIsAdmin } from '../../services/auth.js';

const defaultState = {
  accessToken: null,
  identityToken: null,
  isAdmin: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_CREDENTIALS: {
      const {
        accessToken,
        identityToken,
      } = action.payload;

      const isAdmin = getIsAdmin(accessToken);
      return {
        ...state,
        accessToken,
        identityToken,
        isAdmin,
      };
    }
    case PURGE_TOKENS: {
      return { ...defaultState };
    }
    default: {
      return { ...state };
    }
  }
};
