/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import './pagination-fix.css';

const PaginatedTable = ({
  columns,
  dataSource,
  limit,
  loading,
  rowKey,
  rowSelection,
  totalCount,
  currentPage,
  getPage,
  onColumnChange,
}) => {
  const [currentDisplay, setCurrentDisplay] = useState(currentPage);
  // only reset pagination display on finish load
  useEffect(() => {
    if (!loading) {
      setCurrentDisplay(currentPage);
    }
  }, [loading]);
  const getTotal = () => {
    // gets the "page number" as if starting from 0
    const zeroBased = currentDisplay - 1;
    // This is the record number (one-based) of the first record
    const currentPageStart = (zeroBased * limit) + 1;
    // This is the record number (one-based) of the last record
    const currentPageEnd = (zeroBased * limit) + dataSource.length;
    return `Showing ${currentPageStart} - ${currentPageEnd} of ${totalCount}`;
  };
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      rowKey={rowKey}
      pagination={{
        position: ['bottomLeft'],
        showSizeChanger: false,
        total: totalCount,
        current: currentPage,
        onChange: (page) => {
          getPage(page);
        },
        showTotal: () => getTotal(),
      }}
      rowSelection={rowSelection}
      onChange={onColumnChange}
    />
  );
};

PaginatedTable.defaultProps = {
  pagination: {
    limit: 10,
  },
  loading: false,
};

PaginatedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array,
  rowKey: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
};

export default PaginatedTable;

