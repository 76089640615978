import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PERFORM_SINGLE_ACTION } from '../constants/index.js';
import { EDIT_USER, RESET_PASSWORD, ADMIN_PURGE } from '../constants/single_actions.js';
import {
  performSingleActionSuccess,
  performSingleActionFailure,
  updateUserDisplay,
} from '../actions/index.js';
import {
  commentSelector,
  profileUpdateSelector,
  singleActionTypeSelector,
  userIdSelector,
} from '../selectors/singleActionsSelector.js';

const createSingleActionSagas = (api) => {
  function* updateUserSaga() {
    const profileUpdate = yield select(profileUpdateSelector);
    const userId = yield select(userIdSelector);
    const comment = yield select(commentSelector);
    try {
      const response = yield call(api.updateUser, {
        comment,
        profileUpdate,
        userId,
      });
      const user = _.get(response, 'data.data.attributes', {});
      yield put(performSingleActionSuccess());
      yield put(updateUserDisplay({
        user,
      }));
    } catch (error) {
      // The only real error messaging we have is Duplicate Email/Phone
      // TODO: Evaluate with QA any specific error cases
      // TODO: Evaluate with QA if it would be good if we validate form fields
      // before submitting, so that the only errors to really be concerned about
      // are duplicate email/phone
      const displayError = _.get(error, 'response.data.errors.0.detail', '');
      const statusCode = _.get(error, 'response.status', -1);
      yield put(performSingleActionFailure({
        error: displayError,
        statusCode,
      }));
    }
  }

  function* resetPasswordSaga() {
    const userId = yield select(userIdSelector);
    const comment = yield select(commentSelector);
    try {
      yield call(api.resetPassword, {
        comment,
        userId,
      });
      yield put(performSingleActionSuccess());
    } catch (error) {
      const displayError = _.get(error, 'response.data.errors.0.detail', '');
      const statusCode = _.get(error, 'response.status', -1);
      yield put(performSingleActionFailure({ error: displayError, statusCode }));
    }
  }

  function* purgeAdminAccountSaga() {
    const userId = yield select(userIdSelector);
    try {
      yield call(api.purgeAdmin, {
        userId,
      });
      yield put(performSingleActionSuccess());
    } catch (error) {
      const displayError = _.get(error, 'response.data.errors.0.detail', '');
      const statusCode = _.get(error, 'response.status', -1);
      yield put(performSingleActionFailure({ error: displayError, statusCode }));
    }
  }

  function* performSingleAction() {
    const singleAction = yield select(singleActionTypeSelector);
    /* eslint-disable default-case */
    switch (singleAction) {
      case EDIT_USER:
        yield call(updateUserSaga);
        break;
      case RESET_PASSWORD:
        yield call(resetPasswordSaga);
        break;
      case ADMIN_PURGE:
        yield call(purgeAdminAccountSaga);
        break;
    }
    /* eslint-enable default-case */
  }

  function* watchSingleActionSubmission() {
    yield takeLatest(PERFORM_SINGLE_ACTION, performSingleAction);
  }

  return {
    watchSingleActionSubmission,
  };
};

export default createSingleActionSagas;
