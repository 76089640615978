// WORKFLOW
export const isFeaturesWorkflowActiveSelector = state =>
  state.featuresReducer.isActive;

export const featuresActionTypeSelector = state =>
  state.featuresReducer.featuresActionType;

export const featuresActionStepSelector = state =>
  state.featuresReducer.featuresActionStep;

export const featuresCommentSelector = state => state.featuresReducer.comment;

// GLOBAL FEATURES/TICKETSCAN
export const ticketscanSelector = state =>
  state.featuresReducer.ticketscanOptions;

export const newTicketscanSelector = state =>
  state.featuresReducer.newTicketscanOptions;

export const isTicketscanEnabledSelector = state =>
  state.featuresReducer.ticketscanOptions.isEnabled;

export const ticketscanLimitSelector = state =>
  state.featuresReducer.ticketscanOptions.scanCountLimit;

export const isFeaturesLoadingSelector = state =>
  state.featuresReducer.isLoading;

export const isFetchingSettingsSelector = state =>
  state.featuresReducer.isFetchingSettings;

export const isTicketscanUnlimitedSelector = state =>
  state.featuresReducer.ticketscanOptions.isUnlimitedScan;

// AUDIT TRAIL
export const isFeaturesFetchingAuditTrailSelector = state =>
  state.featuresReducer.isFetchingAuditTrail;

export const featuresAuditTrailSelector = state =>
  state.featuresReducer.auditTrail;

export const featuresAuditTrailTotalCountSelector = state =>
  state.featuresReducer.auditTrailTotalCount;

export const featuresAuditTrailLimitSelector = state =>
  state.featuresReducer.auditTrailLimit;

export const featuresAuditTrailOffsetSelector = state =>
  state.featuresReducer.auditTrailOffset;
