/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ContentHeaderMenu from './ContentHeaderMenu/index.jsx';
import ContentHeaderTopbar from './ContentHeaderTopbar/index.jsx';

class ContentHeader extends React.Component {
  render() {
    return (
      <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed">
        <ContentHeaderMenu />
        <ContentHeaderTopbar props={this.props} />
      </div>
    );
  }
}

export default ContentHeader;
