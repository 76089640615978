import {
  FETCH_USERS,
  FETCH_USER_SUCCESS,
  SET_IS_SINGLE_USER_WORKFLOW,
  SET_IS_BULK_USER_WORKFLOW,
  SELECT_USER_FOR_BULK,
  DESELECT_USER_FOR_BULK,
  START_BULK_ACTION_WORKFLOW,
  ADD_COMMENT,
  CANCEL_BULK_ACTION_WORKFLOW,
  PERFORM_BULK_ACTION,
  PERFORM_BULK_ACTION_SUCCESS,
  PERFORM_BULK_ACTION_FAILURE,
  FINISH_BULK_ACTION_WORKFLOW,
} from '../constants/index.js';

import {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../constants/bulk_actions.js';

const defaultState = {
  // is the modal open?
  isActive: false,
  // This value is used to pull copy from ../constants/bulk_actions
  // as well as the API method for the saga
  bulkActionType: 'NONE',
  // the step of the form
  bulkActionStep: COMMENT_STEP,
  // probably not necessary
  statusCode: false,
  // repetitive?
  errorDetail: '',
  // not sure on these two, might be handleable by redux-form
  comment: null,
  // support single workflow not clearing array
  isSingleUserFlow: false,
  users: [],
  // After the bulk action has been performed, the list of users it was successful for
  successUsers: [],
  // After the bulk action has been performed, the list of users it failed for
  errorUsers: [],
};

// start workflow payload:
// {
//   users: [ { userId } ],
//   bulkActionType: constant from ../constants/bulk_actions
// }
export default (state = defaultState, action) => {
  const { users } = state;
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: defaultState.users,
      };
    case SET_IS_SINGLE_USER_WORKFLOW:
      return {
        ...state,
        isSingleUserFlow: true,
      };
    case SET_IS_BULK_USER_WORKFLOW:
      return {
        ...state,
        isSingleUserFlow: false,
      };
    case FETCH_USER_SUCCESS: {
      const {
        user: {
          sub: userId,
        },
      } = action.payload;
      return {
        ...state,
        users: [{ userId }],
      };
    }
    case SELECT_USER_FOR_BULK: {
      const {
        userId,
      } = action.payload;
      const matchedUserIndex = users.findIndex(selectedUser => selectedUser.userId === userId);
      if (matchedUserIndex > -1) {
        // guard against double select
        return { ...state };
      }
      return {
        ...state,
        users: [...users, { userId }],
      };
    }
    case DESELECT_USER_FOR_BULK: {
      const {
        userId,
      } = action.payload;
      const matchedUserIndex = users.findIndex(selectedUser => selectedUser.userId === userId);
      if (matchedUserIndex === -1) {
        // guard against invalid deselect
        return { ...state };
      }
      return {
        ...state,
        users: [
          ...users.slice(0, matchedUserIndex),
          ...users.slice(matchedUserIndex + 1, users.length),
        ],
      };
    }
    case START_BULK_ACTION_WORKFLOW: {
      const {
        payload: {
          bulkActionType,
        },
      } = action;
      return {
        ...state,
        bulkActionType,
        bulkActionStep: defaultState.bulkActionStep,
        isActive: true,
      };
    }
    case CANCEL_BULK_ACTION_WORKFLOW:
      return {
        ...state,
        bulkActionType: defaultState.bulkActionType,
        isActive: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        comment: action.payload.comment,
        bulkActionStep: CONFIRM_STEP,
      };
    case PERFORM_BULK_ACTION:
      return {
        ...state,
        bulkActionStep: SUBMITTING,
      };
    case PERFORM_BULK_ACTION_SUCCESS:
      return {
        ...state,
        bulkActionStep: SUCCESS,
        successUsers: action.payload.successUsers.map(({ userId }) => userId),
        errorUsers: (action.payload.errorUsers || []).map(({ userId }) => userId),
      };
    case PERFORM_BULK_ACTION_FAILURE:
      return {
        ...state,
        bulkActionStep: FAILURE,
      };
    case FINISH_BULK_ACTION_WORKFLOW: {
      if (state.isSingleUserFlow) {
        return {
          ...defaultState,
          isSingleUserFlow: true,
          users: state.users,
        };
      }
      return {
        ...defaultState,
      };
    }
    default:
      return { ...state };
  }
};
