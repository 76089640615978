import { GENERIC_ERROR_BODY } from './workflow_actions.js';

// FEATURE ACTION TYPES
export const INVITE_ADMIN = 'INVITE_ADMIN';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const LOCK_ADMIN = 'LOCK_ADMIN';
export const UNLOCK_ADMIN = 'UNLOCK_ADMIN';

// FEATURE WORKFLOW STEPS
export {
  PARAMETERS_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from './workflow_actions.js';

export default {
  NONE: {},
  [INVITE_ADMIN]: {
    ctaText: 'Add User',
    title: 'Add Admin User Details',
    body: '',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Admin User Created',
  },
  [EDIT_ADMIN]: {
    ctaText: 'EDIT',
    title: 'Edit Admin User Details',
    body: '',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Admin User Updated',
  },
  [LOCK_ADMIN]: {
    ctaText: '',
    title: 'Lock Admin User',
    body: '',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Admin User Locked',
  },
  [UNLOCK_ADMIN]: {
    ctaText: '',
    title: 'Unlock Admin User',
    body: '',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Admin User Unlocked',
  },
};
