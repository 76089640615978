import {
  START_SINGLE_ACTION_WORKFLOW,
  START_UPDATE_USER_MODAL_WORKFLOW,
  ADD_SINGLE_COMMENT,
  CANCEL_SINGLE_ACTION_WORKFLOW,
  PERFORM_SINGLE_ACTION,
  PERFORM_SINGLE_ACTION_SUCCESS,
  PERFORM_SINGLE_ACTION_FAILURE,
  FINISH_SINGLE_ACTION_WORKFLOW,
} from '../constants/index.js';

import {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../constants/bulk_actions.js';

import { EDIT_USER } from '../constants/single_actions.js';

const defaultState = {
  // is the modal open?
  isActive: false,
  // This value is used to pull copy from ../constants/single_actions
  // as well as the API method for the saga
  singleActionType: 'NONE',
  // the step of the form
  singleActionStep: COMMENT_STEP,
  // The error message from the API
  // Most useful for EDIT_USER (duplicate email/phone)
  error: null,
  // An object of values to update a user profile with
  // For use with EDIT_USER flow only
  profileUpdate: {},
  // Determines whether or not to show verification note in modal
  // For use with EDIT_USER flow only
  shouldShowVerificationMessage: false,
  // The sub of the user to perform an action upon
  // For use with EDIT_USER flow
  // Will likely be used for Reset Password flow
  userId: null,
  comment: null,
};

// start workflow payload:
// {
//   singleActionType: constant from ../constants/single_actions
// }
export default (state = defaultState, action) => {
  switch (action.type) {
    case START_SINGLE_ACTION_WORKFLOW: {
      const {
        payload: {
          singleActionType,
          userId,
        },
      } = action;
      return {
        ...state,
        singleActionType,
        singleActionStep: defaultState.singleActionStep,
        isActive: true,
        userId,
      };
    }
    case START_UPDATE_USER_MODAL_WORKFLOW: {
      const {
        payload: {
          profileUpdate,
          shouldShowVerificationMessage,
          userId,
        },
      } = action;
      return {
        ...state,
        profileUpdate,
        shouldShowVerificationMessage,
        userId,
        singleActionType: EDIT_USER,
        singleActionStep: defaultState.singleActionStep,
        isActive: true,
      };
    }
    case CANCEL_SINGLE_ACTION_WORKFLOW:
      return {
        ...state,
        profileUpdate: defaultState.profileUpdate,
        shouldShowVerificationMessage: defaultState.shouldShowVerificationMessage,
        userId: defaultState.userId,
        singleActionType: defaultState.singleActionType,
        isActive: false,
      };
    case ADD_SINGLE_COMMENT:
      return {
        ...state,
        comment: action.payload.comment,
        singleActionStep: CONFIRM_STEP,
      };
    case PERFORM_SINGLE_ACTION:
      return {
        ...state,
        singleActionStep: SUBMITTING,
      };
    case PERFORM_SINGLE_ACTION_SUCCESS:
      return {
        ...state,
        singleActionStep: SUCCESS,
      };
    case PERFORM_SINGLE_ACTION_FAILURE: {
      const {
        payload: {
          error,
        },
      } = action;
      return {
        ...state,
        error,
        singleActionStep: FAILURE,
      };
    }
    case FINISH_SINGLE_ACTION_WORKFLOW: {
      return {
        ...defaultState,
      };
    }
    default:
      return { ...state };
  }
};
