import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop.js';
import { List, Modal } from 'antd';
import {
  featuresActionTypeSelector,
  featuresActionStepSelector,
  isFeaturesWorkflowActiveSelector,
} from '../../../../redux/selectors/featureActionsSelector.js';
import FEATURE_ACTIONS_MAP, {
  COMMENT_STEP,
  PARAMETERS_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../../../../redux/constants/features_actions.js';
import {
  cancelFeaturesWorkflow,
  finishFeaturesWorkflow,
} from '../../../../redux/actions/index.js';
import FeaturesCommentForm from '../../ModalCommentForm/FeaturesCommentForm.js';
import ModalContext from '../ModalContext.js';
import FeaturesConfirmForm from '../../ModalConfirmForm/FeaturesConfirmForm.js';
import TicketscanLimitForm from '../../ModalParametersForm/TicketscanLimitForm.js';
import Loader from '../../Loader/index.jsx';

const mapStateToProps = state => ({
  featuresActionType: featuresActionTypeSelector(state),
  featuresActionStep: featuresActionStepSelector(state),
  isActive: isFeaturesWorkflowActiveSelector(state),
});

const mapDispatchToProps = {
  cancelWorkflow: cancelFeaturesWorkflow,
  finishWorkflow: finishFeaturesWorkflow,
};

const MainCopyBody = ({
  body,
  verificationNote,
}) => (
  <Fragment>
    {body}
    {
      verificationNote && (
        <Fragment>
          {verificationNote.body}
          <List
            dataSource={verificationNote.list}
            prefixCls="modal_verification_list ant-list"
            renderItem={item => (
              <li>{item}</li>
              )}
          />
        </Fragment>
      )
    }
  </Fragment>
);
const getModalBody = ({
  featuresActionStep,
  copyConfig,
}) => {
  switch (featuresActionStep) {
    case COMMENT_STEP:
      return (
        <Fragment>
          <MainCopyBody
            body={copyConfig.body}
          />
          <FeaturesCommentForm />
        </Fragment>
      );
    case PARAMETERS_STEP:
      return (
        <Fragment>
          <TicketscanLimitForm />
        </Fragment>
      );
    case CONFIRM_STEP:
      return (
        <Fragment>
          <MainCopyBody
            body={copyConfig.body}
            verificationNote={copyConfig.verificationNote}
          />
          <p>
            {`Type "${copyConfig.confirmationPhrase}" to confirm`}
          </p>
          <FeaturesConfirmForm
            confirmationPhrase={copyConfig.confirmationPhrase}
          />
        </Fragment>
      );
    case FAILURE:
      return (
        <Fragment>
          {copyConfig.errorBody}
        </Fragment>
      );
    case SUCCESS:
      return (
        <Fragment>
          {copyConfig.successBody}
        </Fragment>
      );
    case SUBMITTING:
    default:
      return (
        <Loader isLoading />
      );
  }
};

const getOnCancel = ({
  featuresActionStep,
  cancelWorkflow,
  finishWorkflow,
}) => {
  switch (featuresActionStep) {
    case SUCCESS:
    case FAILURE:
      return finishWorkflow;
    default:
      return cancelWorkflow;
  }
};

const getOnOk = ({
  featuresActionStep,
  onCancel,
  submitFn,
}) => {
  switch (featuresActionStep) {
    case COMMENT_STEP:
    case PARAMETERS_STEP:
    case CONFIRM_STEP:
      return submitFn;
    default:
      return onCancel;
  }
};

const FeaturesWorkflowModal = ({
  featuresActionType,
  featuresActionStep,
  isActive,
  cancelWorkflow,
  finishWorkflow,
}) => {
  // used by nth-level children to set outer submit control
  const [submitFn, setSubmitFn] = useState(noop);
  // used by nth-level children to set outer submit control disabled state
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const [modalContext] = useState({
    setSubmitFn,
    setSubmitDisabled,
  });

  const copyConfig = FEATURE_ACTIONS_MAP[featuresActionType];
  const isClosable = featuresActionStep !== SUBMITTING;
  const isCancelButtonHidden = [SUBMITTING, SUCCESS, FAILURE]
    .includes(featuresActionStep);
  const onCancel = getOnCancel({
    featuresActionStep,
    cancelWorkflow,
    finishWorkflow,
  });

  // disable the modal submit by the form's status if it's a form,
  // otherwise never disable it
  const isOkButtonDisabled = [COMMENT_STEP, CONFIRM_STEP]
    .includes(featuresActionStep) && isSubmitDisabled;
  const isOkButtonHidden = featuresActionStep === SUBMITTING;
  const onOk = getOnOk({
    featuresActionStep,
    onCancel,
    submitFn,
  });

  const footerOverride = {};
  // No buttons means no need for footer
  if (isOkButtonHidden && isCancelButtonHidden) {
    footerOverride.footer = null;
  }
  return (
    <Modal
      {...footerOverride}
      destroyOnClose
      closable={isClosable}
      keyboard={isClosable}
      maskClosable={isClosable}
      cancelButtonProps={{
        hidden: isCancelButtonHidden,
      }}
      okButtonProps={{
        disabled: isOkButtonDisabled,
        hidden: isOkButtonHidden,
      }}
      onCancel={onCancel}
      onOk={onOk}
      title={copyConfig.title}
      visible={isActive}
    >
      <ModalContext.Provider value={modalContext}>
        <div>
          {
            getModalBody({
              featuresActionStep,
              copyConfig,
            })
          }
        </div>
      </ModalContext.Provider>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesWorkflowModal);
