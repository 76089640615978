import { connect } from 'react-redux';
import {
  fetchUsers,
  initSearchParams,
} from '../../../redux/actions/index.js';
import searchConfigs, { USERS_SEARCH } from '../../lib/searchBarConfig.js';
import StructuredSearchbarForm from './StructuredSearchbarForm.jsx';

// eslint-disable-next-line import/no-named-as-default-member
const searchCategories = searchConfigs[USERS_SEARCH];
const mapStateToProps = state => ({
  isFetching: state.usersReducer.isFetching,
  searchParams: state.usersReducer.searchParams,
});

const mapDispatchToProps = {
  submitSearch: fetchUsers,
  initSearchParams,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  searchCategories,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StructuredSearchbarForm);

