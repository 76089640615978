import { call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { PERFORM_BULK_ACTION } from '../constants/index.js';
import {
  VERIFY,
  UNVERIFY,
  LOCK_ACCOUNT,
  UNLOCK_ACCOUNT,
  MARK_FOR_DELETION,
  CANCEL_DELETION,
  PURGE,
} from '../constants/bulk_actions.js';
import {
  performBulkActionSuccess,
  performBulkActionFailure,
} from '../actions/index.js';
import {
  bulkActionTypeSelector,
  selectedUsersSelector,
  commentSelector,
} from '../selectors/bulkActionsSelector.js';

const isMultiStatus = ({ statusCode }) => statusCode === 207;
const handleMultiStatus = ({
  data: successUsers,
  errors: errorUsers,
}) => ({
  successUsers,
  errorUsers,
});
const handleSuccess = ({
  data: successUsers,
}) => ({
  successUsers,
});

const createBulkActionSagas = (api) => {
  const {
    bulkVerify,
    bulkUnverify,
    bulkLockAccount,
    bulkUnlockAccount,
    bulkMarkForDeletion,
    bulkCancelDeletion,
    bulkPurge,
  } = api;

  const ACTION_TO_API_METHOD = {
    [VERIFY]: bulkVerify,
    [UNVERIFY]: bulkUnverify,
    [LOCK_ACCOUNT]: bulkLockAccount,
    [UNLOCK_ACCOUNT]: bulkUnlockAccount,
    [MARK_FOR_DELETION]: bulkMarkForDeletion,
    [CANCEL_DELETION]: bulkCancelDeletion,
    [PURGE]: bulkPurge,
  };

  function* performBulkAction() {
    const bulkAction = yield select(bulkActionTypeSelector);
    const users = yield select(selectedUsersSelector);
    const comment = yield select(commentSelector);

    const apiMethod = ACTION_TO_API_METHOD[bulkAction];
    // planning ahead so that we can pick user pool if migration from the
    // sso-app pool gets held up for some reason. just in case.
    try {
      const response = yield call(apiMethod, __CONFIG__.ssoUserPoolId, {
        users,
        comment,
      });
      let payload;
      if (isMultiStatus(response)) {
        payload = handleMultiStatus(response);
      } else {
        payload = handleSuccess(response);
      }
      yield put(performBulkActionSuccess(payload));
    } catch (error) {
      const statusCode = _.get(error, 'response.status', -1);
      yield put(performBulkActionFailure({
        error,
        statusCode,
      }));
    }
  }

  function* watchBulkActionSubmission() {
    yield takeLatest(PERFORM_BULK_ACTION, performBulkAction);
  }
  return {
    watchBulkActionSubmission,
  };
};

export default createBulkActionSagas;
