import { connect } from 'react-redux';
import ModalInviteAdmin from './ModalInviteAdmin.jsx';
import { addAdminDetails, performAdminsAction } from '../../../redux/actions/index.js';
import {
  adminDetailsSelector,
  adminsActionTypeSelector,
} from '../../../redux/selectors/adminsActionsSelector.js';

const mapStateToProps = state => ({
  adminDetails: adminDetailsSelector(state),
  actionType: adminsActionTypeSelector(state),
  isAdmin: state.loginReducer.isAdmin,
});

const mapDispatchToProps = {
  addAdminDetails,
  performAdminsAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onInviteSubmit: (values) => {
    dispatchProps.addAdminDetails({
      adminDetails: {
        ...stateProps.adminDetails,
        ...values,
      },
    });
    dispatchProps.performAdminsAction({});
  },
  onEditSubmit: (values) => {
    dispatchProps.addAdminDetails({
      userId: stateProps.adminDetails.userId,
      adminDetails: {
        roleUpdate: {
          role: values.isSuperadmin ? 'superadmin' : 'csr',
        },
        profileUpdate: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          ...(stateProps.isAdmin ? { email: values.email } : {}),
        },
      },
    });
    dispatchProps.performAdminsAction({});
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ModalInviteAdmin);
