import React, { useContext } from 'react';
import ModalContext from '../Modal/ModalContext.js';
import CommentForm from '../CommentForm/index.jsx';

// Use this component as the base for a modal comment
// form so that the modal's buttons have access to submit the form.
const ModalCommentForm = (props) => {
  const {
    setSubmitFn,
    setSubmitDisabled,
  } = useContext(ModalContext);

  return (
    <CommentForm
      {...props}
      setOuterSubmitFn={setSubmitFn}
      setOuterSubmitDisabled={setSubmitDisabled}
    />
  );
};

export default ModalCommentForm;
