import { createContext } from 'react';

const initialValue = {
  config: {},
};

const PortletContext = createContext(initialValue);

PortletContext.displayName = 'Portlet';

export default PortletContext;
