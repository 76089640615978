/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';

const ConfirmForm = ({
  onSubmit,
  setOuterSubmitFn,
  setOuterSubmitDisabled,
  confirmationPhrase,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  // This allows us to provide outer controls the ability to submit this form.
  const [form] = Form.useForm();

  // Allows outer control to use submit
  useEffect(() => {
    if (setOuterSubmitFn) {
      setOuterSubmitFn(() => form.submit);
    }
  }, [setOuterSubmitFn, form]);

  // Allows outer control to see disabled status of the form
  const onValuesChange = (changedValues) => {
    if (Object.hasOwnProperty.call(changedValues, 'phrase')) {
      const {
        phrase,
      } = changedValues;
      const hasConfirm = phrase && phrase.length > 0;
      setOuterSubmitDisabled(!hasConfirm);
    }
  };

  const validationRules = [{
    validator(rule, value) {
      if (value && value === confirmationPhrase) {
        return Promise.resolve();
      }

      return Promise.reject(`You must enter "${confirmationPhrase}".`);
    },
  }];

  const onFinish = () => {
    onSubmit();
  };

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      validateTrigger="onSubmit"
    >
      <Form.Item
        name="phrase"
        rules={validationRules}
      >
        <Input
          className="phrase-input"
          placeholder="Type confirmation phrase"
          autoComplete="off"
          ref={inputRef}
        />
      </Form.Item>
    </Form>
  );
};

export default ConfirmForm;
