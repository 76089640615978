/* eslint-disable arrow-parens */
/* eslint-disable import/first */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Loader from '../../common/components/Loader/index.jsx';
import { Modal, Form, Input } from 'antd';
import Portlet from '../../common/components/Portlet/index.jsx';
import PaginatedTable from '../../common/components/Table/PaginatedTable.jsx';
import {
  fetchFeaturesAuditTrail,
  saveFeatureAuditTrailComment,
  resetFeaturesAuditTrailPagination,
  startFeaturesWorkflow,
  fetchFeaturesSettings,
} from '../../redux/actions/index.js';
import {
  setCommentFocusIntervalRef,
  clearIntervalRef,
} from '../../common/lib/utils.js';
import {
  isFeaturesLoadingSelector,
  featuresAuditTrailSelector,
  isFeaturesFetchingAuditTrailSelector,
  featuresAuditTrailTotalCountSelector,
  featuresAuditTrailLimitSelector,
  featuresAuditTrailOffsetSelector,
} from '../../redux/selectors/featureActionsSelector.js';

const tablePortletConfig = {
  title: 'Features Audit Trail',
};

const mapStateToProps = state => ({
  isAdmin: state.loginReducer.isAdmin,
  auditTrail: featuresAuditTrailSelector(state),
  isFetchingAuditTrail: isFeaturesFetchingAuditTrailSelector(state),
  auditTrailTotalCount: featuresAuditTrailTotalCountSelector(state),
  auditTrailLimit: featuresAuditTrailLimitSelector(state),
  auditTrailOffset: featuresAuditTrailOffsetSelector(state),
  isLoading: isFeaturesLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchFeaturesAuditTrail,
  resetFeaturesAuditTrailPagination,
  fetchFeaturesSettings,
  saveFeatureAuditTrailComment,
  startFeaturesWorkflow,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  currentPage: stateProps.auditTrailOffset / stateProps.auditTrailLimit + 1,
  getPage(page) {
    const { auditTrailLimit } = stateProps;
    const zeroBasedPageNumber = page - 1;
    const auditTrailOffset = zeroBasedPageNumber * stateProps.auditTrailLimit;
    dispatchProps.fetchFeaturesAuditTrail({
      auditTrailLimit,
      auditTrailOffset,
    });
  },
});

const FeaturesAuditTrail = ({
  isAdmin,
  isLoading,
  currentPage,
  getPage,
  auditTrail,
  auditTrailLimit,
  auditTrailOffset,
  auditTrailTotalCount,
  saveFeatureAuditTrailComment,
  fetchFeaturesAuditTrail,
  resetFeaturesAuditTrailPagination,
  isFetchingAuditTrail,
}) => {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  // This effect and refs focuses the comment form field upon displaying it and
  // cleans up interval upon unmount or close modal
  const commentRef = useRef();
  const intervalRef = useRef();
  useEffect(() => {
    if (visible) {
      setCommentFocusIntervalRef(commentRef, intervalRef);
    } else {
      clearIntervalRef(intervalRef);
    }
    return () => clearIntervalRef(intervalRef);
  }, [visible]);

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    fetchFeaturesAuditTrail({ auditTrailLimit, auditTrailOffset });
    // reset pagination upon unmount
    return resetFeaturesAuditTrailPagination;
  }, []);

  const onSubmit = (values) => {
    const comment = _.get(values, 'audit-log-comment');
    saveFeatureAuditTrailComment({
      comment,
      auditTrailLimit,
      auditTrailOffset,
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Admin',
      dataIndex: 'admin_user_first_name',
      render: (text, record) =>
        `${record.admin_user_first_name} ${record.admin_user_last_name}`,
    },
    {
      title: 'Time',
      dataIndex: 'created_at',
      render: record => moment(record).format('MM/DD/YY H:mm'),
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (text) => (
        <div
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            maxWidth: '500px',
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="order-1 col-12">
        <Modal
          title="Please add a comment"
          centered
          visible={visible}
          okText="Submit"
          cancelText="Cancel"
          onOk={form.submit}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Form form={form} onFinish={onSubmit} preserve={false}>
            <Form.Item name="audit-log-comment">
              <Input.TextArea
                className="comment-text-area"
                placeholder="add optional comment here"
                rows={4}
                ref={commentRef}
                maxLength={280}
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </Modal>
        <Loader isLoading={isLoading}>
          <div className="position-relative mt-3">
            <Portlet config={tablePortletConfig}>
              {isAdmin && (
                <div className="features-audit-trail-comment-btn">
                  <button
                    disabled={!isAdmin}
                    onClick={() => {
                      showModal(true);
                    }}
                    className="btn btn-secondary"
                  >
                    Add Comment
                  </button>
                </div>
              )}
              <PaginatedTable
                columns={columns}
                dataSource={auditTrail}
                rowKey={record => record.id}
                currentPage={currentPage}
                getPage={getPage}
                loading={isFetchingAuditTrail}
                limit={10}
                totalCount={auditTrailTotalCount}
              />
            </Portlet>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FeaturesAuditTrail);
