import { createContext } from 'react';

const initialValue = {
  setSubmitDisabled: () => {},
  setSubmitFn: () => {},
};

const ModalContext = createContext(initialValue);

ModalContext.displayName = 'ModalControls';

export default ModalContext;
