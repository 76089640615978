import _ from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import {
  saveUserCredentials,
  flushUser,
} from '../../services/auth.js';
import { SAVE_CREDENTIALS } from '../constants/index.js';
import { purgeTokens } from '../actions/index.js';

// Check if action type has `_FAILURE` at the end
// and if statusCode is 401 (Unauthorized)
const FAILURE_PATTERN = /_FAILURE$/;
const matchFailureAndUnauthorized = (action) => {
  const match = FAILURE_PATTERN.test(action.type);
  if (match) {
    return _.get(action, 'payload.statusCode') === 401;
  }
  return false;
};

const createSaveCredentialsSaga = () => {
  function* saveTokensToCookies(action) {
    const { payload } = action;
    yield call(saveUserCredentials, payload);
  }

  function* purgeTokensReduxAndCookies() {
    // Clears tokens from cookies
    yield call(flushUser);
    // Clears tokens from redux to force redirect to login page
    yield put(purgeTokens());
  }

  // Save the tokens to the cookie store upon sending them to the redux store
  function* watchSaveCredentials() {
    yield takeLatest(SAVE_CREDENTIALS, saveTokensToCookies);
  }

  // If an action that matches /_FAILURE$/ and has a payload with
  // `statusCode === 401` (Unauthorized), remove the tokens from redux
  // and the cookies, to force the user to re-authenticate via login or refresh
  // at the hosted UI login app.
  function* watchFailures() {
    yield takeLatest(matchFailureAndUnauthorized, purgeTokensReduxAndCookies);
  }
  return {
    watchSaveCredentials,
    watchFailures,
  };
};

export default createSaveCredentialsSaga;
