/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import 'react-app-polyfill/ie11';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import { logoutUser } from '../../../redux/actions/index.js';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  logoutUser,
};

class IdleTimerHandler extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      isModalVisible: false,
    };

    this.secondaryTimer = null;
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleExtendTimer = this.handleExtendTimer.bind(this);
  }

  componentDidMount() {
    const {
      logoutUser,
    } = this.props;
    this.secondaryTimer = setInterval(() => {
      const timeRemaining = this.idleTimer.getRemainingTime();
      if (timeRemaining <= 30000 && this.state.isModalVisible === false) {
        this.setState({
          isModalVisible: true,
        });
      }

      if (timeRemaining === 0) {
        logoutUser();
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.secondaryTimer);
  }

  handleOnActive() {
    if (this.state.isModalVisible) {
      this.handleExtendTimer();
    }
  }

  handleExtendTimer() {
    this.idleTimer.reset();
    this.setState({
      isModalVisible: false,
    });
  }

  render() {
    const {
      isModalVisible,
    } = this.state;

    return (
      <Fragment>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          timeout={900000}
          onActive={this.handleOnActive}
          debounce={250}
        />
        <Modal
          title="Your session is idle..."
          visible={isModalVisible}
          onOk={this.handleExtendTimer}
          closable={false}
          maskClosable={false}
          okText="Extend Session"
          cancelButtonProps={{ disabled: true }}
        >
          <p>In order to protect user data, your session will be logged out of in <b>30s</b>. Move your mouse or press 'Extend Session' to keep your session alive.</p>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdleTimerHandler);
