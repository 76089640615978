/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import classnames from 'classnames';

class PortletHeader extends React.Component {
  render() {
    const {
      title,
      tabs,
      activeTab,
      handleTabClick,
      actions,
    } = this.props;

    return (
      <div className="kt-portlet__head kt-portlet__head--lg">
        {tabs ? (
          <div className="col-12 d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs nav-tabs-line">
              {tabs.map((tab, idx) => (
                <li className="nav-item" key={tab}>
                  <a
                    className={classnames('nav-link', {
                      active: idx === activeTab,
                    })}
                    data-toggle="tab"
                    onClick={() => handleTabClick(idx)}
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
            {false && activeTab === 2 && (
              <div>
                <button className="btn btn-secondary">Add Comment</button>
              </div>
            )}
          </div>
        ) : (
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{title || ''}</h3>
          </div>
        )}
        <div className="kt-portlet__head-toolbar">
          <div className="kt-portlet__head-toolbar-wrapper">
            {actions && actions.length > 0 && (
              <div className="dropdown dropdown-inline">
                <button
                  type="button"
                  className="btn btn-clean btn-sm btn-icon btn-icon-md"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="flaticon-more-1" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="kt-nav">
                    <li className="kt-nav__section kt-nav__section--first">
                      <span className="kt-nav__section-text" />
                    </li>
                    {actions.map((action, key) => (
                      <li className="kt-nav__item" key={`action-${key}`}>
                        <a href="#" className="kt-nav__link">
                          <i className="kt-nav__link-icon la la-print" />
                          <span className="kt-nav__link-text">
                            {action.label}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PortletHeader;
