// represents the comment form step
export const COMMENT_STEP = 'COMMENT_STEP';
// represents setting parameters form step
export const PARAMETERS_STEP = 'PARAMETERS_STEP';
// represents setting parameters form step
export const CONFIRM_STEP = 'CONFIRM_STEP';
// represents the submitting step. no form associated
export const SUBMITTING = 'SUBMITTING';
// represents the failure step. no form associated
export const FAILURE = 'FAILURE';
// represents the success step. no form associated
export const SUCCESS = 'SUCCESS';

// To be displayed if the whole request failed (connection err, auth err, etc)
export const GENERIC_ERROR_BODY = 'Something went wrong, please refresh the page and try again';
