export function addSearchField({ category, value, objectFields }) {
  if (objectFields[category]) {
    objectFields[category].push(value);
  } else {
    // eslint-disable-next-line no-param-reassign
    objectFields[category] = [value];
  }
}

export function clearIntervalRef(intervalRef) {
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
    /* eslint-disable no-param-reassign */
    intervalRef.current = null;
    /* eslint-enable no-param-reassign */
  }
}

export function setCommentFocusIntervalRef(commentRef, intervalRef) {
  if (!intervalRef.current) {
    /* eslint-disable no-param-reassign */
    intervalRef.current = setInterval(() => {
    /* eslint-enable no-param-reassign */
      if (commentRef.current) {
        commentRef.current.focus();
        clearInterval(intervalRef.current);
      }
    }, 500);
  }
}
