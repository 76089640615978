import React from 'react';
import { Menu, Button, Dropdown } from 'antd';

const BulkActions = ({
  bulkActions,
  bulkSubmit,
}) => {
  const onClick = (event) => {
    const { key } = event;
    if (key) {
      bulkSubmit(key);
    }
  };
  const menu = () => (
    <Menu onClick={onClick} triggerSubMenuAction={['click']}>
      { bulkActions.map(bulkAction => (
        <Menu.Item key={bulkAction.key}>
          {bulkAction.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomLeft"
      trigger={['click']}
      disabled={!bulkSubmit}
    >
      <Button>Perform Bulk Action</Button>
    </Dropdown>
  );
};
// The array-style react fragment is a workaround due to this issue:
// If using Menu.Divider and wrapping in Fragment, see:
// https://github.com/ant-design/ant-design/issues/18022

export default BulkActions;
