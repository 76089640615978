import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { Form, Input, Select } from 'antd';
import moment from 'moment';
import { states } from '../../common/lib/generateStates.js';
import { emailRegex, normalizeDob } from '../../common/lib/validation.jsx';
import { createSsoUser } from '../../redux/actions/index.js';
import Loader from '../../common/components/Loader/index.jsx';


const { Option } = Select;

const layout = {
  labelCol: {
    md: { span: 6 },
    xl: { span: 5 },
  },
  wrapperCol: {
    md: { span: 9, offset: 1 },
    xl: { span: 8, offset: 1 },
  },
};

const mapStateToProps = state => ({
  errorCode: state.createSsoUserReducer.errorCode,
  errorDetail: state.createSsoUserReducer.errorDetail,
  isCreating: state.createSsoUserReducer.isCreating,
  sub: state.createSsoUserReducer.sub,
});

const mapDispatchToProps = {
  createSsoUser,
};

const CreateSsoUser = ({
  createSsoUser: create,
  errorDetail,
  isCreating,
  sub,
  history,
}) => {
  const [form] = Form.useForm();

  const onFinish = (allValues) => {
    create({
      ssoUserDetails: allValues,
    });
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    console.error({ values, errorFields, outOfDate });
  };

  useEffect(() => {
    if (sub) {
      history.push(`/users/${sub}`);
    }
  }, [sub]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <br />
          <Loader isLoading={isCreating}>
            <Form
              {...layout}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              preserve={false}
            >
              <Form.Item
                name="firstName"
                label="Legal First Name"
                rules={[{ required: true, message: 'First Name is required' }]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Legal Last Name"
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="streetNumber"
                label="House/Building Number"
                rules={[{ required: true, message: 'House/Building Number is required' }]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="street"
                label="Street Name"
                rules={[{ required: true, message: 'Street Name is required' }]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="city"
                label="City/Town"
                rules={[{ required: true, message: 'City/Town is required' }]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item name="state" label="State" rules={[{ required: true, message: 'State is required' }]}>
                <Select>
                  {states.map(state => (
                    <Option key={state} value={state}>{state}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="zip"
                label="Zipcode"
                rules={[
                  { required: true, message: 'Zipcode is required' },
                  { pattern: /^[0-9]*$/, message: 'Zipcode must contain only digits' },
                  { len: 5, message: 'Zipcode length must be 5' },
                ]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  { required: true, message: 'Phone Number is required' },
                  {
                    pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                    message: 'Invalid phone number.',
                  },
                ]}
              >
                <MaskedInput mask="(111) 111-1111" onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { pattern: emailRegex, message: 'Please enter a valid email' },
                ]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              <Form.Item
                name="birthdate"
                label="Birthdate"
                normalize={normalizeDob}
                rules={[
                  { required: true, message: 'Birthdate is required' },
                  {
                    transform: (value) => {
                      const date = moment(value, 'MM/DD/YYYY', true);
                      return date.isValid() && moment().diff(moment(value, 'MM/DD/YYYY', true), 'years');
                    },
                    validator: (_, val) => {
                      if (val < 18) {
                        return Promise.reject(new Error(''));
                      }
                      return Promise.resolve();
                    },
                    message: 'User must be 18 years or older',
                  },
                ]}
              >
                <Input onPressEnter={form.submit} autocomplete="off" />
              </Form.Item>
              {errorDetail && <p className="text-danger text-center">{errorDetail}</p>}
              <div className="pt-3 bg-white card-footer d-flex justify-content-end">
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!!isCreating}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </Form>
          </Loader>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSsoUser);
