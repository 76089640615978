import { GENERIC_ERROR_BODY } from './workflow_actions.js';

// FEATURE ACTION TYPES
export const LOCK_TICKET_SCAN = 'LOCK_TICKET_SCAN';
export const UNLOCK_TICKET_SCAN = 'UNLOCK_TICKET_SCAN';
export const SET_TICKET_SCAN_LIMIT = 'SET_TICKET_SCAN_LIMIT';
export const SET_IS_UNLIMITED = 'SET_IS_UNLIMITED';

// FEATURE WORKFLOW STEPS
export {
  COMMENT_STEP,
  PARAMETERS_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from './workflow_actions.js';

export default {
  NONE: {},
  [LOCK_TICKET_SCAN]: {
    ctaText: 'Lock Ticket Scan',
    title: 'Lock Ticket Scan',
    body: '',
    confirmationPhrase: 'lock ticket scan',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Ticket Scan has been locked',
  },
  [UNLOCK_TICKET_SCAN]: {
    ctaText: 'Unlock Ticket Scan',
    title: 'Unlock Ticket Scan',
    body: '',
    confirmationPhrase: 'unlock ticket scan',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Ticket Scan has been unlocked.',
  },
  [SET_TICKET_SCAN_LIMIT]: {
    ctaText: 'Set Limit',
    title: 'Ticket Scan Limit',
    body: '',
    confirmationPhrase: 'set scan limit',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'Ticket Scan Limit successfully changed.',
  },
};
