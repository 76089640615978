/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ContentHeader from '../ContentHeader/index.jsx';
import ContentBody from '../ContentBody/index.jsx';

class ContentWrapper extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
        <ContentHeader />
        <ContentBody {...this.props}>
          {children}
        </ContentBody>
      </div>
    );
  }
}

export default ContentWrapper;
