import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import GameToggle from './GameToggle.jsx';
import API from '../../../services/api.js';
import Loader from '../Loader/index.jsx';

const api = API.create();

const DataToggleForm = ({ isAdmin }) => {
  const [gamesList, setGamesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [gameLoadingError, setGameLoadingError] = useState(false);

  const fetchData = async () => {
    try {
      const data = await api.getGameToggleData();
      setIsLoading(false);
      setGamesList(data.data.data.attributes.games);
    } catch (err) {
      setGameLoadingError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // this little bit is necessary to avoid a memory leak with this component.
    // there may still be a memory leak error, but it is misleading and removed in React 18
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    // Clean up function
    return () => {
      isMounted = false;
    };
  }, []);

  const handleToggleChange = async (name, enabled) => {
    try {
      await api.toggleGameData(name, enabled);
    } catch (err) {
      Modal.error({ title: 'Error', content: `There was an error toggling ${name}, please try again later.` });
    }
  };

  const handleMessageChange = async (name, message) => {
    try {
      await api.customGameMessage(name, message);
    } catch (err) {
      Modal.error({ title: 'Error', content: `There was an error updating the message for ${name}, please try again later.` });
    }
  };

  // This feature is only available to Superadmins, do not display the controls if not authorized.
  if (!isAdmin) {
    return null;
  }

  return (
    <Loader isLoading={isLoading}>
      <h3 className="data-toggle">Data Toggle</h3>
      <div className="game-toggle-flex">
        {gameLoadingError ? (
          <div>Sorry, there has been an error loading Data Toggle data.</div>
        ) : (
          gamesList.map((game) => {
            const {
              id, gameId, name, message, enabled,
            } = game;
            return (
              <GameToggle
                key={id}
                name={name}
                gameId={gameId}
                message={message}
                enabled={enabled}
                isAdmin={isAdmin}
                toggleChange={handleToggleChange}
                messageChange={handleMessageChange}
              />
            );
          })
        )}
      </div>
    </Loader>
  );
};

export default DataToggleForm;
