import React from 'react';
import { List } from 'antd';

const UserList = ({
  users,
  idPrefix,
}) => (
  <List
    id={`${idPrefix}_user_list`}
    prefixCls="modal_user_list ant-list"
    dataSource={users}
    rowKey="userId"
    renderItem={item => (
      <li>{item.name}</li>
      )}
  />
);

export default UserList;
