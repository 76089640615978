import React, { useContext } from 'react';
import ModalContext from '../Modal/ModalContext.js';
import TicketscanLimitForm from '../TicketscanLimitForm/index.jsx';

// Use this component as the base for a modal comment
// form so that the modal's buttons have access to submit the form.
const ModalParametersForm = (props) => {
  const { setSubmitFn, setSubmitDisabled } = useContext(ModalContext);

  return (
    <TicketscanLimitForm
      {...props}
      setOuterSubmitFn={setSubmitFn}
      setOuterSubmitDisabled={setSubmitDisabled}
    />
  );
};

export default ModalParametersForm;
