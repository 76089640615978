export default async function createCsvPure({
  pageLimit,
  searchParams,
  totalCount,
  dataIndex,
  fetchType,
  marked,
  fetchAPI,
  createBlob,
  URL,
  document,
}) {
  // Documentation note: See /js/services/api.js for the various fetchTypes
  const limit = pageLimit || Math.min(totalCount, 2000);
  const markedForDeletion = marked ? true : null;
  const parsedResponse = [];
  for (let offset = 0; offset < totalCount; offset += limit) {
    const response = await fetchAPI({
      limit, markedForDeletion, offset, searchParams, fetchType,
    });
    parsedResponse.push(response);
  }
  const csvFile = [`${dataIndex.flat()}\n`]; // for the init column names
  for (const entry of parsedResponse.flat()) {
    const newLine = [];
    dataIndex.forEach((item) => {
      // strip new lines use a space + replace comma with nothing
      // eslint-disable-next-line eqeqeq
      const cleanLine = entry[item] != undefined ? entry[item].replace(/\n/g, ' ').replace(/,/g, '') : '';
      newLine.push(`${cleanLine}`);
    });
    csvFile.push(`${newLine.flat()}\n`);
  }
  const blob = createBlob(csvFile, { type: 'text/csv;charset=utf-8;' });
  // This looks extremely hacky, but if we want something to specifically download versus open in a new window
  const link = document.createElement('a');
  const filename = typeof searchParams === 'string' ? `${searchParams}.csv` : 'userlist.csv';
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
}
