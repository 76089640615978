/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';

class AsideBrandHeader extends React.Component {
  render() {
    return (
      <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
        <div className="kt-aside__brand-logo">
          <a href="#">
            <img className="nyl-logo-header" alt="Logo" src="https://cdn.nylservices.net/assets/nylotto-logo.png" />
          </a>
        </div>
      </div>
    );
  }
}

export default AsideBrandHeader;
