import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { List, Modal } from 'antd';
import {
  bulkActionTypeSelector,
  bulkActionStepSelector,
  isBulkActionWorkflowActiveSelector,
  sortedSelectedUserIdNameTuplesSelector,
  sortedSuccessUserIdNameTuplesSelector,
  sortedErrorUserIdNameTuplesSelector,
} from '../../../../redux/selectors/bulkActionsSelector.js';
import BULK_ACTIONS_MAP, {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../../../../redux/constants/bulk_actions.js';
import {
  cancelBulkActionWorkflow,
  finishBulkActionWorkflow,
} from '../../../../redux/actions/index.js';
import BulkActionCommentForm from '../../ModalCommentForm/BulkActionCommentForm.js';
import ModalContext from '../ModalContext.js';
import BulkActionConfirmForm from '../../ModalConfirmForm/BulkActionConfirmForm.js';
import UserList from './UserList.jsx';
import Loader from '../../Loader/index.jsx';
import './index.css';

const noop = () => {};

const mapStateToProps = state => ({
  bulkActionType: bulkActionTypeSelector(state),
  bulkActionStep: bulkActionStepSelector(state),
  isActive: isBulkActionWorkflowActiveSelector(state),
  errorUsers: sortedErrorUserIdNameTuplesSelector(state),
  successUsers: sortedSuccessUserIdNameTuplesSelector(state),
  users: sortedSelectedUserIdNameTuplesSelector(state),
});

const mapDispatchToProps = {
  cancelWorkflow: cancelBulkActionWorkflow,
  finishWorkflow: finishBulkActionWorkflow,
};

const MainCopyBody = ({
  body,
  users,
  verificationNote,
}) => (
  <Fragment>
    {body}
    <UserList users={users} />
    {
      verificationNote && (
        <Fragment>
          {verificationNote.body}
          <List
            dataSource={verificationNote.list}
            prefixCls="modal_verification_list ant-list"
            renderItem={li => (
              <li>{li}</li>
              )}
          />
        </Fragment>
      )
    }
  </Fragment>
);
const getModalBody = ({
  bulkActionStep,
  copyConfig,
  users,
  successUsers,
  errorUsers,
}) => {
  switch (bulkActionStep) {
    case COMMENT_STEP:
      return (
        <Fragment>
          <MainCopyBody
            body={copyConfig.body}
            users={users}
            verificationNote={copyConfig.verificationNote}
          />
          <BulkActionCommentForm />
        </Fragment>
      );
    case CONFIRM_STEP:
      return (
        <Fragment>
          <MainCopyBody
            body={copyConfig.body}
            users={users}
            verificationNote={copyConfig.verificationNote}
          />
          <p>
            {`Type "${copyConfig.confirmationPhrase}" to confirm`}
          </p>
          <BulkActionConfirmForm
            confirmationPhrase={copyConfig.confirmationPhrase}
          />
        </Fragment>
      );
    case FAILURE:
      return (
        <Fragment>
          {copyConfig.errorBody}
        </Fragment>
      );
    case SUCCESS:
      return (
        <Fragment>
          { successUsers.length > 0 && (
            <Fragment>
              {copyConfig.successBody}
              <UserList users={successUsers} />
            </Fragment>
          ) }
          { errorUsers.length > 0 && (
            <Fragment>
              {copyConfig.partialErrorBody}
              <UserList users={errorUsers} />
            </Fragment>
          ) }
        </Fragment>
      );
    case SUBMITTING:
    default:
      return (
        <Loader isLoading />
      );
  }
};

const getOnCancel = ({
  bulkActionStep,
  cancelWorkflow,
  finishWorkflow,
}) => {
  switch (bulkActionStep) {
    case SUCCESS:
    case FAILURE:
      return finishWorkflow;
    default:
      return cancelWorkflow;
  }
};

const getOnOk = ({
  bulkActionStep,
  onCancel,
  submitFn,
}) => {
  switch (bulkActionStep) {
    case COMMENT_STEP:
    case CONFIRM_STEP:
      return submitFn;
    default:
      return onCancel;
  }
};

const BulkActionsWorkflowModal = ({
  bulkActionType,
  bulkActionStep,
  isActive,
  users,
  successUsers,
  errorUsers,
  cancelWorkflow,
  finishWorkflow,
}) => {
  // used by nth-level children to set outer submit control
  const [submitFn, setSubmitFn] = useState(noop);
  // used by nth-level children to set outer submit control disabled state
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const [modalContext] = useState({
    setSubmitFn,
    setSubmitDisabled,
  });

  const copyConfig = BULK_ACTIONS_MAP[bulkActionType];
  const isClosable = bulkActionStep !== SUBMITTING;
  const isCancelButtonHidden = [SUBMITTING, SUCCESS, FAILURE]
    .includes(bulkActionStep);
  const onCancel = getOnCancel({
    bulkActionStep,
    cancelWorkflow,
    finishWorkflow,
  });

  // disable the modal submit by the form's status if it's a form,
  // otherwise never disable it
  const isOkButtonDisabled = [COMMENT_STEP, CONFIRM_STEP]
    .includes(bulkActionStep) && isSubmitDisabled;
  const isOkButtonHidden = bulkActionStep === SUBMITTING;
  const onOk = getOnOk({
    bulkActionStep,
    onCancel,
    submitFn,
  });

  const footerOverride = {};
  // No buttons means no need for footer
  if (isOkButtonHidden && isCancelButtonHidden) {
    footerOverride.footer = null;
  }
  return (
    <Modal
      {...footerOverride}
      destroyOnClose
      closable={isClosable}
      keyboard={isClosable}
      maskClosable={isClosable}
      cancelButtonProps={{
        hidden: isCancelButtonHidden,
      }}
      okButtonProps={{
        disabled: isOkButtonDisabled,
        hidden: isOkButtonHidden,
      }}
      onCancel={onCancel}
      onOk={onOk}
      title={copyConfig.title}
      visible={isActive}
    >
      <ModalContext.Provider value={modalContext}>
        <div>
          {
            getModalBody({
              bulkActionStep,
              copyConfig,
              users,
              successUsers,
              errorUsers,
            })
          }
        </div>
      </ModalContext.Provider>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsWorkflowModal);
