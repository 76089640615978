import React, { useContext } from 'react';
import PortletContext from '../PortletContext.js';
import BulkActions from '../../BulkActions/BulkActions.jsx';

const PortletBulkActions = () => {
  const {
    config: {
      bulkActions,
      bulkSubmit,
    },
  } = useContext(PortletContext);
  return (
    <div className="kt-form kt-fork--label-right kt-margin-t-20 kt-margin-b-10">
      <div className="row align-items-center">
        <div className="col-xl-8 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-12">
              <BulkActions
                bulkActions={bulkActions}
                bulkSubmit={bulkSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortletBulkActions;
