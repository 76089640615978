import { GENERIC_ERROR_BODY } from './workflow_actions.js';

// SINGLE USER ACTION TYPES
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const EDIT_USER = 'EDIT_USER';
export const ADMIN_PURGE = 'ADMIN_PURGE';

// SINGLE USER ACTION WORKFLOW STEPS
export {
  COMMENT_STEP,
  CONFIRM_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from './workflow_actions.js';

export default {
  NONE: {},
  [RESET_PASSWORD]: {
    ctaText: 'Reset Password',
    title: 'Reset Password',
    body: 'This will send an email to the user with a link to reset their password.',
    confirmationPhrase: 'reset password',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'An email has been sent to the user with a password reset link.',
  },
  [EDIT_USER]: {
    title: 'Edit User',
    body: 'You are about to edit this user\'s details',
    // To be displayed if fields that require re-verification are changed
    verificationNote: {
      body: 'The user will need to log in to SSO and perform the following verification steps:',
      list: [
        'Resubmit their SSN4 or Government ID for ID DataWeb verification',
        'Request a OTP via TEXT or VOICE and submit the OTP for verification',
      ],
    },
    confirmationPhrase: 'edit user',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The user\'s details have been successfully updated.',
  },
  [ADMIN_PURGE]: {
    ctaText: 'Permanently Delete',
    title: 'Purge Admin Account',
    body: 'This will permanently delete the admin account. This cannot be undone',
    confirmationPhrase: 'purge admin',
    errorBody: GENERIC_ERROR_BODY,
    successBody: 'The following admin accounts were permanently deleted',
  },
};
