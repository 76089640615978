import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop.js';
import { Modal } from 'antd';
import {
  adminsActionTypeSelector,
  adminsActionStepSelector,
  isAdminsWorkflowActiveSelector,
} from '../../../../redux/selectors/adminsActionsSelector.js';
import ADMINS_ACTIONS_MAP, {
  PARAMETERS_STEP,
  SUBMITTING,
  FAILURE,
  SUCCESS,
} from '../../../../redux/constants/admins_actions.js';
import {
  cancelAdminsWorkflow,
  finishAdminsWorkflow,
} from '../../../../redux/actions/index.js';
import ModalContext from '../ModalContext.js';
import InviteAdminForm from '../../../components/ModalParametersForm/InviteAdminForm.js';
import Loader from '../../Loader/index.jsx';

const mapStateToProps = state => ({
  adminsActionType: adminsActionTypeSelector(state),
  adminsActionStep: adminsActionStepSelector(state),
  isActive: isAdminsWorkflowActiveSelector(state),
});
const mapDispatchToProps = {
  cancelWorkflow: cancelAdminsWorkflow,
  finishAdminsWorkflow,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  finishWorkflow: () => dispatchProps.finishAdminsWorkflow({
    adminsActionType: stateProps.adminsActionType,
  }),
});

const getModalBody = ({ adminsActionStep, copyConfig }) => {
  switch (adminsActionStep) {
    case PARAMETERS_STEP:
      return (
        <Fragment>
          <InviteAdminForm />
        </Fragment>
      );
    case FAILURE:
      return <Fragment>{copyConfig.errorBody}</Fragment>;
    case SUCCESS:
      return <Fragment>{copyConfig.successBody}</Fragment>;
    case SUBMITTING:
    default:
      return <Loader isLoading />;
  }
};

const getOnCancel = ({ adminsActionStep, cancelWorkflow, finishWorkflow }) => {
  switch (adminsActionStep) {
    case SUCCESS:
    case FAILURE:
      return finishWorkflow;
    default:
      return cancelWorkflow;
  }
};

const getOnOk = ({ adminsActionStep, onCancel, submitFn }) => {
  switch (adminsActionStep) {
    case PARAMETERS_STEP:
      return submitFn;
    default:
      return onCancel;
  }
};

const AdminsWorkflowModal = ({
  adminsActionType,
  adminsActionStep,
  isActive,
  cancelWorkflow,
  finishWorkflow,
}) => {
  // used by nth-level children to set outer submit control
  const [submitFn, setSubmitFn] = useState(noop);
  // used by nth-level children to set outer submit control disabled state
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const [modalContext] = useState({
    setSubmitFn,
    setSubmitDisabled,
  });

  const copyConfig = ADMINS_ACTIONS_MAP[adminsActionType];
  const isClosable = adminsActionStep !== SUBMITTING;
  const isCancelButtonHidden = [SUBMITTING, SUCCESS, FAILURE].includes(adminsActionStep);
  const onCancel = getOnCancel({
    adminsActionStep,
    cancelWorkflow,
    finishWorkflow,
  });

  // disable the modal submit by the form's status if it's a form,
  // otherwise never disable it
  // In the case of the Admins Workflow, the submit is never disabled,
  // but we keep this here for consistency.
  // If there's ever a change in the future to perform an async check
  // for values, that's an example of when you'd want to disable the
  // button.
  const isOkButtonDisabled = [PARAMETERS_STEP]
    .includes(adminsActionStep) && isSubmitDisabled;
  const isOkButtonHidden = adminsActionStep === SUBMITTING;
  const onOk = getOnOk({
    adminsActionStep,
    onCancel,
    submitFn,
  });

  const footerOverride = {};
  // No buttons means no need for footer
  if (isOkButtonHidden && isCancelButtonHidden) {
    footerOverride.footer = null;
  }

  return (
    <Modal
      {...footerOverride}
      destroyOnClose
      closable={isClosable}
      keyboard={isClosable}
      maskClosable={isClosable}
      cancelButtonProps={{
        hidden: isCancelButtonHidden,
      }}
      okButtonProps={{
        disabled: isOkButtonDisabled,
        hidden: isOkButtonHidden,
      }}
      onCancel={onCancel}
      onOk={onOk}
      title={copyConfig.title}
      visible={isActive}
    >
      <ModalContext.Provider value={modalContext}>
        <div>
          {getModalBody({
            adminsActionStep,
            copyConfig,
          })}
        </div>
      </ModalContext.Provider>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AdminsWorkflowModal);
