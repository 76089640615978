import _ from 'lodash';
import { select, put, takeLatest, call } from 'redux-saga/effects';
import {
  FINISH_BULK_ACTION_WORKFLOW,
  FETCH_USER,
  FETCH_USERS,
  FETCH_AUDIT_TRAIL,
  SAVE_AUDIT_TRAIL_COMMENT,
} from '../constants/index.js';

import {
  fetchUserSuccess,
  fetchUserFailure,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  fetchAuditTrail,
  fetchAuditTrailSuccess,
  fetchAuditTrailFailure,
  saveAuditTrailCommentFailure,
} from '../actions/index.js';

const createUserSagas = (api) => {
  function* fetchUserSaga(action) {
    const { userId } = action.payload;
    let response;
    try {
      response = yield call(api.fetchSingleUser, { userId });
      const user = _.get(response, 'data.data', {});
      yield put(fetchUserSuccess({
        user,
      }));
    } catch (error) {
      console.log('Fetch user error', error);
      const errorCode = _.get(error, 'response.status', -1);
      yield put(fetchUserFailure({ errorCode }));
    }
  }

  function* watchFetchUser() {
    yield takeLatest(FETCH_USER, fetchUserSaga);
  }

  function* fetchUsersSaga(action) {
    const {
      searchParams, limit, offset, markedForDeletion, column, order,
    } = action.payload;

    let response;
    try {
      response = yield call(api.fetchUsers, {
        searchParams,
        limit,
        offset,
        markedForDeletion,
        column,
        order,
      });
      const { totalCount, users } = _.get(response, 'data.data', {
        count: 0,
        totalCount: 0,
        users: [],
      });
      yield put(fetchUsersSuccess({
        totalCount,
        users,
      }));
    } catch (error) {
      console.log('Fetch users error', error);
      const statusCode = _.get(error, 'response.status', -1);
      yield put(fetchUsersFailure({ error, statusCode }));
    }
  }

  function* watchFetchUsers() {
    yield takeLatest(FETCH_USERS, fetchUsersSaga);
  }

  function* fetchAuditTrailSaga(action) {
    const { userDbId, auditTrailLimit, auditTrailOffset } = action.payload;

    let response;
    try {
      // TODO Add pagination params. Should be done with Audit Trail overhaul since old Audit Trail
      // uses a standard table. Should use `<PaginatedTable>`. Lower prio than other functionality
      response = yield call(api.fetchUserAuditTrail, {
        userDbId,
        limit: auditTrailLimit,
        offset: auditTrailOffset,
      });
      const { auditLog: auditTrail, totalCount } = _.get(
        response,
        'data.data',
        {},
      );
      yield put(fetchAuditTrailSuccess({
        auditTrail,
        auditTrailTotalCount: totalCount,
      }));
    } catch (error) {
      console.log('Fetch audit trail error', error);
      const displayError = _.get(error, 'response.data.errors.0.detail', '');
      const statusCode = _.get(error, 'response.status', -1);
      yield put(fetchAuditTrailFailure({ errorDetail: displayError, statusCode }));
    }
  }

  function* watchFetchAuditTrail() {
    yield takeLatest(FETCH_AUDIT_TRAIL, fetchAuditTrailSaga);
  }

  function* saveAuditTrailCommentSaga(action) {
    const {
      comment,
      userDbId,
      auditTrailLimit,
      auditTrailOffset,
    } = action.payload;
    try {
      yield call(api.postUserAuditTrailComment, {
        userDbId,
        body: { comment },
      });
      yield put(fetchAuditTrail({ userDbId, auditTrailLimit, auditTrailOffset }));
    } catch (error) {
      console.log('Save audit trail comment error', error);
      const statusCode = _.get(error, 'response.status', -1);
      yield put(saveAuditTrailCommentFailure({ errorDetail: error, statusCode }));
    }
  }

  function* watchSaveAuditTrailComment() {
    yield takeLatest(SAVE_AUDIT_TRAIL_COMMENT, saveAuditTrailCommentSaga);
  }

  function* selectParamsAndPutFetchUsers() {
    const shouldProcSearch = yield select((state) => {
      const store = state.bulkActionsReducer;
      return !store.isSingleUserFlow;
    });
    if (shouldProcSearch) {
      const params = yield select((state) => {
        const store = state.usersReducer;
        const { searchParams, markedForDeletion, limit } = store;
        return {
          searchParams,
          markedForDeletion,
          limit,
        };
      });
      yield put(fetchUsers(params));
    }
  }

  function* watchFinishBulkActionWorkflow() {
    yield takeLatest(FINISH_BULK_ACTION_WORKFLOW, selectParamsAndPutFetchUsers);
  }

  return {
    watchFinishBulkActionWorkflow,
    watchFetchUsers,
    watchFetchUser,
    watchFetchAuditTrail,
    watchSaveAuditTrailComment,
  };
};

export default createUserSagas;
