import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import {
  NYL_ADMIN_ACCESS_TOKEN,
  NYL_ADMIN_EXPIRES_IN,
  NYL_ADMIN_IDENTITY,
  NYL_ADMIN_IDENTITY_TOKEN,
  NYL_ADMIN_REFRESH_TOKEN,
} from '../redux/constants/index.js';

import {
  setCookieItem,
  getCookieItem,
  removeCookieItem,
} from './cookies.js';

export const saveUserCredentials = ({ accessToken, identityToken }) => {
  setCookieItem(NYL_ADMIN_ACCESS_TOKEN, accessToken);
  setCookieItem(NYL_ADMIN_IDENTITY_TOKEN, identityToken);
  return true;
};

export const fetchUser = () => {
  const identityPayload = {};
  identityPayload.accessToken = getCookieItem(NYL_ADMIN_ACCESS_TOKEN);
  identityPayload.identityToken = getCookieItem(NYL_ADMIN_IDENTITY_TOKEN);
  return identityPayload;
};

export const flushUser = () => {
  removeCookieItem(NYL_ADMIN_ACCESS_TOKEN);
  removeCookieItem(NYL_ADMIN_IDENTITY_TOKEN);
  removeCookieItem(NYL_ADMIN_REFRESH_TOKEN);
  removeCookieItem(NYL_ADMIN_EXPIRES_IN);
  removeCookieItem(NYL_ADMIN_IDENTITY);
};

export const validateToken = (accessToken) => {
  if (accessToken) {
    const accessTokenDecoded = jwtDecode(accessToken);
    const tokenExpiration = _.get(accessTokenDecoded, 'exp', false);
    const tokenDate = new Date(tokenExpiration);
    return new Date().getTime() / 1000 < tokenDate;
  }
  return false;
};

export const getIsAdmin = (accessToken) => {
  const accessTokenDecoded = jwtDecode(accessToken);
  const groups = _.get(accessTokenDecoded, 'cognito:groups', []);
  return groups.includes('superadmin');
};
