/* eslint-disable react/prefer-stateless-function */
import React from 'react';

class ContentHeaderTopbarUserBar extends React.Component {
  render() {
    const isDisplayName = false;
    return (
      <div className="kt-header__topbar-item kt-header__topbar-item--user">
        <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
          <div className="kt-header__topbar-user kt-rounded-">
            {isDisplayName &&
              <div>
                <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi</span>
                <span className="kt-header__topbar-username kt-hidden-mobile">Admin</span>
              </div>
            }
            <span className="kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-badge--bold">AU</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentHeaderTopbarUserBar;
