import _ from 'lodash';

import { put, takeLatest, call } from 'redux-saga/effects';
import { CREATE_SSO_USER } from '../constants/index.js';
import { createSsoUserFailure, createSsoUserSuccess } from '../actions/index.js';

const createSsoUserSagas = (api) => {
  function* createSsoUserSaga(action) {
    const {
      ssoUserDetails,
    } = action.payload;

    try {
      const response = yield call(api.createSsoUser, {
        userPoolId: __CONFIG__.ssoUserPoolId,
        body: ssoUserDetails,
      });
      const sub = response.data.User.Attributes.find(attr => attr.Name === 'sub').Value;
      yield put(createSsoUserSuccess({ sub }));
    } catch (error) {
      console.error('create sso user error', error);
      const errorDetail = _.get(error, 'response.data.errors.0.detail', 'Something went wrong');
      const errorCode = _.get(error, 'response.status', -1);
      yield put(createSsoUserFailure({ errorCode, errorDetail }));
    }
  }

  function* watchCreateSsoUser() {
    yield takeLatest(CREATE_SSO_USER, createSsoUserSaga);
  }

  return {
    watchCreateSsoUser,
  };
};

export default createSsoUserSagas;
