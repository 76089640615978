import React, { useEffect, useState, useRef } from 'react';
import { Form, Radio, InputNumber } from 'antd';

const TicketscanLimitForm = ({
  initialValues,
  onSubmit,
  setOuterSubmitFn,
  setOuterSubmitDisabled,
}) => {
  const [isUnlimited, setIsUnlimited] = useState(initialValues.isUnlimitedScan);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  // This allows us to provide outer controls the ability to submit this form.
  const [form] = Form.useForm();

  // Allows outer control to use submit
  useEffect(() => {
    if (setOuterSubmitFn) {
      setOuterSubmitFn(() => form.submit);
    }
  }, [setOuterSubmitFn, form]);

  // Allows outer control to see disabled status of form
  useEffect(() => {
    if (setOuterSubmitDisabled) {
      setOuterSubmitDisabled(false);
    }
  }, [setOuterSubmitDisabled]);

  // Remove limit errors on isUnlimited
  useEffect(() => {
    const newScanCountLimit = isUnlimited ? null : initialValues.scanCountLimit;
    form.setFields([{
      name: 'scanCountLimit',
      errors: [],
      touched: false,
      validating: false,
      value: newScanCountLimit,
    }]);
  }, [isUnlimited]);

  const onChange = (e) => {
    setIsUnlimited(e.target.value);
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '60px',
  };

  const limitRules = [
    {
      validator(rule, value) {
        if (isUnlimited || (value > 0 && value <= 2000)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid daily ticketscan limit (1 - 2000)'));
      },
    },
  ];

  return (
    <Form
      name="ticketscan"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      preserve={false}
      validateTrigger="onSubmit"
    >
      <Form.Item name="isUnlimitedScan">
        <Radio.Group onChange={onChange}>
          <Radio style={radioStyle} value>
            Unlimited
            <Radio style={radioStyle} value={false}>
              Number
            </Radio>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Scan(s)"
        name="scanCountLimit"
        rules={limitRules}
      >
        <InputNumber
          className="col-4"
          ref={inputRef}
          disabled={isUnlimited}
          autocomplete="off"
        />
      </Form.Item>
    </Form>
  );
};

export default TicketscanLimitForm;
