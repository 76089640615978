import { createSelector } from 'reselect';

export const isBulkActionWorkflowActiveSelector = state => state.bulkActionsReducer.isActive;
export const bulkActionTypeSelector = state => state.bulkActionsReducer.bulkActionType;
export const bulkActionStepSelector = state => state.bulkActionsReducer.bulkActionStep;
export const commentSelector = state => state.bulkActionsReducer.comment;
export const selectedUsersSelector = state => state.bulkActionsReducer.users;
const successUsersSelector = state => state.bulkActionsReducer.successUsers;
const errorUsersSelector = state => state.bulkActionsReducer.errorUsers;

const idToNameMapSelector = (state) => {
  const userPageUser = state.usersReducer.user;
  const baseMap = {};
  if (userPageUser && userPageUser.sub) {
    baseMap[userPageUser.sub] = `${userPageUser.given_name} ${userPageUser.family_name}`;
  }
  return state.usersReducer.users.reduce((acc, next) => ({
    ...acc,
    [next.sub]: `${next.given_name} ${next.family_name}`,
  }), baseMap);
};

export const hasSelectedUsersSelector = createSelector(
  selectedUsersSelector,
  users => users.length > 0,
);

const createUserIdsSelector = usersSelector =>
  createSelector(
    usersSelector,
    users => users.map(({ userId }) => userId),
  );

export const selectedUserIdsSelector = createUserIdsSelector(selectedUsersSelector);

const createIdNameTuplesSelector = userIdSelector =>
  createSelector(
    idToNameMapSelector,
    userIdSelector,
    (idToNameMap, userIds) => userIds.map(userId => ({
      userId,
      name: idToNameMap[userId],
    })),
  );

const selectedUserIdNameTuplesSelector = createIdNameTuplesSelector(selectedUserIdsSelector);

const successUserIdNameTuplesSelector = createIdNameTuplesSelector(successUsersSelector);

const errorUserIdNameTuplesSelector = createIdNameTuplesSelector(errorUsersSelector);

const createSortedUserIdNameTuplesSelector = userIdNameTuplesSelector =>
  createSelector(
    userIdNameTuplesSelector,
    userIdNameTuples => [...userIdNameTuples].sort(({ name: a }, { name: b }) => a.localeCompare(b, undefined, {
      sensitivity: 'accent',
    })),
  );

export const sortedSelectedUserIdNameTuplesSelector = createSortedUserIdNameTuplesSelector(selectedUserIdNameTuplesSelector);

export const sortedSuccessUserIdNameTuplesSelector = createSortedUserIdNameTuplesSelector(successUserIdNameTuplesSelector);

export const sortedErrorUserIdNameTuplesSelector = createSortedUserIdNameTuplesSelector(errorUserIdNameTuplesSelector);
