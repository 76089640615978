import React, { useContext } from 'react';
import PortletContext from '../PortletContext.js';
import PortletSearch from '../Search/index.jsx';
import PortletBulkActions from '../BulkActions/index.jsx';

const PortletBody = ({ children }) => {
  const {
    config: {
      shouldShowBulkActions,
      renderSearchbar,
    },
  } = useContext(PortletContext);
  return (
    <div className="kt-portlet__body">
      {renderSearchbar &&
        <PortletSearch />
      }
      { shouldShowBulkActions && (
        <PortletBulkActions />
      ) }
      <div className="kt_datatable" id="local_data">
        {children}
      </div>
    </div>
  );
};

export default PortletBody;
