/* eslint-disable no-shadow */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from 'react';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import moment from 'moment';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { states } from '../../common/lib/generateStates.js';
import { startUpdateUserModalWorkflow } from '../../redux/actions/index.js';
import { formatPhoneForDetailsForm } from '../../common/lib/formatting.jsx';
import { emailRegex, normalizeDob } from '../../common/lib/validation.jsx';

const { Option } = Select;

const mapDispatchToProps = {
  startUpdateUserModalWorkflow,
};

const addressIsoMap = ['streetNumber', 'street', 'city', 'state', 'zip'];
// TODO: to be moved to saga or service
const createParsedAddress = (address) => {
  let addressParts = [];
  if (_.isString(address)) {
    addressParts = address.split('|');
  }
  return addressIsoMap.reduce(
    (acc, next, index) => ({
      ...acc,
      [next]: _.get(addressParts, index, ''),
    }),
    {},
  );
};

const VERIFICATION_REQUIRED_FIELDS = new Set([
  'firstName',
  'lastName',
  'email',
  'phone',
  'birthdate',
]);

const isUpdateRequireReverification = updatedFields => _.some(
  updatedFields,
  fieldName => VERIFICATION_REQUIRED_FIELDS.has(fieldName),
);

const layout = {
  labelCol: {
    md: { span: 6 },
    xl: { span: 5 },
  },
  wrapperCol: {
    md: { span: 9, offset: 1 },
    xl: { span: 8, offset: 1 },
  },
};

const Details = ({
  user,
  originPath,
  startUpdateUserModalWorkflow,
}) => {
  const [isEditing, setIsEditing] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [form] = Form.useForm();
  // creates the initial values upon receiving a new user
  useEffect(() => {
    const userAddress = createParsedAddress(_.get(user, 'address_iso'));
    const phone = formatPhoneForDetailsForm(_.get(user, 'phone_number', ''));
    const derivedValues = {
      firstName: _.get(user, 'given_name', ''),
      lastName: _.get(user, 'family_name', ''),
      streetNumber: userAddress.streetNumber,
      street: userAddress.street,
      addressLine2: _.get(user, 'addressLine2', ''),
      city: userAddress.city,
      state: userAddress.state,
      zip: userAddress.zip,
      phone,
      email: _.get(user, 'email', ''),
      birthdate: _.get(user, 'birthdate', ''),
    };
    setInitialValues(derivedValues);
    setIsEditing(false);
  }, [user]);

  // Render initial values upon opening form
  useEffect(() => {
    if (isEditing === false) {
      form.resetFields();
    }
  }, [isEditing]);

  // we should not even get to this since the Details view is wrapped
  // in a Loader
  if (!user) {
    return null;
  }

  const handleCancel = () => {
    setIsEditing(false);
  };

  const onFinish = (allValues) => {
    const fieldNames = Object.keys(initialValues);
    const updatedFields = fieldNames.filter(fieldName => initialValues[fieldName] !== allValues[fieldName]);
    const shouldShowVerificationMessage = isUpdateRequireReverification(updatedFields);
    startUpdateUserModalWorkflow({
      profileUpdate: allValues,
      shouldShowVerificationMessage,
      userId: user.sub,
    });
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    console.error({ values, errorFields, outOfDate });
  };

  return (
    <Fragment>
      {user.marked_for_deletion === false ? <div className="mt-5 mb-5 col-lg-2">
        {isEditing ? (
          <span className="font-italic user-select-none">Edit Info</span>
        ) : (
          <button
            className="p-0 bg-transparent btn btn-link text-reset font-weight-bold user-select-none"
            onClick={() => setIsEditing(true)}
          >
            <u className="text-primary">Edit Info</u>
          </button>
        )}
      </div> : ''}
      <Form
        {...layout}
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        preserve={false}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: 'First Name is required' }]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.firstName}</span>
          )}
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: 'Last Name is required' }]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.lastName}</span>
          )}
        </Form.Item>
        <Form.Item
          name="streetNumber"
          label="House/Building Number"
          rules={[{ required: true, message: 'House/Building Number is required' }]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.streetNumber}</span>
          )}
        </Form.Item>
        <Form.Item
          name="street"
          label="Street Name"
          rules={[{ required: true, message: 'Street Name is required' }]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.street}</span>
          )}
        </Form.Item>
        <Form.Item name="addressLine2" label="Apartment/Floor/Other">
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.addressLine2}</span>
          )}
        </Form.Item>
        <Form.Item
          name="city"
          label="City/Town"
          rules={[{ required: true, message: 'City/Town is required' }, { max: 20 }]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.city}</span>
          )}
        </Form.Item>
        <Form.Item
          name="zip"
          label="Zipcode"
          rules={[
            { required: true, message: 'Zipcode is required' },
            { pattern: /^[0-9]*$/, message: 'Zipcode must contain only digits' },
            { len: 5, message: 'Zipcode length must be 5' },
          ]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.zip}</span>
          )}
        </Form.Item>
        <Form.Item name="state" label="State" rules={[{ required: true, message: 'State is required' }]}>
          {isEditing ? (
            <Select>
              {states.map(state => (
                <Option key={state} value={state}>{state}</Option>
              ))}
            </Select>
          ) : (
            <span>{initialValues.state}</span>
          )}
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            { required: true, message: 'Phone Number is required' },
            {
              pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              message: 'Invalid phone number.',
            },
          ]}
        >
          {isEditing ? (
            <MaskedInput mask="(111) 111-1111" onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.phone}</span>
          )}
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Email is required' },
            { pattern: emailRegex, message: 'Please enter a valid email' },
          ]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.email}</span>
          )}
        </Form.Item>
        <Form.Item
          name="birthdate"
          label="Birthdate"
          normalize={normalizeDob}
          rules={[
            { required: true, message: 'Birthdate is required' },
            {
              transform: (value) => {
                const date = moment(value, 'MM/DD/YYYY', true);
                return date.isValid() && moment().diff(moment(value, 'MM/DD/YYYY', true), 'years');
              },
              validator: (_, val) => {
                if (val < 18) {
                  return Promise.reject(new Error(''));
                }
                return Promise.resolve();
              },
              message: 'User must be 18 yrs or older',
            },
            {
              pattern: /^(?:0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
              message: 'Birthdate must be in mm/dd/yyyy format',
            },
          ]}
        >
          {isEditing ? (
            <Input onPressEnter={form.submit} autocomplete="off" />
          ) : (
            <span>{initialValues.birthdate}</span>
          )}
        </Form.Item>
        <div className="pt-3 bg-white card-footer d-flex justify-content-between">
          <Link to={originPath}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {}}
            >
              BACK
            </button>
          </Link>
          {user.marked_for_deletion === false ?
            <div>
              <button
                type="button"
                className="mr-3 btn btn-primary"
                disabled={!isEditing}
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isEditing}
              >
                SAVE
              </button>
            </div> : ''}
        </div>
      </Form>
    </Fragment>
  );
};

export default connect(null, mapDispatchToProps)(Details);
