import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import Portlet from '../../common/components/Portlet/index.jsx';
import PaginatedTable from '../../common/components/Table/PaginatedTable.jsx';
import { fetchPurgedUsers } from '../../redux/actions/index.js';
import PurgedUsersSearch from '../../common/components/Searchbar/PurgedUsersSearch.js';
import CSVDownloadButton from '../../common/components/CSVDownload/CSVDownloadButton.jsx';

const mapStateToProps = state => ({
  searchParams: state.purgedUsersReducer.searchParams,
  purgedUsers: state.purgedUsersReducer.purgedUsers,
  isAdmin: state.loginReducer.isAdmin,
  count: state.purgedUsersReducer.count,
  limit: state.purgedUsersReducer.limit,
  statusCode: state.purgedUsersReducer.statusCode,
  errorDetail: state.purgedUsersReducer.errorDetail,
  isFetching: state.purgedUsersReducer.isFetching,
  offset: state.purgedUsersReducer.offset,
  totalCount: state.purgedUsersReducer.totalCount,
  order: state.purgedUsersReducer.order,
  column: state.purgedUsersReducer.column,
});

const mapDispatchToProps = {
  fetchPurgedUsers,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  currentPage: (stateProps.offset / stateProps.limit) + 1,
  getPage(page) {
    const {
      limit,
      searchParams,
    } = stateProps;
    const zeroBased = page - 1;
    const offset = zeroBased * limit;
    dispatchProps.fetchPurgedUsers({
      limit,
      offset,
      searchParams,
    });
  },
  sortColumns(pagination, filters, sorter) {
    const {
      limit,
      searchParams,
    } = stateProps;
    const offset = (pagination.current - 1) * limit;
    const column = sorter.field;
    const { order } = sorter;
    dispatchProps.fetchPurgedUsers({
      limit,
      offset,
      searchParams,
      column,
      order,
    });
  },
});

@connect(mapStateToProps, mapDispatchToProps, mergeProps)
class PurgedUsers extends Component {
  componentDidMount() {
    const {
      limit,
      offset,
      searchParams,
    } = this.props;
    // Make it so the selected users array gets cleared upon finishing workflow.
    // Required so that we can reuse bulk for single user page
    // setIsBulkUserWorkflow()
    this.props.fetchPurgedUsers({ searchParams, limit, offset });
  }

  render() {
    const {
      purgedUsers,
      isFetching,
      getPage,
      sortColumns,
      currentPage,
      searchParams,
      totalCount,
    } = this.props;
    const portletConfig = {
      title: 'PERMANENTLY DELETED USER LIST',
      actionsLabel: 'Actions',
      actions: [],
      renderSearchbar: () => (
        <PurgedUsersSearch />
      ),
    };

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: 'Date Deleted',
        dataIndex: 'deleted_at',
        render: date => moment.utc(date).local().format('MM/DD/YYYY'),
        sorter: (a, b) => moment(a.deleted_at).unix() - moment(b.deleted_at).unix(),
      },
      {
        title: 'Time Deleted',
        dataIndex: 'deleted_at',
        render: date => moment.utc(date).local().format('h:mm a'),
        sorter: (a, b) => moment(a.deleted_at).unix() - moment(b.deleted_at).unix(),
      },
      {
        title: 'Action Approved By',
        dataIndex: 'deleted_by_last_name',
        sorter: (a, b) => a.deleted_by_last_name.localeCompare(b.deleted_by_last_name),
        render: (text, record) =>
          `${record.deleted_by_first_name} ${record.deleted_by_last_name}`,
      },
      {
        title: 'Action',
        render: (text, record) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to={{
              pathname: `/purged-users/${record.id}`,
              state: {
                userObj: record,
              },
            }}
          >
            <button
              type="button"
              className="btn btn-link text-primary bg-transparent text-uppercase"
            >
              view
            </button>
          </Link>
        ),
      },
    ];
    const dataIndex = ['email', 'deleted_at', 'deleted_by_last_name'];
    return (
      <div className="row">
        <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <Portlet config={portletConfig}>
            <PaginatedTable
              columns={columns}
              dataSource={purgedUsers}
              rowKey={record => record.id}
              loading={isFetching}
              currentPage={currentPage}
              getPage={getPage}
              limit={10}
              totalCount={totalCount}
              onColumnChange={sortColumns}
            />
            {isFetching && purgedUsers.length > 0 && (
              <div className="text-center">
                <h4>Loading users...</h4>
              </div>
            )}
          </Portlet>
          <CSVDownloadButton
            searchParams={searchParams}
            totalCount={totalCount}
            dataIndex={dataIndex}
            fetchType="fetchPurgedUsers"
            marked
          />
        </div>
      </div>
    );
  }
}

export default PurgedUsers;
