import React from 'react';
import PortletContext from './PortletContext.js';
import PortletHeader from './Header/index.jsx';
import PortletBody from './Body/index.jsx';

const Portlet = ({
  activeTab,
  handleTabClick,
  config,
  children,
}) => (
  <div className="kt-portlet kt-portlet--height-fluid">
    <PortletContext.Provider value={{ config }}>
      <PortletHeader
        tabs={config.tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        title={config.title}
        actionsLabel={config.actionsLabel}
        actions={config.actions}
      />
      <PortletBody>
        {children}
      </PortletBody>
    </PortletContext.Provider>
  </div>
);

export default Portlet;
