import {
  CLEAR_ERROR,
  FETCH_ADMINS,
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_SUCCESS,
  INIT_ADMINS_SEARCH_PARAMS,
  START_INVITE_ADMIN_FLOW,
  START_EDIT_ADMIN_FLOW,
  ADD_ADMIN_DETAILS,
  ADD_ADMINS_COMMENT,
  CANCEL_ADMINS_WORKFLOW,
  FINISH_ADMINS_WORKFLOW,
  PERFORM_ADMINS_ACTION,
  INVITE_ADMIN_SUCCESS,
  INVITE_ADMIN_FAILURE,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  START_TOGGLE_ADMIN_FLOW,
  LOCK_ADMIN_SUCCESS,
  LOCK_ADMIN_FAILURE,
  UNLOCK_ADMIN_SUCCESS,
  UNLOCK_ADMIN_FAILURE,
} from '../constants/index.js';
import {
  INVITE_ADMIN,
  EDIT_ADMIN,
  PARAMETERS_STEP,
  LOCK_ADMIN,
  UNLOCK_ADMIN,
  SUBMITTING,
  SUCCESS,
  FAILURE,
} from '../constants/admins_actions.js';

const defaultState = {
  isActive: false,
  admin: null,
  userId: null,
  totalCount: 0,
  admins: [],
  limit: 10, // limit to fetch users send to the api
  offset: 0,
  searchParams: [],
  isFetching: false,
  statusCode: false,
  errorDetail: '',
  errorCode: null,
  comment: null,
  adminDetails: {},
  adminsActionType: 'NONE',
  adminsActionStep: PARAMETERS_STEP,
  order: null,
  column: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case INIT_ADMINS_SEARCH_PARAMS: {
      const { formParams } = action.payload;
      return {
        ...state,
        searchParams: formParams,
        limit: defaultState.limit,
        offset: defaultState.offset,
      };
    }
    case FETCH_ADMINS: {
      const {
        searchParams, offset, limit, column, order,
      } = action.payload;
      return {
        ...state,
        isFetching: true,
        searchParams,
        limit,
        offset,
        column,
        order,
      };
    }
    case FETCH_ADMINS_SUCCESS: {
      const { totalCount, users } = action.payload;
      return {
        ...state,
        isFetching: false,
        totalCount,
        admins: users,
      };
    }
    case FETCH_ADMINS_FAILURE: {
      const { errorDetail, statusCode } = action.payload;
      return {
        ...state,
        isFetching: false,
        statusCode,
        errorDetail,
      };
    }
    case START_INVITE_ADMIN_FLOW: {
      return {
        ...state,
        isActive: true,
        adminsActionType: INVITE_ADMIN,
        adminsActionStep: PARAMETERS_STEP,
      };
    }
    case START_TOGGLE_ADMIN_FLOW: {
      const { toggleType } = action.payload;
      return {
        ...state,
        isActive: true,
        adminsActionType: toggleType ? UNLOCK_ADMIN : LOCK_ADMIN,
      };
    }
    case START_EDIT_ADMIN_FLOW: {
      const {
        payload: { adminDetails },
      } = action;
      return {
        ...state,
        isActive: true,
        adminDetails,
        adminsActionType: EDIT_ADMIN,
        adminsActionStep: PARAMETERS_STEP,
      };
    }
    case EDIT_ADMIN_SUCCESS: {
      const { updatedAdmin } = action.payload;
      return {
        ...state,
        adminsActionStep: SUCCESS,
        adminDetails: defaultState.adminDetails,
        admins: state.admins.map((admin) => {
          if (admin.sub === updatedAdmin.sub) {
            return { ...admin, ...updatedAdmin };
          }
          return admin;
        }),
      };
    }
    case EDIT_ADMIN_FAILURE:
      return {
        ...state,
        adminDetails: defaultState.adminDetails,
        adminsActionStep: FAILURE,
      };
    case LOCK_ADMIN_SUCCESS: {
      const { updatedAdmin } = action.payload;
      return {
        ...state,
        adminsActionStep: SUCCESS,
        admins: state.admins.map((admin) => {
          if (admin.sub === updatedAdmin.sub) {
            return { ...admin, ...updatedAdmin };
          }
          return admin;
        }),
      };
    }
    case LOCK_ADMIN_FAILURE:
      return {
        ...state,
        adminsActionStep: FAILURE,
      };
    case UNLOCK_ADMIN_SUCCESS: {
      const { updatedAdmin } = action.payload;
      return {
        ...state,
        adminsActionStep: SUCCESS,
        admins: state.admins.map((admin) => {
          if (admin.sub === updatedAdmin.sub) {
            return { ...admin, ...updatedAdmin };
          }
          return admin;
        }),
      };
    }
    case UNLOCK_ADMIN_FAILURE:
      return {
        ...state,
        adminsActionStep: FAILURE,
      };
    case ADD_ADMIN_DETAILS: {
      const {
        payload: { userId, adminDetails },
      } = action;
      return {
        ...state,
        userId,
        adminDetails,
      };
    }
    case ADD_ADMINS_COMMENT: {
      return {
        ...state,
        comment: action.payload.comment,
        adminsActionStep: SUBMITTING,
      };
    }
    case PERFORM_ADMINS_ACTION:
      return {
        ...state,
        adminsActionStep: SUBMITTING,
      };
    case INVITE_ADMIN_SUCCESS: {
      return {
        ...state,
        adminDetails: defaultState.adminDetails,
        adminsActionStep: SUCCESS,
      };
    }
    case INVITE_ADMIN_FAILURE:
      return {
        ...state,
        adminDetails: defaultState.adminDetails,
        adminsActionStep: FAILURE,
      };
    case CLEAR_ERROR: {
      return {
        ...state,
        errorCode: defaultState.errorCode,
      };
    }
    case CANCEL_ADMINS_WORKFLOW:
      return {
        ...state,
        adminDetails: defaultState.adminDetails,
        adminActionType: defaultState.adminActionType,
        isActive: false,
      };
    case FINISH_ADMINS_WORKFLOW: {
      return {
        ...state,
        isActive: false,
        adminsActionStep: defaultState.adminsActionStep,
      };
    }
    default:
      return { ...state };
  }
};
