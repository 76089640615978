/* eslint-disable no-underscore-dangle */
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'react-app-polyfill/ie9';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@babel/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { fromJS } from 'immutable';
import { createBrowserHistory as createHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import routes from './routes.jsx';
import Root from './Root.jsx';
import configureStore from './redux/configureStore.js';

import '../style/index.css';

let initialState = {};

// rehydrate initialState for JS app
if (window.__INITIAL_STATE__) {
  initialState = window.__INITIAL_STATE__;

  // Transform into Immutable.js collections,
  // but leave top level keys untouched for Redux
  Object
    .keys(initialState)
    .forEach((key) => {
      initialState[key] = fromJS(initialState[key]);
    });
}

const browserHistory = createHistory({ basename: '/' });

const store = configureStore(initialState, browserHistory);

const history = syncHistoryWithStore(browserHistory, store);

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools
const container = document.getElementById('app-container');
const root = createRoot(container);
root.render(<Root history={history} routes={routes} store={store} />);
