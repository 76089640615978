/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Fragment } from 'react';
import _ from 'lodash';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MobileHeader } from './common/components/Header/index.js';
import Aside from './common/components/Theme/Aside/index.jsx';
import Content from './common/components/Theme/Content/index.jsx';
import Loader from './common/components/Loader/index.jsx';
import LoginResponse from './views/login-response/index.jsx';
import Users from './views/users/index.jsx';
import User from './views/user/index.jsx';
import PendingDeletion from './views/pendingDeletion/index.jsx';
import PurgedUsers from './views/purgedUsers/index.jsx';
import AdminsList from './views/admins/index.jsx';
import PurgedUser from './views/purgedUsers/PurgedUser.jsx';
import FeaturesAuditTrail from './views/featuresAuditTrail/index.jsx';
import Features from './views/features/index.jsx';
import CreateSsoUser from './views/createSsoUser/index.jsx';
import ErrorPage from './views/404page/index.jsx';
import { validateToken } from './services/auth.js';

const AsideWithRouter = withRouter(props => <Aside {...props} />);
const ContentWithRouter = withRouter(props => <Content {...props} />);
const loginUrl = __CONFIG__.loginRedirectUrl;

class RedirectPage extends React.Component {
  componentDidMount() {
    window.location.replace(loginUrl);
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => ({
  isAdmin: state.loginReducer.isAdmin,
});

const mapStateToPrivateRouteProps = state => ({
  accessToken: state.loginReducer.accessToken,
});

const PrivateRoute = ({ component: Component, accessToken, ...rest }) => (
  <Route
    {...rest}
    render={props =>
        (validateToken(accessToken) ? <Component {...props} /> : <RedirectPage />)
      }
  />
);

const ConnectedPrivateRoute = connect(mapStateToPrivateRouteProps)(PrivateRoute);

const AdminRoute = ({ component: Component, isAdmin, ...rest }) => (
  <ConnectedPrivateRoute
    {...rest}
    component={props => (
      <Loader isLoading={_.isUndefined(isAdmin)}>
        {isAdmin ? (
          <Component {...props} />
          ) : (
            <Redirect to="/" />
          )}
      </Loader>
      )}
  />
);

const ConnectedAdminRoute = connect(mapStateToProps)(AdminRoute);

export default (
  <div className="kt-grid kt-grid--hor kt-grid--root">
    <MobileHeader />
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
      <AsideWithRouter />
      <ContentWithRouter>
        <Switch>
          <Route exact path="/login-response" component={LoginResponse} />
          <ConnectedPrivateRoute exact path="/" component={Users} />
          <ConnectedPrivateRoute exact path="/users" component={Users} />
          <ConnectedPrivateRoute exact path="/users/:id" component={User} />
          <ConnectedPrivateRoute
            exact
            path="/pending-deletion"
            component={PendingDeletion}
          />
          <ConnectedPrivateRoute
            exact
            path="/pending-deletion/:id"
            component={User}
          />
          <ConnectedAdminRoute
            exact
            path="/purged-users"
            component={PurgedUsers}
          />
          <ConnectedAdminRoute
            exact
            path="/purged-users/:userDbId"
            component={PurgedUser}
          />
          <ConnectedPrivateRoute
            exact
            path="/features"
            component={Features}
          />
          <ConnectedPrivateRoute
            exact
            path="/features-audit-trail"
            component={FeaturesAuditTrail}
          />
          <ConnectedAdminRoute
            exact
            path="/admins"
            component={AdminsList}
          />
          <ConnectedAdminRoute
            exact
            path="/create-user"
            component={CreateSsoUser}
          />
          <ConnectedPrivateRoute component={ErrorPage} />
        </Switch>
      </ContentWithRouter>
    </div>
  </div>
);
