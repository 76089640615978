import axios from 'axios';
import moment from 'moment';
import { fetchUser } from './auth.js';
import { formToObj } from '../common/lib/transformSearchRequest.js';

/* eslint-disable arrow-body-style */
const constructBaseUrl = () => {
  return __CONFIG__.apiBaseUrl;
};
const constructApiKey = () => {
  return __CONFIG__.apiKey;
};

const baseUrl = constructBaseUrl();
const apiKey = constructApiKey();
const columnsMap = {
  given_name: 'firstName',
  family_name: 'lastName',
  email: 'email',
  address: 'address',
  phone_number: 'phone',
  created_at: 'created_at',
  sso_verified: 'sso_verified',
  deleted_at: 'deleted_at',
  permissions: 'cognito_user_enabled',
  role: 'role',
  deleted_by_last_name: 'lastName',
  ascend: 'asc',
  descend: 'desc',
};

const instantiateAxios = (baseURL) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return axios.create({
    baseURL,
    headers,
    timeout: 30000,
    crossDomain: true,
    withCredentials: false,
  });
};

const create = () => {
  const axiosInstance = instantiateAxios(baseUrl);

  const fetchSingleUser = ({ userId }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `admin/users/${userId}`;
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      userId,
    });
  };

  const resetPassword = ({ comment, userId }) => {
    const path = `admin/users/${userId}/reset-password`;
    const user = fetchUser();
    const { accessToken } = user;
    return axiosInstance.post(
      path,
      {
        comment,
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': apiKey,
        },
      },
    );
  };

  const purgeAdmin = ({ userId }) => {
    const path = `/admin/users/${userId}/purge-admin`;
    const user = fetchUser();
    const { accessToken } = user;
    return axiosInstance.post(
      path,
      {},
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': apiKey,
        },
      },
    );
  };

  const updateUser = ({ comment, profileUpdate, userId }) => {
    const path = `admin/users/${userId}`;
    const user = fetchUser();
    const { accessToken } = user;
    return axiosInstance.patch(
      path,
      {
        comment,
        profileUpdate,
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': apiKey,
        },
      },
    );
  };

  const fetchUsers = ({
    searchParams, limit, offset, markedForDeletion, order, column,
  }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/users';
    const params = formToObj(searchParams);
    params.limit = limit;
    params.offset = offset;
    params.column = columnsMap[column] || ''; // Columns on backend are firstName, lastName, address, email, phone
    params.order = columnsMap[order] || ''; // Order is default asc (if column is specified), desc is a specific trait
    params.marked_for_deletion = markedForDeletion;
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      params,
    });
  };

  const fetchAdmins = ({
    searchParams, limit, offset, column, order,
  }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/admin_users';
    const params = formToObj(searchParams);
    params.limit = limit;
    params.offset = offset;
    params.column = columnsMap[column] || '';
    params.order = columnsMap[order] || '';

    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      params,
    });
  };

  const fetchPurgedUser = ({ userDbId }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `admin/purged-users/${userDbId}`;
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const fetchPurgedUsers = ({
    searchParams, limit, offset, column, order,
  }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/purged-users';
    const params = formToObj(searchParams);
    params.limit = limit;
    params.offset = offset;
    params.column = columnsMap[column] || '';
    params.order = columnsMap[order] || '';
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      params,
    });
  };

  const fetchUserAuditTrail = ({ limit, offset, userDbId }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const params = {
      limit,
      offset,
    };
    const path = `admin/user-audits/${userDbId}`;
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      params,
    });
  };

  const postUserAuditTrailComment = ({ userDbId, body }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `admin/user-audits/${userDbId}`;
    return axiosInstance.post(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const fetchFeaturesAuditTrail = ({ limit, offset }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const params = {
      limit,
      offset,
    };
    const path = 'admin/features-audit-log';
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
      params,
    });
  };

  const postFeaturesAuditTrailComment = ({ body }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/features-audit-log';
    return axiosInstance.post(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const fetchFeaturesSettings = () => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/features';
    return axiosInstance.get(path, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const putTicketscanSettings = (body) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/features/ticket-scan';
    return axiosInstance.put(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  // planning ahead so that we can pick user pool if migration from the
  // sso-app pool gets held up for some reason. just in case.
  const getUserBulkPath = (userPoolId, action) =>
    `admin/pools/${userPoolId}/users/_bulk/${action}`;

  const handleUserBulkApiCall = (path, { users, comment }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const body = {
      users,
      comment,
    };
    const config = {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    };
    return axiosInstance
      .post(path, body, config)
      .then(({ data }) => data)
      .catch((error) => {
        if (error.response) {
          throw error;
        }
        // Connection error or something
        throw new Error('Something went wrong!');
      });
  };

  const bulkVerify = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'verify');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkUnverify = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'unverify');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkLockAccount = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'lock');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkUnlockAccount = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'unlock');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkMarkForDeletion = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'mark-for-deletion');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkCancelDeletion = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'cancel-deletion');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const bulkPurge = (userPoolId, { users, comment }) => {
    const path = getUserBulkPath(userPoolId, 'purge');
    return handleUserBulkApiCall(path, {
      users,
      comment,
    });
  };

  const inviteAdmin = ({ body }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/invite';
    return axiosInstance.post(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const editAdmin = ({ userId, body }) => {
    const user = fetchUser();
    const { accessToken } = user;
    return axiosInstance.patch(`admin/admin_users/${userId}`, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const createSsoUser = ({ body }) => {
    const adminUser = fetchUser();
    const { accessToken } = adminUser;
    const user = Object.assign({}, body);
    user.phone = user.phone.replace(/[^\d]/g, '');
    user.birthdate = moment(user.birthdate).format('MM/DD/YYYY');
    return axiosInstance.post('/admin/sso/register-verify', body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const toggleAdmin = ({ userId, body }) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `admin/admin_users/${userId}/status`;
    return axiosInstance.put(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const logOutAdmin = () => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = 'admin/logout';
    const body = {
      accessToken,
    };
    return axiosInstance.post(path, body, {
      headers: {
        Authorization: accessToken,
        'x-api-key': apiKey,
      },
    });
  };

  const toggleGameData = (name, enabled) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `/game/toggle-game-data/${name}`;
    const body = {
      enabled,
    };
    return axiosInstance.put(path, body, {
      headers: {
        'x-api-key': apiKey,
        Authorization: accessToken,
      },
    });
  };

  const customGameMessage = (name, message) => {
    const user = fetchUser();
    const { accessToken } = user;
    const path = `/game/custom-message/${name}`;
    const body = {
      message,
    };
    return axiosInstance.put(path, body, {
      headers: {
        'x-api-key': apiKey,
        Authorization: accessToken,
      },
    });
  };

  const getGameToggleData = () => {
    const path = 'content/game-toggle?populate=deep,10';
    return axiosInstance.get(
      path,
      {
        headers: {
          'x-api-key': apiKey,
        },
      },
    );
  };


  return {
    bulkVerify,
    bulkUnverify,
    bulkLockAccount,
    bulkUnlockAccount,
    bulkMarkForDeletion,
    bulkCancelDeletion,
    bulkPurge,
    fetchSingleUser,
    fetchUsers,
    fetchAdmins,
    fetchPurgedUser,
    fetchPurgedUsers,
    fetchUserAuditTrail,
    postUserAuditTrailComment,
    fetchFeaturesAuditTrail,
    postFeaturesAuditTrailComment,
    resetPassword,
    purgeAdmin,
    updateUser,
    fetchFeaturesSettings,
    putTicketscanSettings,
    inviteAdmin,
    editAdmin,
    toggleAdmin,
    logOutAdmin,
    toggleGameData,
    customGameMessage,
    getGameToggleData,
    createSsoUser,
  };
};

export default {
  create,
};
