import getProp from 'lodash/get.js';
import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  FETCH_ADMINS,
  PERFORM_ADMINS_ACTION,
  FINISH_ADMINS_WORKFLOW,
} from '../constants/index.js';
import {
  INVITE_ADMIN,
  EDIT_ADMIN,
  LOCK_ADMIN,
  UNLOCK_ADMIN,
} from '../constants/admins_actions.js';
import {
  fetchAdmins,
  fetchAdminsSuccess,
  fetchAdminsFailure,
  inviteAdminsSuccess,
  inviteAdminsFailure,
  editAdminsSuccess,
  editAdminsFailure,
  lockAdminSuccess,
  lockAdminFailure,
  unlockAdminSuccess,
  unlockAdminFailure,
} from '../actions/index.js';
import {
  adminsActionTypeSelector,
  adminDetailsSelector,
  adminUserIdSelector,
} from '../selectors/adminsActionsSelector.js';
import {
  formatPhoneNumberOnlyNumbers,
  formatRoleString,
} from '../../common/lib/formatting.jsx';

const createAdminSaga = (api) => {
  const { inviteAdmin, editAdmin, toggleAdmin } = api;

  const ACTION_TO_API_METHOD = {
    [INVITE_ADMIN]: inviteAdmin,
    [EDIT_ADMIN]: editAdmin,
    [LOCK_ADMIN]: toggleAdmin,
    [UNLOCK_ADMIN]: toggleAdmin,
  };
  const ACTION_SUCCESS_METHOD = {
    [INVITE_ADMIN]: inviteAdminsSuccess,
    [EDIT_ADMIN]: editAdminsSuccess,
    [LOCK_ADMIN]: lockAdminSuccess,
    [UNLOCK_ADMIN]: unlockAdminSuccess,
  };
  const ACTION_FAILURE_METHOD = {
    [INVITE_ADMIN]: inviteAdminsFailure,
    [EDIT_ADMIN]: editAdminsFailure,
    [LOCK_ADMIN]: lockAdminFailure,
    [UNLOCK_ADMIN]: unlockAdminFailure,
  };
  function* performAdminsActionSaga(action) {
    const REQUEST_BODY = {
      [INVITE_ADMIN]: { body: yield select(adminDetailsSelector) },
      [EDIT_ADMIN]: {
        userId: yield select(adminUserIdSelector),
        body: yield select(adminDetailsSelector),
      },
      [LOCK_ADMIN]: {
        userId: action.payload.data.userId,
        body: {
          enabled: false,
        },
      },
      [UNLOCK_ADMIN]: {
        userId: action.payload.data.userId,
        body: {
          enabled: true,
        },
      },
    };
    const adminsAction = yield select(adminsActionTypeSelector);
    const apiMethod = ACTION_TO_API_METHOD[adminsAction];
    const successMethod = ACTION_SUCCESS_METHOD[adminsAction];
    const failureMethod = ACTION_FAILURE_METHOD[adminsAction];
    const reqBody = REQUEST_BODY[adminsAction];
    if (adminsAction === INVITE_ADMIN) {
      if (reqBody.body.phone) {
        reqBody.body.phone = formatPhoneNumberOnlyNumbers(reqBody.body.phone);
      }
    }
    if (adminsAction === EDIT_ADMIN) {
      if (reqBody.body.profileUpdate.phone) {
        reqBody.body.profileUpdate.phone = formatPhoneNumberOnlyNumbers(reqBody.body.profileUpdate.phone);
      }
    }

    let response;
    try {
      response = yield call(apiMethod, reqBody);
      const SUCCESS_PAYLOAD = {
        [INVITE_ADMIN]: {
          sub: getProp(response, 'data.data.user.sub', ''),
          id: getProp(response, 'data.data.user.sub', ''),
          given_name: getProp(response, 'data.meta.firstName', ''),
          family_name: getProp(response, 'data.meta.lastName', ''),
          email: getProp(response, 'data.meta.email', ''),
          phone_number: getProp(response, 'data.meta.phone', ''),
          role: getProp(response, 'data.meta.isSuperadmin', false)
            ? 'Superadmin'
            : 'CSR',
        },
        [EDIT_ADMIN]: {
          sub: getProp(response, 'data.data.0.userId', ''),
          role: formatRoleString(getProp(response, 'data.meta.roleUpdate.role', 'CSR')),
          given_name: getProp(
            response,
            'data.meta.profileUpdate.firstName',
            '',
          ),
          family_name: getProp(
            response,
            'data.meta.profileUpdate.lastName',
            '',
          ),
          phone_number: getProp(response, 'data.meta.profileUpdate.phone', ''),
        },
        [LOCK_ADMIN]: {
          sub: getProp(response, 'data.data.userId', ''),
          cognito_user_enabled: getProp(
            response,
            'data.data.cognito_user_enabled',
          ),
        },
        [UNLOCK_ADMIN]: {
          sub: getProp(response, 'data.data.userId', ''),
          cognito_user_enabled: getProp(
            response,
            'data.data.cognito_user_enabled',
          ),
        },
      };
      const successPayload = SUCCESS_PAYLOAD[adminsAction];

      yield put(successMethod(successPayload));
    } catch (error) {
      const statusCode = getProp(error, 'response.status', -1);
      console.log('Fetch users error', error);
      yield put(failureMethod({ error, statusCode }));
    }
  }

  function* watchPerformAdminAction() {
    yield takeLatest(PERFORM_ADMINS_ACTION, performAdminsActionSaga);
  }

  function* fetchAdminsSaga(action) {
    const {
      searchParams, limit, offset, order, column,
    } = action.payload;
    let response;
    try {
      response = yield call(api.fetchAdmins, {
        searchParams, limit, offset, order, column,
      });
      const { totalCount, users } = getProp(response, 'data.data', {
        count: 0,
        totalCount: 0,
        users: [],
      });
      yield put(fetchAdminsSuccess({
        totalCount,
        users,
      }));
    } catch (error) {
      console.log('Fetch users error', error);
      const statusCode = getProp(error, 'response.status', -1);
      yield put(fetchAdminsFailure({ error, statusCode }));
    }
  }

  function* watchFetchAdmins() {
    yield takeLatest(FETCH_ADMINS, fetchAdminsSaga);
  }

  // refresh the search if creating a new admin
  function* selectParamsAndPutFetchAdmins(action) {
    if (action.payload.adminsActionType === INVITE_ADMIN) {
      const params = yield select((state) => {
        const store = state.adminsReducer;
        const { searchParams, offset, limit } = store;
        return {
          searchParams,
          offset,
          limit,
        };
      });
      yield put(fetchAdmins(params));
    }
  }

  function* watchFinishAdminActionWorkflow() {
    yield takeLatest(FINISH_ADMINS_WORKFLOW, selectParamsAndPutFetchAdmins);
  }

  return {
    watchFetchAdmins,
    watchPerformAdminAction,
    watchFinishAdminActionWorkflow,
  };
};

export default createAdminSaga;
