/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import getProp from 'lodash/get.js';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

class Details extends Component {
  render() {
    const { user, originPath } = this.props;
    const email = getProp(user, 'email', '');
    const deletedAt = getProp(user, 'deleted_at', '');
    const firstName = getProp(user, 'deleted_by_first_name', '');
    const lastName = getProp(user, 'deleted_by_last_name', '');
    return (
      <Fragment>
        <div className="py-5 ml-4">
          <div className="row mb-5">
            <div className="col-3 font-weight-bold">Email</div>
            <div>{email}</div>
          </div>
          <div className="row mb-5">
            <div className="col-3 font-weight-bold">Date Deleted</div>
            <div>{moment.utc(deletedAt).local().format('MM/DD/YYYY')}</div>
          </div>
          <div className="row mb-5">
            <div className="col-3 font-weight-bold">Time Deleted</div>
            <div>{moment.utc(deletedAt).local().format('h:mm a')}</div>
          </div>
          <div className="row mb-5">
            <div className="col-3 font-weight-bold">Action Approved By</div>
            <div>{`${firstName} ${lastName}`}</div>
          </div>
        </div>
        <div className="pt-3 bg-white card-footer d-flex justify-content-between">
          <Link to={originPath}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {}}
            >
              BACK
            </button>
          </Link>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
