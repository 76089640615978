import React, { useState, useEffect, useRef } from 'react';
import { Switch } from 'antd';
import GameDataToggleModal from '../../components/Modal/GameDataToggle/index.jsx';

const GameToggle = ({
  name, message, enabled, toggleChange, messageChange, isAdmin,
}) => {
  const [isEnabled, setIsEnabled] = useState(enabled);
  const [modalVisible, setModalVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    // do not fire on MouseEvent, only fire when changed
    if (isMounted.current) {
      toggleChange(name, isEnabled);
    } else {
      isMounted.current = true;
    }
  }, [isEnabled]);

  const changeMessage = (newMessage) => {
    if (newMessage !== message) {
      messageChange(name, newMessage);
    }
    setModalVisible(false);
    setMessageModalVisible(false);
  };

  const setToggle = () => {
    setModalVisible(false);
    setIsEnabled(!isEnabled);
    setMessageModalVisible(true);
  };

  const flipSwitch = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setMessageModalVisible(false);
  };

  return (
    <div className="game-toggle">
      <GameDataToggleModal
        modalVisible={modalVisible}
        messageModalVisible={messageModalVisible}
        changeMessage={changeMessage}
        setToggle={setToggle}
        handleCancel={handleCancel}
        name={name}
        isEnabled={isEnabled}
        message={message}
        isAdmin={isAdmin}
      />
      <div>
        <span className="font-weight-bold">{name}</span>
      </div>
      <div>
        <Switch
          checked={isEnabled}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          disabled={!isAdmin}
          onClick={flipSwitch}
        />
      </div>
    </div>
  );
};

export default GameToggle;
