/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import UserBar from './UserBar/index.jsx';

class ContentHeaderTopbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        <UserBar />
      </div>
    );
  }
}

export default ContentHeaderTopbar;
