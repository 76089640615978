import { connect } from 'react-redux';
import ModalConfirmForm from './ModalConfirmForm.jsx';
import { performSingleAction } from '../../../redux/actions/index.js';

const mapDispatchToProps = {
  onSubmit: performSingleAction,
};

export default connect(null, mapDispatchToProps)(ModalConfirmForm);

