import { connect } from 'react-redux';
import ModalCommentForm from './ModalCommentForm.jsx';
import { addSingleComment } from '../../../redux/actions/index.js';

const mapDispatchToProps = {
  onSubmit: addSingleComment,
};

export default connect(null, mapDispatchToProps)(ModalCommentForm);

