import { connect } from 'react-redux';
import {
  fetchAdmins,
  initAdminsSearchParams,
} from '../../../redux/actions/index.js';
import searchConfigs, { ADMIN_USERS_SEARCH } from '../../lib/searchBarConfig.js';
import StructuredSearchbarForm from './StructuredSearchbarForm.jsx';

// eslint-disable-next-line import/no-named-as-default-member
const searchCategories = searchConfigs[ADMIN_USERS_SEARCH];
const mapStateToProps = state => ({
  isFetching: state.adminsReducer.isFetching,
  searchParams: state.adminsReducer.searchParams,
});

const mapDispatchToProps = {
  submitSearch: fetchAdmins,
  initSearchParams: initAdminsSearchParams,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  searchCategories,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StructuredSearchbarForm);
