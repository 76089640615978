import React, { Component, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import getProp from 'lodash/get.js';
import { Switch } from 'antd';
import SingleActionsWorkflowModal from '../../common/components/Modal/SingleActionsWorkflow/index.jsx';
import Portlet from '../../common/components/Portlet/index.jsx';
import PaginatedTable from '../../common/components/Table/PaginatedTable.jsx';
import {
  fetchAdmins,
  fetchUserSuccess,
  startInviteAdminFlow,
  startEditAdminFlow,
  startToggleAdminFlow,
  performAdminsAction,
  setIsSingleUserWorkflow,
  startSingleActionWorkflow,
} from '../../redux/actions/index.js';
import { formatPhoneForDetailsForm } from '../../common/lib/formatting.jsx';
import AdminsWorkflowModal from '../../common/components/Modal/AdminsWorkflow/index.jsx';
import { adminDetailsSelector } from '../../redux/selectors/adminsActionsSelector.js';
import AdminsSearch from '../../common/components/Searchbar/AdminsSearch.js';
import CSVDownloadButton from '../../common/components/CSVDownload/CSVDownloadButton.jsx';
import { ADMIN_PURGE } from '../../redux/constants/single_actions.js';

// Reset the checked val upon isActive change
const ToggleAdmin = ({
  isActive,
  checked,
  dispatchToggle,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(checked);
  const switchTimeoutRef = useRef(null);
  // Reset switch state if modal was cancelled
  useEffect(() => {
    if (!isActive) {
      setIsSwitchOn(checked);
    }
  }, [checked, isActive]);

  // Cancel timer upon unmount if exists
  useEffect(() => {
    if (switchTimeoutRef.current) {
      clearTimeout(switchTimeoutRef.current);
      switchTimeoutRef.current = null;
    }
  }, []);

  // Visually show switch flipping and then open modal after
  // a short timeout
  const flipSwitch = () => {
    if (!switchTimeoutRef.current) {
      setIsSwitchOn(prevSwitchState => !prevSwitchState);
      switchTimeoutRef.current = setTimeout(() => {
        dispatchToggle(!checked);
        switchTimeoutRef.current = null;
      }, 100);
    }
  };
  return (
    <Switch
      checked={isSwitchOn}
      onClick={flipSwitch}
    />
  );
};

const mapStateToProps = state => ({
  isActive: state.adminsReducer.isActive,
  searchParams: state.adminsReducer.searchParams,
  admins: state.adminsReducer.admins,
  count: state.adminsReducer.count,
  limit: state.adminsReducer.limit,
  statusCode: state.adminsReducer.statusCode,
  errorDetail: state.adminsReducer.errorDetail,
  isFetching: state.adminsReducer.isFetching,
  offset: state.adminsReducer.offset,
  totalCount: state.adminsReducer.totalCount,
  order: state.adminsReducer.order,
  column: state.adminsReducer.column,
  isAdmin: state.loginReducer.isAdmin,
  adminDetails: adminDetailsSelector(state),
});

const mapDispatchToProps = {
  fetchAdmins,
  startInviteAdminFlow,
  startEditAdminFlow,
  startToggleAdminFlow,
  performAdminsAction,
  beginSingleActionWorkflow: startSingleActionWorkflow,
  setIsSingleUserWorkflow,
  fetchUserSuccess,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  currentPage: stateProps.offset / stateProps.limit + 1,
  addUser: () =>
    dispatchProps.startInviteAdminFlow({
      adminDetails: stateProps.adminDetails,
    }),
  toggleAdmin: ({ userId, toggleType }) => {
    dispatchProps.startToggleAdminFlow({ toggleType });
    dispatchProps.performAdminsAction({ userId });
  },
  editUser: user =>
    dispatchProps.startEditAdminFlow({
      adminDetails: user,
    }),
  getPage(pageNumber) {
    const {
      limit, searchParams,
    } = stateProps;
    const zeroBasedPageNumber = pageNumber - 1;
    const offset = zeroBasedPageNumber * limit;
    dispatchProps.fetchAdmins({
      limit,
      offset,
      searchParams,
    });
  },
  sortColumns(pagination, filters, sorter) {
    const {
      limit,
      searchParams,
    } = stateProps;
    const offset = (pagination.current - 1) * limit;
    const column = sorter.field;
    const { order } = sorter;
    dispatchProps.fetchAdmins({
      limit,
      offset,
      searchParams,
      column,
      order,
    });
  },
});

@connect(mapStateToProps, mapDispatchToProps, mergeProps)
class AdminsList extends Component {
  componentDidMount() {
    const {
      limit, offset, searchParams,
    } = this.props;
    this.props.fetchAdmins({ searchParams, limit, offset });

    // Prevent clearing of selected users array upon finishing workflow
    this.props.setIsSingleUserWorkflow();
  }

  render() {
    const {
      admins,
      isActive,
      isFetching,
      getPage,
      sortColumns,
      currentPage,
      totalCount,
      addUser,
      toggleAdmin,
      editUser,
      searchParams,
      isAdmin,
      beginSingleActionWorkflow,
    } = this.props;

    const portletConfig = {
      title: 'ADMIN LIST',
      actionsLabel: 'Actions',
      actions: [],
      renderSearchbar: () => <AdminsSearch />,
    };

    const columns = [
      {
        title: 'First Name',
        dataIndex: 'given_name',
        sorter: (a, b) => a.given_name.localeCompare(b.given_name),
      },
      {
        title: 'Last Name',
        dataIndex: 'family_name',
        sorter: (a, b) => a.family_name.localeCompare(b.family_name),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: 'Role',
        dataIndex: 'role',
        sorter: (a, b) => a.role.localeCompare(b.role),
      },
      {
        title: 'Permissions',
        dataIndex: 'permissions',
        sorter: (a, b) => a.cognito_user_enabled - b.cognito_user_enabled,

        render: (text, record) => {
          const isEnabled = getProp(record, 'cognito_user_enabled', false);
          const userId = getProp(record, 'sub', '');
          return (
            <ToggleAdmin
              dispatchToggle={(toggleType) => {
                toggleAdmin({ userId, toggleType });
              }}
              isActive={isActive}
              checked={isEnabled}
            />
          );
        },
      },
      {
        render: (text, record) => {
          const firstName = getProp(record, 'given_name', '');
          const lastName = getProp(record, 'family_name', '');
          const email = getProp(record, 'email', '');
          const intlPhone = getProp(record, 'phone_number', '');
          const phone = formatPhoneForDetailsForm(intlPhone);
          const isSuperadmin = getProp(record, 'role', '') === 'Superadmin';
          const userId = getProp(record, 'sub', '');
          return (
            <button
              type="button"
              onClick={() => {
                editUser({
                  userId,
                  firstName,
                  lastName,
                  email,
                  phone,
                  isSuperadmin,
                });
              }}
              className="btn btn-edit btn-link text-primary bg-transparent text-uppercase"
            >
              Edit
            </button>
          );
        },
      },
      (isAdmin && {
        render: (text, record) => (
          <button
            type="button"
            onClick={() => {
                beginSingleActionWorkflow({
                  singleActionType: ADMIN_PURGE,
                  userId: getProp(record, 'sub'),
                });
              }}
            className="btn btn-edit btn-link text-danger bg-transparent text-uppercase"
          >
            Delete
          </button>
        ),
      }),
    ];
    const dataIndex = ['given_name', 'family_name', 'email', 'role'];
    return (
      <div className="row">
        <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <AdminsWorkflowModal />
          <SingleActionsWorkflowModal />
          <Portlet config={portletConfig}>
            <div className="action-btn">
              <button onClick={addUser} className="btn btn-secondary">
                Add User
              </button>
            </div>
            <PaginatedTable
              columns={columns}
              dataSource={admins}
              rowKey={record => record.id}
              loading={isFetching}
              currentPage={currentPage}
              getPage={getPage}
              limit={10}
              totalCount={totalCount}
              onColumnChange={sortColumns}
            />
            {isFetching && admins.length > 0 && (
              <div className="text-center">
                <h4>Loading users...</h4>
              </div>
            )}
          </Portlet>
          <CSVDownloadButton
            searchParams={searchParams}
            totalCount={totalCount}
            dataIndex={dataIndex}
            fetchType="fetchAdmins"
          />
        </div>
      </div>
    );
  }
}

export default AdminsList;
