/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';

class ContentHeaderMenu extends React.Component {
  render() {
    return (
      <Fragment>
        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close" /></button>
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout- ">
            <ul className="kt-menu__nav ">
              <li
                className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                data-ktmenu-submenu-toggle="click"
                aria-haspopup="true"
              />
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContentHeaderMenu;
