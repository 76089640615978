/* eslint-disable import/first */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _, { startCase } from 'lodash';
import { formToQueryString } from '../../../../lib/transformSearchRequest.js';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  user: state.usersReducer.user,
  searchParams: state.usersReducer.searchParams,
});

const mapDispatchToProps = {};

class ContentSubheader extends React.Component {
  render() {
    const { user, searchParams } = this.props;
    const path = _.get(this.props, 'location.pathname', '');
    const breadcrumbPath = startCase(path.split('/')[1]);
    const isUserPath = path.includes('/users/');
    const isPendingDeletion = path.includes('/pending-deletion/');
    const isPurged = path.includes('/purged-users/');

    let originPath = `/${path.split('/')[1]}`;
    if (searchParams.length) {
      originPath = `${originPath}?${formToQueryString(searchParams)}`;
    }

    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-container  kt-container--fluid ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Dashboard</h3>
            <span className="kt-subheader__separator kt-hidden" />
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home"><i className="flaticon2-shelter" /></Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link to={originPath} className="kt-subheader__breadcrumbs-link">
                {breadcrumbPath}
              </Link>
              {user && (isUserPath || isPendingDeletion || isPurged) &&
                <Fragment>
                  <span className="kt-subheader__breadcrumbs-separator" />
                  <span href="#" className="kt-subheader__breadcrumbs-link">
                    { isPurged ? user.email : `${user.given_name} ${user.family_name}` }
                  </span>
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSubheader);
